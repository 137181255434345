import React from 'react'
import { withTranslation } from 'react-i18next'
import Footer from './../footer/Footer';
import logo from './../../res/logos/logo.svg';
import { withHelpers } from '../../utils/Injection';
import Validation from '../../utils/Validation';
import ApiRepository from '../../data/ApiRepository';
import IconInfo from '../icons/IconInfo';
import BaseComponent from '../BaseComponent';

class SignUp extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            fullName: null,
            email: null,
            password: null,
            success: null,
            error: null,
            tooltipPasswordVisible: false
        };
        this.handleFullNameChange = this.handleFullNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.toggleTooltipPasswordVisibility = this.toggleTooltipPasswordVisibility.bind(this);
        this.doSignUp = this.doSignUp.bind(this);
        this.navigateToToLogin = this.navigateToToLogin.bind(this);
    }

    handleFullNameChange(e) {
        this.setState({ fullName: e.target.value });
    }

    handleEmailChange(e) {
        this.setState({ email: e.target.value });
    }

    handlePasswordChange(e) {
        this.setState({ password: e.target.value });
    }

    toggleTooltipPasswordVisibility(e) {
        e. preventDefault();
        this.setState({ tooltipPasswordVisible: !this.state.tooltipPasswordVisible });
    }

    doSignUp(e) {
        e. preventDefault();

        const { t } = this.props;

        var emptyFullName = false;
        var emptyEmail = false;
        var emptyPassword = false;
        var validEmail = true;
        emptyFullName = Validation.isEmptyString(this.state.fullName);
        emptyEmail = Validation.isEmptyString(this.state.email);
        emptyPassword = Validation.isEmptyString(this.state.password);
        validEmail = Validation.validateEmail(this.state.email);

        var errors = []
        if (emptyFullName) {
            errors.push(t('error_empty_full_name'));
        }
        if (!validEmail && !emptyEmail) {
            errors.push(t('error_invalid_email'));
        }
        if (emptyEmail || emptyPassword) {
            if (emptyEmail && emptyPassword) {
                errors.push(t('error_empty_email_and_password'));
            } else if (emptyEmail) {
                errors.push(t('error_empty_email'));
            } else {
                errors.push(t('error_empty_password'));
            }
        }
        if (errors.length > 0) {
            this.setState({ error: errors.join("\n") });
            return false;
        }
        this.setState({ error: null });
        ApiRepository.signUp(
            this.state.fullName,
            this.state.email,
            this.state.password, 
            (response) => {
                this.setState({ success: t('sign_up_success_message'), error: null })
            },
            (sessionExpired, errorMessage) => {
                this.setState({ error: errorMessage });
            }
        );
        return false;
    }

    navigateToToLogin(e) {
        e. preventDefault();
        this.props.navigate("/login");
    }

    render() {
        const { t } = this.props;
        
        return <div className='login-container'>
            <div>
                <img src={logo} className="login-logo" alt={t('alt_green_systems_logo')} />
                <h1>{t('iot_platform')}</h1>
                <form className='login-form' onSubmit={this.doSignUp} noValidate>
                    <div className="form-container">
                        <label htmlFor="full_name">{t('full_name')}</label>
                        <input type="text" placeholder={t('full_name_input_placeholder')} name="full_name" onChange={this.handleFullNameChange}/>
                    
                        <label htmlFor="username">{t('email')}</label>
                        <input type="email" placeholder={t('email_input_placeholder')} name="username" onChange={this.handleEmailChange}/>

                        <label htmlFor="password">{t('password')}<span className='tooltip-trigger' onClick={this.toggleTooltipPasswordVisibility}><IconInfo className='input-hint-icon'/></span></label>
                        <label htmlFor='password' className={'tooltip-' + (this.state.tooltipPasswordVisible ? 'expanded' : 'collapsed')}>{t('password_requirements_description')}</label>
                        <input type="password" placeholder={t('password_input_placeholder')} name="password" onChange={this.handlePasswordChange}/>

                        { !Validation.isEmptyString(this.state.success) && <p className='success'>{this.state.success}</p> }
                        { !Validation.isEmptyString(this.state.error) && <p className='error'>{this.state.error}</p> }

                        <button type="submit">{t('action_sign_up')}</button>
                        
                        <a onClick={this.navigateToToLogin}>{t('link_login')}</a>
                    </div>
                </form>
            </div>
            <Footer/>
        </div>
    }
}

export default withHelpers(withTranslation()(SignUp))