import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './assets/css/branding.css';
import App from './App';
import Lottie from "lottie-web/build/player/lottie_light";
import loadingAnimationData from './assets/lotties/loading.json';

ReactDOM.render((
  <Suspense fallback={
    <div id='home-container'>
      {/* <p>Loading…</p> */}
      <div id="animationLoading" />
      {
        Lottie.loadAnimation({
          container: document.querySelector("#animationLoading"),
          animationData: loadingAnimationData,
          loop: false,
          autoplay: true
        }) && <></>
      }
    </div>
  }>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>
), document.getElementById('root')
);