import circleWhite from './../assets/icons/circle_white.svg';
import circleRed from './../assets/icons/circle_red.svg';
import circleYellow from './../assets/icons/circle_yellow.svg';
import circleGreen from './../assets/icons/circle_green.svg';
import rangeTop from './../assets/icons/range_top.svg';
import rangeMiddle from './../assets/icons/range_middle.svg';
import rangeBottom from './../assets/icons/range_bottom.svg';
import { mapMarkerSyncWhite } from './../assets/map/MapMarkerSyncWhite';
import { mapMarkerSyncRed } from './../assets/map/MapMarkerSyncRed';
import { mapMarkerSyncYellow } from './../assets/map/MapMarkerSyncYellow';
import { mapMarkerSyncGreen } from './../assets/map/MapMarkerSyncGreen';

export default class DashboardUIHelper {

    static TYPE_HEADER = 0;
    static TYPE_BODY = 1;
    static TYPE_FOOTER = 2;

    static dashboardModuleHeader = (branding, text) => {
        const style = branding ? {
            backgroundColor: branding.getColorAccent(),
            color: branding.getColorTextMainAccent()
        } : {}
        return <p className='dashboard-module-header' style={style}>{text}</p>;
    };

    static circleSvgForSyncStatus = (syncStatus, t) => {
        switch (syncStatus) {
            case 3:
                return <img className='status-circle' src={circleGreen} alt={t('alt_sync_status_less_than_1_month')} />
            case 2:
                return <img className='status-circle' src={circleYellow} alt={t('alt_sync_status_more_than_1_month')} />
            case 1:
                return <img className='status-circle' src={circleRed} alt={t('alt_sync_status_more_than_3_months')} />
            default:
                return <img className='status-circle' src={circleWhite} alt={t('alt_sync_status_not_available')} />
        }
    }

    static iconSvgForPosRange = (range, t) => {
        switch (range) {
            case 3:
                return <img className='pos-range-icon' src={rangeTop} alt={t('alt_pos_range_top')} />
            case 2:
                return <img className='pos-range-icon' src={rangeMiddle} alt={t('alt_pos_range_middle')} />
            case 1:
                return <img className='pos-range-icon' src={rangeBottom} alt={t('alt_pos_range_bottom')} />
            default:
                return <></>
        }
    }

    static iconSvgForTempStatus = (tempStatus, t) => {
        switch (tempStatus) {
            case 2:
                return <img className='status-circle' src={circleGreen} alt={t('alt_temp_status_ok')} />
            case 1:
                return <img className='status-circle' src={circleRed} alt={t('alt_temp_status_ko')} />
            default:
                return <img className='status-circle' src={circleWhite} alt={t('alt_temp_status_not_available')} />
        }
    }

    static markerIconForSyncStatus = (syncStatus) => {
        switch (syncStatus) {
            case 3:
                return mapMarkerSyncGreen;
            case 2:
                return mapMarkerSyncYellow;
            case 1:
                return mapMarkerSyncRed;
            default:
                return mapMarkerSyncWhite;
        }
    }

    static styleForTableCellDivider = (cellType, branding) => {
        if (branding) {
            switch (cellType) {
                case DashboardUIHelper.TYPE_BODY:
                    return {borderBottom: '1px solid ' + branding.getColorAccent() + '88'};
                case DashboardUIHelper.TYPE_HEADER:
                    return {borderBottom: '1px solid ' + branding.getColorAccent()};
                case DashboardUIHelper.TYPE_FOOTER:
                    return {borderTop: '1px solid ' + branding.getColorAccent()};
                default:
                    return {};
            }
        }
        return {};
    }

    static getHeaderCell = (branding, content, onClick) => {
        return <th style={DashboardUIHelper.styleForTableCellDivider(DashboardUIHelper.TYPE_HEADER, branding)} onClick={onClick}>{content}</th>;
    }

    static getBodyCell = (branding, content, style = null, colspan = 1) => {
        return <td style={Object.assign({}, DashboardUIHelper.styleForTableCellDivider(DashboardUIHelper.TYPE_BODY, branding), style)} colSpan={colspan}>{content}</td>;
    }

    static getFooterCell = (branding, content, colspan = 1) => {
        return <th style={DashboardUIHelper.styleForTableCellDivider(DashboardUIHelper.TYPE_FOOTER, branding)} colSpan={colspan}>{content}</th>;
    }

    static getImgFileTypeIcon = (fileType) => {
        return <img className='file-type-icon' src={'https://elasticbeanstalk-eu-north-1-140292165096.s3.eu-north-1.amazonaws.com/public-assets/file-type-icons/' + fileType.toLowerCase() + '.svg'} alt={fileType}/>;
    }
}