export const HeaderMenuItem = {
    AssetManagement: "header_asset_management",
    CommercialManagement: "header_commercial_management",
    Temperature: "header_temperature",
    Documentation: "header_documentation",
    IotPremium: "header_iot_premium",
    Admin: "header_admin",
    Account: "header_account",
    Back: "header_back",
    CompaniesList: "header_companies_list",
    Logout: "header_logout"
}