import Validation from "./Validation";
import i18n from '../i18n';

export default class DateUtils {

    static formatYYYYMMDD = (date) => {
        if (!Validation.isDateType(date)) {
            throw('The parameters must be of type Date.');
        }
        const month = date.getMonth() + 1;
        return date.getFullYear() + "-" + (month < 10 ? "0" : "") + month + "-" + (date.getDate() < 10 ? "0" : "") + date.getDate();
    };

    static formatYYYYMMDDHHMMSS = (date) => {
        return this.formatYYYYMMDD(date) + " " + (date.getHours() < 10 ? "0" : "") + date.getHours() + ":" + (date.getMinutes() < 10 ? "0" : "") + date.getMinutes() + ":" + (date.getSeconds() < 10 ? "0" : "") + date.getSeconds();
    }

    static addDays = (date, days) => {
        return date.setUTCDate(date.getUTCDate() + days);
    }

    static addDaysCopy = (date, days) => {
        var newDate = new Date(date.getTime());
        newDate.setUTCDate(newDate.getUTCDate() + days);
        return newDate;
    }

    static formattedDate = (dateStr, noCurrentYear=false) => {
        if (dateStr) {
            try {
                let date = new Date(dateStr);
                var params = {
                    month: 'short',
                    day: 'numeric'
                }
                if (!noCurrentYear || date.getFullYear() !== new Date().getFullYear()) {
                    params.year = '2-digit'
                }
                return date.toLocaleString(i18n.resolvedLanguage, params);
            } catch (error) {
                //
            }
        }
        return "";
    }

    static daysBetweenDates = (dateStart, dateEnd) => {
        if (dateStart == null || dateEnd == null) {
            return 0;
        }
        return Math.round(Math.abs(dateEnd.getTime() - dateStart.getTime()) / (1000 * 3600 * 24));
    }

    static getSmallest = (date1, date2) => {
        if (date1 != null && date2 != null) {
            return date1.getTime() < date2.getTime() ? date1 : date2;
        } else if (date1 != null) {
            return date1;
        } else if (date2 != null) {
            return date2;
        }
        return null;
    }

    static getBiggest = (date1, date2) => {
        if (date1 != null && date2 != null) {
            return date1.getTime() > date2.getTime() ? date1 : date2;
        } else if (date1 != null) {
            return date1;
        } else if (date2 != null) {
            return date2;
        }
        return null;
    }

    static epochToDate = (epochSeconds) => {
        return new Date(epochSeconds * 1000);
    }
}