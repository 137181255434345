import React from "react";
import { withHelpers } from "../utils/Injection";
import { withTranslation } from "react-i18next";

class BaseComponent extends React.Component {

    resetTokenAndGoToLogin() {
        this.props.auth.setToken(null);
        this.props.navigate("/login", { replace: true});
        this.props.navigate(0);
    }
}

export default BaseComponent