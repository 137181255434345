import i18n from "../i18n";

export default class NumberUtils {
 
    static formatNumberMaxDecimals = (number, maxDecimals = 2) => {
        if (number == null) {
            return null;
        }
        const formatter = new Intl.NumberFormat(i18n.resolvedLanguage, {
            maximumFractionDigits: maxDecimals
        });
        return formatter.format(number);
    }

    static formatNumberK(num, maxDecimals = 2) {
        const lookup = [
          { value: 1, symbol: "" },
          { value: 1e3, symbol: "k" },
          { value: 1e6, symbol: "M" },
          { value: 1e9, symbol: "G" },
          { value: 1e12, symbol: "T" },
          { value: 1e15, symbol: "P" },
          { value: 1e18, symbol: "E" }
        ];
        const item = lookup.slice().reverse().find(item => num >= item.value);
        return item ? NumberUtils.formatNumberMaxDecimals((num / item.value), maxDecimals).concat(item.symbol) : "0";
      }
}