export default class Validation {

    static validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    static isEmptyString = (str) => {
        return (str == null || (typeof str === "string" && str.trim().length === 0));
    }

    static isDateType = (date) => {
        return Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date);
    }
}