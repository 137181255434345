export default class IotEvent {

    #timestamp = null;
    #imei = null;
    #temp = null;
    #doorOpenings = null;
    #footfall = null;
    #lat = null;
    #long = null;
    #modemEvent = null;
    #event = null;
    #voltage = null;
    #gsmSignal = null;

    constructor(timestamp, imei, temp, doorOpenings, footfall, lat, long, modemEvent, event, voltage, gsmSignal) {
        this.#timestamp = timestamp;
        this.#imei = imei;
        this.#temp = temp;
        this.#doorOpenings = doorOpenings;
        this.#footfall = footfall;
        this.#lat = lat;
        this.#long = long;
        this.#modemEvent = modemEvent;
        this.#event = event;
        this.#voltage = voltage;
        this.#gsmSignal = gsmSignal;
    }

    static fromServerResponse(serverResponse) {
        return new IotEvent(
            serverResponse.timestamp,
            serverResponse.imei,
            serverResponse.temp,
            serverResponse.door_openings,
            serverResponse.footfall,
            serverResponse.lat,
            serverResponse.long,
            serverResponse.modem_event,
            serverResponse.event,
            serverResponse.voltage,
            serverResponse.gsm_signal
        );
    }

    getTimestamp() {
        return this.#timestamp;
    }

    setTimestamp(timestamp) {
        this.#timestamp = timestamp;
    }

    getImei() {
        return this.#imei;
    }

    setImei(imei) {
        this.#imei = imei;
    }

    getTemp() {
        return this.#temp;
    }

    setTemp(temp) {
        this.#temp = temp;
    }

    getDoorOpenings() {
        return this.#doorOpenings;
    }

    setDoorOpenings(doorOpenings) {
        this.#doorOpenings = doorOpenings;
    }

    getFootfall() {
        return this.#footfall;
    }

    setFootfall(footfall) {
        this.#footfall = footfall;
    }

    getLat() {
        return this.#lat;
    }

    setLat(lat) {
        this.#lat = lat;
    }

    getLong() {
        return this.#long;
    }

    setLong(long) {
        this.#long = long;
    }

    getModemEvent() {
        return this.#modemEvent;
    }

    setModemEvent(modemEvent) {
        this.#modemEvent = modemEvent;
    }

    getEvent() {
        return this.#event;
    }

    setEvent(event) {
        this.#event = event;
    }

    getVoltage() {
        return this.#voltage;
    }

    setVoltage(voltage) {
        this.#voltage = voltage;
    }

    getGsmSignal() {
        return this.#gsmSignal;
    }

    setGsmSignal(gsmSignal) {
        this.#gsmSignal = gsmSignal;
    }
}