
import React from 'react'
import { withHelpers } from '../../utils/Injection';
import { withTranslation } from 'react-i18next';
import BaseComponent from '../BaseComponent';

class DashboardIotPremium extends BaseComponent {

    constructor(props) {
        super(props);
        if (props.reference) {
            props.reference.current = this;
        }
        this.allMachines = props.machines != null ? props.machines : [];
        this.allAggregatedEvents = props.aggregatedEvents != null ? props.aggregatedEvents : [];
    }

    refresh(updatedMachines, aggregatedEvents) {

    }

    render() {
        const { t } = this.props;

        if (!this.props.selectedCompany) {
            return <p>{t('error_generic')}</p>
        }

        return <div className='dashboard-iot-premium'>
            <h1>{t('header_iot_premium')}</h1>
            <img src={"https://elasticbeanstalk-eu-north-1-140292165096.s3.eu-north-1.amazonaws.com/public-assets/other/iot_premium.jpeg"} alt={t('header_iot_premium')}/>
        </div>
    }
}

export default withHelpers(withTranslation()(DashboardIotPremium))