import React from 'react'
import { withTranslation } from 'react-i18next'
import { withHelpers } from '../../utils/Injection';
import ApiRepository from '../../data/ApiRepository';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import logo from './../../res/logos/logo.svg';
import { HeaderMenuItem } from '../header/HeaderMenuItem';
import * as Icon from "react-icons/fi";
import BaseComponent from '../BaseComponent';

class Account extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
        this.onHeaderItemSelected = this.onHeaderItemSelected.bind(this);
        this.navigateToChangePassword = this.navigateToChangePassword.bind(this);
    }

    componentDidMount() {
        ApiRepository.getUser(
            ApiRepository.userId,
            (user) => {
                this.setState({
                    user: user,
                    loading: false
                });
            },
            (sessionExpired, errorMessage) => {
                this.setState({
                    loading: false
                });
                if (sessionExpired) {
                    this.resetTokenAndGoToLogin();
                    alert(errorMessage);
                }
            }
        );
    }

    onHeaderItemSelected(item) {
        if (item === HeaderMenuItem.Back) {
            this.props.navigate("/");
        }
    }

    navigateToChangePassword(e) {
        e. preventDefault();
        this.props.navigate("/change-password");
    }

    render() {
        const { t } = this.props;
        const menuItems = [HeaderMenuItem.Account, HeaderMenuItem.Back];

        return <>
            <Header
                logo={ logo }
                menuItems={menuItems}
                onItemSelected={(item) => this.onHeaderItemSelected(item)}
                selectedItem={menuItems[0]}
            />
            <div className='account-container'>
                <div>
                    { this.state.loading && <p>{t('loading_ellipsis')}</p> }
                    { !this.state.loading && this.state.user && <div className='account-content'>
                        <p className='header-over-card'>{t('my_account')}</p>
                        <div className='user-details card'>
                            <p className='card-header'>{this.state.user.getName()}&nbsp;&nbsp;&nbsp;<span className={'account-status-chip-' + (this.state.user.isActive() ? 'active' : 'inactive')}>{this.state.user.isActive() && <Icon.FiCheck className='account-status-chip-icon' size={15} />}{(!this.state.user.isActive()) && <Icon.FiX className='account-status-chip-icon' size={15} />} {this.state.user.isActive() ? t('active_female') : t('inactive_female')}</span></p>
                            <p className='card-field-name'>{t('account_id')}</p>
                            <p className='card-field-value'>{this.state.user.getId()}</p>
                            <p className='card-field-name'>{t('email')}</p>
                            <p className='card-field-value'>{this.state.user.getEmail()}</p>
                            <p className='card-field-name'>{t('public_key')}</p>
                            <p className='card-field-value'>{"*****" + this.state.user.getPublicKeyLast4()}</p>
                            <p className='card-field-name'>{t('secret_key')}</p>
                            <p className='card-field-value'>{"*****" + this.state.user.getSecretKeyLast4()}</p>
                            <p><a onClick={this.navigateToChangePassword}><span>{t('action_change_password')} <Icon.FiChevronRight size={15} /></span></a></p>
                        </div>
                        <p className='header-over-card'>{t('linked_companies')}</p>
                        <div className='user-companies card'>
                            {this.state.user.getCompanies() && this.state.user.getCompanies().length > 0 && this.state.user.getCompanies().sort((a, b) => (b.isActive() ? 1 : 0) - (a.isActive() ? 1 : 0)).map(company => {
                                return <div className='company-info'>
                                    <p className='card-header'>{company.getTradeName()}&nbsp;&nbsp;&nbsp;<span className={'account-status-chip-' + (company.isActive() ? 'active' : 'inactive')}>{company.isActive() && <Icon.FiCheck className='account-status-chip-icon' size={15} />}{(!company.isActive()) && <Icon.FiX className='account-status-chip-icon' size={15} />} {company.isActive() ? t('active_female') : t('inactive_female')}</span></p>
                                    <p className='card-field-value'>{company.getName()}</p>
                                    <p className='card-field-name'>{t('role')}</p>
                                    <p className='card-field-value'>{t('company_role_' + company.getRoleObj())}</p>
                                    <p className='card-field-name'>{t('account_id')}</p>
                                    <p className='card-field-value'>{company.getId()}</p>
                                    <p className='card-field-name'>{t('email')}</p>
                                    <p className='card-field-value'>{company.getEmail()}</p>
                                    <p className='card-field-name'>{t('phone')}</p>
                                    <p className='card-field-value'>{company.getPhone()}</p>
                                    <p className='card-field-name'>{t('tax_id')}</p>
                                    <p className='card-field-value'>{company.getTaxId()}</p>
                                    <p className='card-field-name'>{t('address')}</p>
                                    <p className='card-field-value'>{company.getAddressLine1()}</p>
                                    { company.getAddressLine2() && <p className='card-field-value'>{company.getAddressLine2()}</p> }
                                    <p className='card-field-value'>{company.getPostcode() + " " + company.getCity()}</p>
                                    <p className='card-field-value'>{company.getCounty()}</p>
                                    <p className='card-field-value'>{company.getCountry()}</p>
                                    { company.getClientCode() && <p className='card-field-name'>{t('redsys_client_code')}</p> }
                                    { company.getClientCode() && <p className='card-field-value'>{company.getClientCode()}</p> }
                                    { company.getCommerceNumber() && <p className='card-field-name'>{t('redsys_commerce_number')}</p> }
                                    { company.getCommerceNumber() && <p className='card-field-value'>{company.getCommerceNumber()}</p> }
                                    <p className='card-field-name'>{t('public_key')}</p>
                                    <p className='card-field-value'>{"*****" + company.getPublicKeyLast4()}</p>
                                    <p className='card-field-name'>{t('secret_key')}</p>
                                    <p className='card-field-value'>{"*****" + company.getSecretKeyLast4()}</p>
                                </div>
                            })}
                            {(this.state.user.getCompanies() == null || this.state.user.getCompanies().length == 0) && <p>{t('no_companies_placeholder')}</p>}
                        </div>
                    </div> }
                </div>
            </div>
            <Footer/>
        </>
    }
}

export default withHelpers(withTranslation()(Account))