
import React from 'react'
import { withHelpers } from '../../utils/Injection';
import { withTranslation } from 'react-i18next';
import DashboardUIHelper from '../../utils/DashboardUIHelper';
import DateUtils from '../../utils/DateUtils';
import Comparators from '../../utils/Comparators';
import NumberUtils from '../../utils/NumberUtils';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import MultiRangeSlider from "multi-range-slider-react";
import { GoTriangleUp } from "react-icons/go";
import { GoTriangleDown } from "react-icons/go";
import Checkbox from 'react-custom-checkbox';
import * as Icon from "react-icons/fi";
import BaseComponent from '../BaseComponent';

const DEFAULT_TEMPERATURE_CELSIUS_ALERT = 7;
const DEFAULT_TEMPERATURE_ALERT_SELECTION = true;

const WarehouseFilterValue = {
    ShowAll: 0,
    ShowNone: 1,
    ShowYes: 2,
    ShowNo: 3
}

const TabFilter = {
    TemperatureDate: "temperature_Date",
    Machine: "machine",
    Warehouse: "warehouse"
}

const MachinesSorting = {
    TempStatus: 0,
    ID: 1,
    LastTemp: 2,
    Temp: 3,
    Description: 4
}

const SortingDirection = {
    Asc: 0,
    Desc: 1
}

class DashboardTemperature extends BaseComponent {

    constructor(props) {
        super(props);
        if (props.reference) {
            props.reference.current = this;
        }
        this.allMachines = props.machines != null ? props.machines : [];
        this.allAggregatedEvents = props.aggregatedEvents != null ? props.aggregatedEvents : [];
        var dateStart = null;
        var dateEnd = null;
        if (this.allAggregatedEvents.length > 0) {
            dateStart = new Date(this.allAggregatedEvents[0].timestamp);
            dateEnd = new Date(this.allAggregatedEvents[this.allAggregatedEvents.length - 1].timestamp);
        }
        var onlyAlert = DEFAULT_TEMPERATURE_ALERT_SELECTION;
        const filteredData = this.calculateFilteredData(null, null, null, null, DEFAULT_TEMPERATURE_ALERT_SELECTION);
        this.state = {
            machines: filteredData.machines,
            aggregatedEvents: filteredData.aggregatedEvents,
            dateStart: dateStart,
            dateEnd: dateEnd,
            filterDateStart: dateStart,
            filterDateEnd: dateEnd,
            temperaturesProduct: filteredData.temperaturesProduct,
            temperaturesCondenser: filteredData.temperaturesCondenser,
            temperaturesEvaporator: filteredData.temperaturesEvaporator,
            temperaturesChartData: filteredData.temperaturesChartData,
            lastWeekData: filteredData.lastWeekData,
            avgTemperature: filteredData.avgTemperature,
            onlyAlert: onlyAlert,
            tabFilters: null,
            machinesSorting: { sorting: MachinesSorting.ID, direction: SortingDirection.Asc }
        };
        this.checkboxWarehouseYesRef = React.createRef();
        this.checkboxWarehouseNoRef = React.createRef();
        this.checkboxOutOfRange = React.createRef();
        this.refresh = this.refresh.bind(this);
        this.onDateFilterChange = this.onDateFilterChange.bind(this);
    }

    refresh(updatedMachines, aggregatedEvents) {
        this.allMachines = updatedMachines != null ? updatedMachines : [];
        this.allAggregatedEvents = aggregatedEvents != null ? aggregatedEvents : [];
        var filter = null;
        var value = null;
        if (this.state.tabFilters && Object.keys(this.state.tabFilters).length !== 0) {
            filter = Object.keys(this.state.tabFilters)[0];
            value = this.state.tabFilters[filter];
        }
        var dateStart = null;
        var dateEnd = null;
        if (this.allAggregatedEvents.length > 0) {
            dateStart = new Date(this.allAggregatedEvents[0].timestamp);
            dateEnd = new Date(this.allAggregatedEvents[this.allAggregatedEvents.length - 1].timestamp);
        }
        var onlyAlert = DEFAULT_TEMPERATURE_ALERT_SELECTION;
        const filteredData = this.calculateFilteredData(null, null, null, null, onlyAlert);
        this.setState({
            machines: filteredData.machines,
            aggregatedEvents: filteredData.aggregatedEvents,
            temperaturesProduct: filteredData.temperaturesProduct,
            temperaturesCondenser: filteredData.temperaturesCondenser,
            temperaturesEvaporator: filteredData.temperaturesEvaporator,
            temperaturesChartData: filteredData.temperaturesChartData,
            avgTemperature: filteredData.avgTemperature,
            lastWeekData: filteredData.lastWeekData,
            dateStart: dateStart,
            dateEnd: dateEnd,
            filterDateStart: dateStart,
            filterDateEnd: dateEnd,
            onlyAlert: onlyAlert
        });
    }

    reloadDataAndUpdateOnlyAlert(onlyAlert) {
        const filteredData = this.calculateFilteredData(this.state.tabFilters != null ? Object.keys(this.state.tabFilters)[0] : null, this.state.tabFilters != null ? Object.values(this.state.tabFilters)[0] : null, this.state.filterDateStart, this.state.filterDateEnd, onlyAlert)
        this.setState({
            machines: filteredData.machines,
            aggregatedEvents: filteredData.aggregatedEvents,
            temperaturesProduct: filteredData.temperaturesProduct,
            temperaturesCondenser: filteredData.temperaturesCondenser,
            temperaturesEvaporator: filteredData.temperaturesEvaporator,
            temperaturesChartData: filteredData.temperaturesChartData,
            avgTemperature: filteredData.avgTemperature,
            lastWeekData: filteredData.lastWeekData,
            filterDateStart: this.state.filterDateStart,
            filterDateEnd: this.state.filterDateEnd,
            onlyAlert: onlyAlert
        });
    }

    reloadDataAndUpdateDate(dateStart, dateEnd) {
        const filteredData = this.calculateFilteredData(this.state.tabFilters != null ? Object.keys(this.state.tabFilters)[0] : null, this.state.tabFilters != null ? Object.values(this.state.tabFilters)[0] : null, dateStart, dateEnd)
        this.setState({
            machines: filteredData.machines,
            aggregatedEvents: filteredData.aggregatedEvents,
            temperaturesProduct: filteredData.temperaturesProduct,
            temperaturesCondenser: filteredData.temperaturesCondenser,
            temperaturesEvaporator: filteredData.temperaturesEvaporator,
            temperaturesChartData: filteredData.temperaturesChartData,
            avgTemperature: filteredData.avgTemperature,
            lastWeekData: filteredData.lastWeekData,
            filterDateStart: dateStart != null ? dateStart : this.state.filterDateStart,
            filterDateEnd: dateEnd != null ? dateEnd : this.state.filterDateEnd
        });
    }

    allAggregatedEventsFilteredByDate(dateStart, dateEnd) {
        var aggregatedEvents = this.allAggregatedEvents != null ? this.allAggregatedEvents : [];
        if (this.state != null && (dateStart != null || this.state.filterDateStart != null) && (dateEnd != null || this.state.filterDateEnd != null)) {
            const dateStartStr = DateUtils.formatYYYYMMDD(dateStart != null ? dateStart : this.state.filterDateStart);
            const dateEndStr = DateUtils.formatYYYYMMDD(dateEnd != null ? dateEnd : this.state.filterDateEnd);
            aggregatedEvents = aggregatedEvents.filter((aggregatedEvent) => aggregatedEvent.timestamp >= dateStartStr && aggregatedEvent.timestamp <= dateEndStr)
        }
        return aggregatedEvents;
    }

    filterMachinesAndEventsOnlyAlert(machines, aggregatedEvents) {
        machines = machines.filter((machine => {
            if (machine.getLastTemperatures() == null) {
                return false;
            }
            const temperatures = Object.values(machine.getLastTemperatures());
            return temperatures.length > 0 && temperatures[temperatures.length - 1] > DEFAULT_TEMPERATURE_CELSIUS_ALERT;
        }));
        var machineAssetIds = new Set();
        var machineImeis = new Set();
        machines.forEach((machine) => {
            machineAssetIds.add(machine.getAssetId());
            machineImeis.add(machine.getImei());
        });
        aggregatedEvents = aggregatedEvents.filter((aggregatedEvent) => machineAssetIds.has(aggregatedEvent.last_asset_id) || machineImeis.has(aggregatedEvent.imei));
        return {
            machines: machines,
            aggregatedEvents: aggregatedEvents
        }
    }

    calculateFilteredData(filter, value, dateStart = null, dateEnd = null, onlyAlertParam = null) {
        var machines = null;
        var aggregatedEvents = null;
        var temperatures = null;
        var temperaturesChartData = null;
        var avgTemperature = null;
        var lastWeekData = null;
        var onlyAlert = onlyAlertParam != null ? onlyAlertParam : this.state != null ? this.state.onlyAlert : DEFAULT_TEMPERATURE_ALERT_SELECTION;

        switch (filter) {
            case TabFilter.TemperatureDate:
                machines = this.allMachines != null ? this.allMachines : [];
                aggregatedEvents = this.allAggregatedEventsFilteredByDate(dateStart, dateEnd);
                var date = new Date(value);
                const aggregatedEventsReduced = this.allAggregatedEventsFilteredByDate(date, date);
                if (onlyAlert) {
                    const filteredOnlyAlert = this.filterMachinesAndEventsOnlyAlert(machines, aggregatedEvents);
                    machines = filteredOnlyAlert.machines;
                    aggregatedEvents = filteredOnlyAlert.aggregatedEvents;
                    const filteredOnlyAlertReduced = this.filterMachinesAndEventsOnlyAlert(machines, aggregatedEventsReduced);
                    aggregatedEventsReduced = filteredOnlyAlertReduced.aggregatedEvents;
                }
                const temperaturesAll = this.calculateTemperatures(aggregatedEvents);
                temperatures = this.calculateTemperatures(aggregatedEventsReduced);
                temperaturesChartData = this.generateTemperaturesChartData(dateStart, dateEnd, temperaturesAll.temperaturesProduct, temperaturesAll.temperaturesCondenser, temperaturesAll.temperaturesEvaporator);
                avgTemperature = (temperatures.temperaturesProduct != null && Object.values(temperatures.temperaturesProduct).length > 0) ? Object.values(temperatures.temperaturesProduct).reduce((a, b) => a + b) / Object.values(temperatures.temperaturesProduct).length : null;
                lastWeekData = this.generateLastWeekData(machines);
                break;
            case TabFilter.Machine:
                const machine = (this.allMachines != null ? this.allMachines : []).find((machine) => machine.getAssetId() === value);
                machines = [machine];
                aggregatedEvents = this.allAggregatedEventsFilteredByDate(dateStart, dateEnd).filter((event) => event.imei === machine.getImei() || event.last_asset_id === machine.getAssetId());
                temperatures = this.calculateTemperatures(aggregatedEvents);
                temperaturesChartData = this.generateTemperaturesChartData(dateStart, dateEnd, temperatures.temperaturesProduct, temperatures.temperaturesCondenser, temperatures.temperaturesEvaporator);
                avgTemperature = (temperatures.temperaturesProduct != null && Object.values(temperatures.temperaturesProduct).length > 0) ? Object.values(temperatures.temperaturesProduct).reduce((a, b) => a + b) / Object.values(temperatures.temperaturesProduct).length : null;
                lastWeekData = this.generateLastWeekData(machines);
                break;
            case TabFilter.Warehouse:
                machines = this.allMachines != null ? this.allMachines : [];
                aggregatedEvents = this.allAggregatedEventsFilteredByDate(dateStart, dateEnd);
                if (onlyAlert) {
                    const filteredOnlyAlert = this.filterMachinesAndEventsOnlyAlert(machines, aggregatedEvents);
                    machines = filteredOnlyAlert.machines;
                    aggregatedEvents = filteredOnlyAlert.aggregatedEvents;
                }
                switch (value) {
                    case WarehouseFilterValue.ShowNone:
                        machines = [];
                        break;
                    case WarehouseFilterValue.ShowYes:
                        machines = machines.filter((machine) => machine.isWarehouse());
                        break;
                    case WarehouseFilterValue.ShowNo:
                        machines = machines.filter((machine) => !machine.isWarehouse());
                        break;
                    case WarehouseFilterValue.ShowAll:
                        break;
                }
                const machineImeis = machines.map((machine) => machine.getImei());
                const machineAssetIds = machines.map((machine) => machine.getAssetId());
                aggregatedEvents = aggregatedEvents.filter((event) => machineImeis.includes(event.imei) || machineAssetIds.includes(event.last_asset_id));
                temperatures = this.calculateTemperatures(aggregatedEvents);
                temperaturesChartData = this.generateTemperaturesChartData(dateStart, dateEnd, temperatures.temperaturesProduct, temperatures.temperaturesCondenser, temperatures.temperaturesEvaporator);
                avgTemperature = (temperatures.temperaturesProduct != null && Object.values(temperatures.temperaturesProduct).length > 0) ? Object.values(temperatures.temperaturesProduct).reduce((a, b) => a + b) / Object.values(temperatures.temperaturesProduct).length : null;
                lastWeekData = this.generateLastWeekData(machines);
                break;
            default:
                machines = this.allMachines != null ? this.allMachines : [];
                aggregatedEvents = this.allAggregatedEventsFilteredByDate(dateStart, dateEnd);
                if (onlyAlert) {
                    const filteredOnlyAlert = this.filterMachinesAndEventsOnlyAlert(machines, aggregatedEvents);
                    machines = filteredOnlyAlert.machines;
                    aggregatedEvents = filteredOnlyAlert.aggregatedEvents;
                }
                temperatures = this.calculateTemperatures(aggregatedEvents);
                temperaturesChartData = this.generateTemperaturesChartData(dateStart, dateEnd, temperatures.temperaturesProduct, temperatures.temperaturesCondenser, temperatures.temperaturesEvaporator);
                avgTemperature = (temperatures.temperaturesProduct != null && Object.values(temperatures.temperaturesProduct).length > 0) ? Object.values(temperatures.temperaturesProduct).reduce((a, b) => a + b) / Object.values(temperatures.temperaturesProduct).length : null;
                lastWeekData = this.generateLastWeekData(machines);
                break;
        }

        return {
            machines: machines,
            aggregatedEvents: aggregatedEvents,
            temperaturesProduct: temperatures.temperaturesProduct,
            temperaturesCondenser: temperatures.temperaturesCondenser,
            temperaturesEvaporator: temperatures.temperaturesEvaporator,
            temperaturesChartData: temperaturesChartData,
            avgTemperature: avgTemperature,
            lastWeekData: lastWeekData
        }
    }

    calculateTemperatures(aggregatedEvents) {
        var temperaturesProduct = {};
        var temperaturesCondenser = {};
        var temperaturesEvaporator = {};
        aggregatedEvents.forEach(event => {
            if (event.temp != null) {
                if (!(event.timestamp in temperaturesProduct)) {
                    temperaturesProduct[event.timestamp] = [event.temp];
                } else {
                    temperaturesProduct[event.timestamp].push(event.temp);
                }
            }
            if (event.temp_condenser != null) {
                if (!(event.timestamp in temperaturesCondenser)) {
                    temperaturesCondenser[event.timestamp] = [event.temp_condenser];
                } else {
                    temperaturesCondenser[event.timestamp].push(event.temp_condenser);
                }
            }
            if (event.temp_evaporator != null) {
                if (!(event.timestamp in temperaturesEvaporator)) {
                    temperaturesEvaporator[event.timestamp] = [event.temp_evaporator];
                } else {
                    temperaturesEvaporator[event.timestamp].push(event.temp_evaporator);
                }
            }
        });
        var temperaturesProductAvg = {};
        for (const [timestamp, temperatures] of Object.entries(temperaturesProduct)) {
            temperaturesProductAvg[timestamp] = temperatures.reduce((a, b) => a + b) / temperatures.length;
        }
        var temperaturesCondenserAvg = {};
        for (const [timestamp, temperatures] of Object.entries(temperaturesCondenser)) {
            temperaturesCondenserAvg[timestamp] = temperatures.reduce((a, b) => a + b) / temperatures.length;
        }
        var temperaturesEvaporatorAvg = {};
        for (const [timestamp, temperatures] of Object.entries(temperaturesEvaporator)) {
            temperaturesEvaporatorAvg[timestamp] = temperatures.reduce((a, b) => a + b) / temperatures.length;
        }
        return {
            temperaturesProduct: temperaturesProductAvg,
            temperaturesCondenser: temperaturesCondenserAvg,
            temperaturesEvaporator: temperaturesEvaporatorAvg
        }
    }

    generateTemperaturesChartData(dateStart, dateEnd, temperaturesProduct, temperaturesCondenser, temperaturesEvaporator) {
        const tempsChartLabels = [];
        var dateFrom = new Date(dateStart != null ? dateStart : this.state != null ? this.state.filterDateStart : this.allAggregatedEvents[0].timestamp);
        const dateTo = new Date(dateEnd != null ? dateEnd : this.state != null ? this.state.filterDateEnd : this.allAggregatedEvents[this.allAggregatedEvents.length - 1].timestamp);
        while (dateFrom <= dateTo) {
            tempsChartLabels.push(DateUtils.formatYYYYMMDD(dateFrom));
            dateFrom.setDate(dateFrom.getDate() + 1);
        }
        const temperaturesChartProduct = [];
        const temperaturesChartCondenser = [];
        const temperaturesChartEvaporator = [];
        tempsChartLabels.forEach(timestamp => {
            temperaturesChartProduct.push(Math.round(temperaturesProduct[timestamp] * 10) / 10);
            temperaturesChartCondenser.push(Math.round(temperaturesCondenser[timestamp] * 10) / 10);
            temperaturesChartEvaporator.push(Math.round(temperaturesEvaporator[timestamp] * 10) / 10);
        });
        return {
            temperaturesLabels: tempsChartLabels,
            temperaturesProduct: temperaturesChartProduct,
            temperaturesCondenser: temperaturesChartCondenser,
            temperaturesEvaporator: temperaturesChartEvaporator
        }
    }

    generateLastWeekData(machines) {
        const temperatures = {};
        machines.forEach(machine => {
            if (machine.getLastTemperatures() != null) {
                for (const [timestamp, temperature] of Object.entries(machine.getLastTemperatures())) {
                    if (timestamp in temperatures) {
                        temperatures[timestamp].push(temperature);
                    } else {
                        temperatures[timestamp] = [temperature];
                    }
                }
            }
        });
        const temperaturesAvg = {};
        for (const [timestamp, temperaturesArray] of Object.entries(temperatures)) {
            temperaturesAvg[timestamp] = temperaturesArray.reduce((a, b) => a + b) / temperaturesArray.length;
        }
        return temperaturesAvg;
    }

    sortedMachines(machinesToSort, sorting, direction) {
        const machines = machinesToSort ? [...machinesToSort] : [];
        switch (sorting) {
            case MachinesSorting.TempStatus:
                if (direction === SortingDirection.Asc) {
                    machines.sort((a, b) => a.getTempStatus() - b.getTempStatus());
                } else {
                    machines.sort((a, b) => b.getTempStatus() - a.getTempStatus());
                }
                break;
            case MachinesSorting.ID:
                if (direction === SortingDirection.Asc) {
                    machines.sort((a, b) => Comparators.compareStringsAsc(a.getAssetId(), b.getAssetId()));
                } else {
                    machines.sort((a, b) => Comparators.compareStringsDesc(a.getAssetId(), b.getAssetId()));
                }
                break;
            case MachinesSorting.LastTemp:
                if (direction === SortingDirection.Asc) {
                    machines.sort((a, b) => Comparators.compareStringsAsc(a.getLastTemperatureDate() != null ? a.getLastTemperatureDate() : "0000", b.getLastTemperatureDate() != null ? b.getLastTemperatureDate() : "0000"));
                } else {
                    machines.sort((a, b) => Comparators.compareStringsDesc(a.getLastTemperatureDate() != null ? a.getLastTemperatureDate() : "0000", b.getLastTemperatureDate() != null ? b.getLastTemperatureDate() : "0000"));
                }
                break;
            case MachinesSorting.Temp:
                if (direction === SortingDirection.Asc) {
                    machines.sort((a, b) => (a.getLastTemperature() != null ? a.getLastTemperature() : -10000000) - (b.getLastTemperature() != null ? b.getLastTemperature() : -10000000));
                } else {
                    machines.sort((a, b) => (b.getLastTemperature() != null ? b.getLastTemperature() : -10000000) - (a.getLastTemperature() != null ? a.getLastTemperature() : -10000000));
                }
                break;
            case MachinesSorting.Description:
                if (direction === SortingDirection.Asc) {
                    machines.sort((a, b) => Comparators.compareStringsAsc(a.getCountyCoolerModelClient(), b.getCountyCoolerModelClient()));
                } else {
                    machines.sort((a, b) => Comparators.compareStringsDesc(a.getCountyCoolerModelClient(), b.getCountyCoolerModelClient()));
                }
                break;
        }
        return machines;
    }

    sortMachinesClick(e, sorting, direction) {
        e.preventDefault();
        this.sortMachines(sorting, direction);
    }

    sortMachines(sorting, direction) {
        const sortedMachines = this.sortedMachines(this.state.machines, sorting, direction);
        this.setState({
            machines: sortedMachines,
            machinesSorting: { sorting: sorting, direction: direction }
        });
    }

    tabFilterSelected(e, filter, value) {
        try {
            e.preventDefault();
        } catch(error) {
            //
        }
        var tabFilters = null;
        var machines = null;
        var aggregatedEvents = null;
        var temperaturesProduct = null;
        var temperaturesCondenser = null;
        var temperaturesEvaporator = null;
        var temperaturesChartData = null;
        var avgTemperature = null;
        var lastWeekData = null;

        if (this.state.tabFilters && this.state.tabFilters[filter] === value) {
            tabFilters = null;
            const filteredData = this.calculateFilteredData(null, null, this.state.dateStart, this.state.dateEnd, this.state.onlyAlert);
            machines = filteredData.machines;
            aggregatedEvents = filteredData.aggregatedEvents;
            temperaturesProduct = filteredData.temperaturesProduct;
            temperaturesCondenser = filteredData.temperaturesCondenser;
            temperaturesEvaporator = filteredData.temperaturesEvaporator;
            temperaturesChartData = filteredData.temperaturesChartData;
            avgTemperature = filteredData.avgTemperature;
            lastWeekData = filteredData.lastWeekData;
        } else {
            tabFilters = {
                [filter]: value
            };
            const filteredData = this.calculateFilteredData(filter, value, this.state.dateStart, this.state.dateEnd, this.state.onlyAlert);
            machines = filteredData.machines;
            aggregatedEvents = filteredData.aggregatedEvents;
            temperaturesProduct = filteredData.temperaturesProduct;
            temperaturesCondenser = filteredData.temperaturesCondenser;
            temperaturesEvaporator = filteredData.temperaturesEvaporator;
            temperaturesChartData = filteredData.temperaturesChartData;
            avgTemperature = filteredData.avgTemperature;
            lastWeekData = filteredData.lastWeekData;
        }
        this.setState({
            tabFilters: tabFilters,
            machines: machines,
            aggregatedEvents: aggregatedEvents,
            temperaturesProduct: temperaturesProduct,
            temperaturesCondenser: temperaturesCondenser,
            temperaturesEvaporator: temperaturesEvaporator,
            temperaturesChartData: temperaturesChartData,
            avgTemperature: avgTemperature,
            lastWeekData: lastWeekData
        });
    }

    onDateFilterChange(e) {
        e.preventDefault();
        if (e.target.id === "start") {
            this.reloadDataAndUpdateDate(new Date(e.target.value), null);
        } else {
            this.reloadDataAndUpdateDate(null, new Date(e.target.value));
        }
    }

    calculateWarehouseFilterValue(yesChecked, noChecked) {
        if (yesChecked == noChecked) {
            return yesChecked ? WarehouseFilterValue.ShowAll : WarehouseFilterValue.ShowNone;
        } else if (yesChecked) {
            return WarehouseFilterValue.ShowYes;
        } else {
            return WarehouseFilterValue.ShowNo;
        }
    }

    render() {
        const { t } = this.props;

        if (!this.props.selectedCompany) {
            return <p>{t('error_generic')}</p>
        }

        return <div className='dashboard-temperature'>
            <div className='dashboard-temperature-top'>
                <div className='chart-temperature-legend'>
                    { this.state.temperaturesChartData.temperaturesProduct != null && this.state.temperaturesChartData.temperaturesProduct.length > 0 && <span style={{color: this.props.selectedCompany.getBranding().getColorTemperatureProduct()}}>{t('product')}</span> }
                    { this.state.temperaturesChartData.temperaturesCondenser != null && this.state.temperaturesChartData.temperaturesCondenser.length > 0 && <span style={{color: this.props.selectedCompany.getBranding().getColorTemperatureCondenser()}}>{t('condenser')}</span> }
                    { this.state.temperaturesChartData.temperaturesEvaporator != null && this.state.temperaturesChartData.temperaturesEvaporator.length > 0 && <span style={{color: this.props.selectedCompany.getBranding().getColorTemperatureEvaporator()}}>{t('evaporator')}</span> }
                </div>
                <div className='chart-temperature-container'>
                     <Line options={{
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,
                            },
                            title: {
                                display: false
                            },
                            datalabels: {
                                anchor: 'end',
                                align: 'top'
                            }
                        },
                        elements: {
                            point: {
                                radius: 4,
                                hoverRadius: 4
                            }
                        },
                        ticks: {
                            precision: 0
                        },
                        onClick: (event, elements) => {
                            if (elements) {
                                if (elements.length > 0) {
                                    this.tabFilterSelected(event, TabFilter.TemperatureDate, this.state.temperaturesChartData.temperaturesLabels[elements[0].index]);
                                } else if (this.state.tabFilters && this.state.tabFilters[TabFilter.TemperatureDate]) {
                                    this.tabFilterSelected(event, TabFilter.TemperatureDate, this.state.tabFilters[TabFilter.TemperatureDate]);
                                }
                            }
                        },
                        onHover: (event, chartElement) => {
                            event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
                        }
                    }} data={{
                        labels: this.state.temperaturesChartData.temperaturesLabels.map(dateStr => DateUtils.formattedDate(dateStr, true)),
                        datasets: [{
                            data: this.state.temperaturesChartData.temperaturesProduct,
                            backgroundColor: Object.values(this.state.temperaturesChartData.temperaturesLabels).map(timestamp => this.props.selectedCompany.getBranding().getColorTemperatureProduct() + ((this.state.tabFilters && this.state.tabFilters[TabFilter.TemperatureDate] !== timestamp) ? "88" : "ff")),
                            borderColor: this.props.selectedCompany.getBranding().getColorTemperatureProduct(),
                            borderWidth: 1
                        },
                        {
                            data: this.state.temperaturesChartData.temperaturesCondenser,
                            backgroundColor: Object.values(this.state.temperaturesChartData.temperaturesLabels).map(timestamp => this.props.selectedCompany.getBranding().getColorTemperatureCondenser() + ((this.state.tabFilters && this.state.tabFilters[TabFilter.TemperatureDate] !== timestamp) ? "88" : "ff")),
                            borderColor: this.props.selectedCompany.getBranding().getColorTemperatureCondenser(),
                            borderWidth: 1
                        },
                        {
                            data: this.state.temperaturesChartData.temperaturesEvaporator,
                            backgroundColor: Object.values(this.state.temperaturesChartData.temperaturesLabels).map(timestamp => this.props.selectedCompany.getBranding().getColorTemperatureEvaporator() + ((this.state.tabFilters && this.state.tabFilters[TabFilter.TemperatureDate] !== timestamp) ? "88" : "ff")),
                            borderColor: this.props.selectedCompany.getBranding().getColorTemperatureEvaporator(),
                            borderWidth: 1
                        }]
                    }} />
                </div>
            </div>
            <div className='dashboard-temperature-middle'>
                <div className='date-inputs'>
                    <input type="date" id="start" name="date-start" value={DateUtils.formatYYYYMMDD(this.state.filterDateStart)} min={DateUtils.formatYYYYMMDD(this.state.dateStart)} max={DateUtils.formatYYYYMMDD(DateUtils.getSmallest(this.state.dateEnd, this.state.filterDateEnd))} style={{borderColor: this.props.selectedCompany.getBranding().getColorAccent(), color: this.props.selectedCompany.getBranding().getColorText()}} onChange={this.onDateFilterChange}/>
                    <input type="date" id="end" name="date-end" value={DateUtils.formatYYYYMMDD(this.state.filterDateEnd)} min={DateUtils.formatYYYYMMDD(DateUtils.getBiggest(this.state.dateStart, this.state.filterDateStart))} max={DateUtils.formatYYYYMMDD(this.state.dateEnd)} style={{borderColor: this.props.selectedCompany.getBranding().getColorAccent(), color: this.props.selectedCompany.getBranding().getColorText()}} onChange={this.onDateFilterChange} />
                </div>
                <MultiRangeSlider
                    min={0}
                    max={DateUtils.daysBetweenDates(this.state.dateStart, this.state.dateEnd)}
                    minValue={DateUtils.daysBetweenDates(this.state.dateStart, this.state.filterDateStart)}
                    maxValue={DateUtils.daysBetweenDates(this.state.dateStart, this.state.filterDateEnd)}
                    canMinMaxValueSame={true}
                    onChange={(result) => {
                        this.reloadDataAndUpdateDate(DateUtils.addDaysCopy(this.state.dateStart, result.minValue), DateUtils.addDaysCopy(this.state.dateStart, result.maxValue));
                    }}
                    label={false}
                    ruler={false}
                    style={{ border: "none", boxShadow: "none", padding: "15px 10px" }}
                    barLeftColor={this.props.selectedCompany.getBranding().getColorAccent() + '88'}
                    barInnerColor={this.props.selectedCompany.getBranding().getColorAccent()}
                    barRightColor={this.props.selectedCompany.getBranding().getColorAccent() + '88'}
                    thumbLeftColor={this.props.selectedCompany.getBranding().getColorAccent()}
                    thumbRightColor={this.props.selectedCompany.getBranding().getColorAccent()}
                />
            </div>
            <div className='dashboard-temperature-bottom'>
                <div className='dashboard-temperature-bottom-left'>
                    <table className='clickable sortable' style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                        <thead style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                            <tr>
                                { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), this.state.machinesSorting.sorting === MachinesSorting.TempStatus ? (this.state.machinesSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>, (e) => this.sortMachinesClick(e, MachinesSorting.TempStatus, this.state.machinesSorting.sorting === MachinesSorting.TempStatus  && this.state.machinesSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                                { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('device'), this.state.machinesSorting.sorting === MachinesSorting.ID ? (this.state.machinesSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortMachinesClick(e, MachinesSorting.ID, this.state.machinesSorting.sorting === MachinesSorting.ID  && this.state.machinesSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                                { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('last_temperature'), this.state.machinesSorting.sorting === MachinesSorting.LastTemp ? (this.state.machinesSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortMachinesClick(e, MachinesSorting.LastTemp, this.state.machinesSorting.sorting === MachinesSorting.LastTemp  && this.state.machinesSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                                { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), this.state.machinesSorting.sorting === MachinesSorting.Temp ? (this.state.machinesSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>, (e) => this.sortMachinesClick(e, MachinesSorting.Temp, this.state.machinesSorting.sorting === MachinesSorting.Temp && this.state.machinesSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                                { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), this.state.machinesSorting.sorting === MachinesSorting.Description ? (this.state.machinesSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>, (e) => this.sortMachinesClick(e, MachinesSorting.Description, this.state.machinesSorting.sorting === MachinesSorting.Description  && this.state.machinesSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.machines.map((machine) => <tr onClick={(e) => this.tabFilterSelected(e, TabFilter.Machine, machine.getAssetId())}  style={{opacity: (this.state.tabFilters && this.state.tabFilters[TabFilter.Machine]) ? (this.state.tabFilters[TabFilter.Machine] === machine.getAssetId() ? 1 : 0.5) : 1}}>
                                { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), DashboardUIHelper.iconSvgForTempStatus(machine.getTempStatus(), t)) }
                                { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), machine.getAssetId()) }
                                { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), DateUtils.formattedDate(machine.getLastTemperatureDate())) }
                                { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), <span style={{ color: (machine.getLastTemperature() && machine.getLastTemperature() > DEFAULT_TEMPERATURE_CELSIUS_ALERT) ? this.props.selectedCompany.getBranding().getColorTemperatureKo() : this.props.selectedCompany.getBranding().getColorTemperatureOk() }}>{ machine.getLastTemperature() != null ? t('format_amount_celsius').replace('{{amount}}', NumberUtils.formatNumberMaxDecimals(machine.getLastTemperature(), 1)) : '' }</span>) }
                                { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), machine.getCountyCoolerModelClient()) }
                            </tr>) }
                        </tbody>
                        <tfoot style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                            <tr>
                                { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), null) }
                                { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), this.state.machines.length) }
                                { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), null) }
                                { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), null) }
                                { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), null) }
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div className='dashboard-temperature-bottom-right'>
                    <div className='dashboard-temperature-bottom-right-controls'>
                        <div>
                            <p>{t('warehouse')}</p>
                            <Checkbox
                                icon={<Icon.FiCheck color={this.props.selectedCompany.getBranding().getColorAccent()} size={13} />}
                                name="warehouse-option-yes"
                                checked={true}
                                onChange={(value, event) => {
                                    return this.tabFilterSelected(event, TabFilter.Warehouse, this.calculateWarehouseFilterValue(value, this.checkboxWarehouseNoRef.current.checked));
                                }}
                                borderColor={this.props.selectedCompany.getBranding().getColorAccent()}
                                size={14}
                                borderRadius={4}
                                containerStyle={{ cursor: "pointer" }}
                                labelStyle={{ marginLeft: 5, userSelect: "none" }}
                                label={t('yes')}
                                reference={this.checkboxWarehouseYesRef}
                            />
                            <Checkbox
                                icon={<Icon.FiCheck color={this.props.selectedCompany.getBranding().getColorAccent()} size={13} />}
                                name="warehouse-option-no"
                                checked={true}
                                onChange={(value, event) => {
                                    return this.tabFilterSelected(event, TabFilter.Warehouse, this.calculateWarehouseFilterValue(this.checkboxWarehouseYesRef.current.checked, value));
                                }}
                                borderColor={this.props.selectedCompany.getBranding().getColorAccent()}
                                size={14}
                                borderRadius={4}
                                containerStyle={{ cursor: "pointer" }}
                                labelStyle={{ marginLeft: 5, userSelect: "none" }}
                                label={t('no')}
                                reference={this.checkboxWarehouseNoRef}
                            />
                        </div>
                        <div className='dashboard-temperature-bottom-right-temp-avg'>
                            <p>{ this.state.avgTemperature != null ? t('format_amount_celsius').replace('{{amount}}', NumberUtils.formatNumberMaxDecimals(this.state.avgTemperature, 1)) : "-" }</p>
                            <p>{ t('average').toLowerCase() }</p>
                        </div>
                        <div>
                            <Checkbox
                                icon={<Icon.FiCheck color={this.props.selectedCompany.getBranding().getColorAccent()} size={13} />}
                                name="out-of-range"
                                checked={DEFAULT_TEMPERATURE_ALERT_SELECTION}
                                onChange={(value, event) => {
                                    this.reloadDataAndUpdateOnlyAlert(value)
                                }}
                                borderColor={this.props.selectedCompany.getBranding().getColorAccent()}
                                size={14}
                                borderRadius={4}
                                containerStyle={{ cursor: "pointer" }}
                                labelStyle={{ marginLeft: 5, userSelect: "none" }}
                                label={t('show_only_devices_out_of_range')}
                                reference={this.checkboxOutOfRange}
                            />
                        </div>
                    </div>
                    <div className='dashboard-temperature-bottom-right-temperatures-chart'>
                        <Line options={{
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    display: false,
                                },
                                title: {
                                    display: false
                                },
                                datalabels: {
                                    anchor: 'end',
                                    align: 'top'
                                }
                            },
                            elements: {
                                point: {
                                    radius: 4,
                                    hoverRadius: 4
                                }
                            },
                            ticks: {
                                precision: 0
                            }
                        }} data={{
                            labels: Object.keys(this.state.lastWeekData).map(timestamp => DateUtils.formattedDate(timestamp, true)),
                            datasets: [{
                                data: Object.values(this.state.lastWeekData).map(temperature => temperature != null ? (Math.round(temperature * 10) / 10) : null),
                                backgroundColor: this.props.selectedCompany.getBranding().getColorAccent(),
                                borderColor: "#888888",
                                borderWidth: 1
                            }]
                        }} />
                    </div>
                    <div className='dashboard-temperature-bottom-right-temperatures-table'>
                        <table style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                            <thead style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                                <tr>
                                    { Object.keys(this.state.lastWeekData).map(timestamp => DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), DateUtils.formattedDate(timestamp, true)))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    { Object.values(this.state.lastWeekData).map(temperature => DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), temperature != null ? NumberUtils.formatNumberMaxDecimals(temperature, 1) : "", (temperature != null && temperature > DEFAULT_TEMPERATURE_CELSIUS_ALERT) ? { background: this.props.selectedCompany.getBranding().getColorTemperatureKo() + "33", color: this.props.selectedCompany.getBranding().getColorTemperatureKo() } : {}))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default withHelpers(withTranslation()(DashboardTemperature))