import Company from "./Company";

export default class User {

    #id = null;
    #name = null;
    #email = null;
    #active = null;
    #publicKeyLast4 = null;
    #secretKeyLast4 = null;
    #companies = null;

    constructor(id, name, email, active, publicKeyLast4, secretKeyLast4, companies) {
        this.#id = id;
        this.#name = name;
        this.#email = email;
        this.#active = active;
        this.#publicKeyLast4 = publicKeyLast4;
        this.#secretKeyLast4 = secretKeyLast4;
        this.#companies = companies;
    }

    static fromServerResponse(serverResponse) {
        return new User(
            serverResponse.id,
            serverResponse.name,
            serverResponse.email,
            serverResponse.active,
            serverResponse.public_key_last4,
            serverResponse.secret_key_last4,
            serverResponse.companies != null ? serverResponse.companies.map(companyItem => Company.fromServerResponse(companyItem)) : []
        )
    }

    getId() {
        return this.#id;
    }

    setId(id) {
        this.#id = id;
    }

    getName() {
        return this.#name;
    }

    setName(name) {
        this.#name = name;
    }

    getEmail() {
        return this.#email;
    }

    setEmail(email) {
        this.#email = email;
    }

    isActive() {
        return this.#active === true;
    }

    getActive() {
        return this.#active;
    }

    setActive(active) {
        this.#active = active;
    }

    getPublicKeyLast4() {
        return this.#publicKeyLast4;
    }

    setPublicKeyLast4(publicKeyLast4) {
        this.#publicKeyLast4 = publicKeyLast4;
    }

    getSecretKeyLast4() {
        return this.#secretKeyLast4;
    }

    setSecretKeyLast4(secretKeyLast4) {
        this.#secretKeyLast4 = secretKeyLast4;
    }

    getCompanies() {
        return this.#companies;
    }

    setCompanies(companies) {
        this.#companies = companies;
    }
}