
import React from 'react'
import { withHelpers } from '../../utils/Injection';
import { withTranslation } from 'react-i18next';
import DateUtils from '../../utils/DateUtils';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import MultiRangeSlider from "multi-range-slider-react";

import { GoTriangleUp } from "react-icons/go";
import { GoTriangleDown } from "react-icons/go";
import DashboardUIHelper from '../../utils/DashboardUIHelper';
import Comparators from '../../utils/Comparators';
import NumberUtils from '../../utils/NumberUtils';
import Checkbox from 'react-custom-checkbox';
import * as Icon from "react-icons/fi";
import BaseComponent from '../BaseComponent';

const TabFilter = {
    DoorOpenings: "door_openings",
    Footfall: "footfall",
    POS: "pos",
    Range: "range",
    Warehouse: "warehouse"
}

const RangeFilterValue = {
    ShowAll: 7,
    ShowTopMiddle: 6,
    ShowTopBottom: 5,
    ShowTop: 4,
    ShowMiddleBottom: 3,
    ShowMiddle: 2,
    ShowBottom: 1,
    ShowNone: 0
}

const WarehouseFilterValue = {
    ShowAll: 0,
    ShowNone: 1,
    ShowYes: 2,
    ShowNo: 3
}

const PosSorting = {
    Range: 0,
    POS: 1,
    MachinesCount: 2,
    Description: 3,
    Days: 4,
    Affluence: 5,
    Activity: 6
}

const SortingDirection = {
    Asc: 0,
    Desc: 1
}

class DashboardCommercialManagement extends BaseComponent {

    constructor(props) {
        super(props);
        if (props.reference) {
            props.reference.current = this;
        }
        this.allMachines = props.machines != null ? props.machines : [];
        this.allAggregatedEvents = props.aggregatedEvents != null ? props.aggregatedEvents : [];
        var dateStart = null;
        var dateEnd = null;
        if (this.allAggregatedEvents.length > 0) {
            dateStart = new Date(this.allAggregatedEvents[0].timestamp);
            dateEnd = new Date(this.allAggregatedEvents[this.allAggregatedEvents.length - 1].timestamp);
        }
        const filteredData = this.calculateFilteredData(null, null);
        this.state = {
            machines: filteredData.machines,
            aggregatedEvents: filteredData.aggregatedEvents,
            dateStart: dateStart,
            dateEnd: dateEnd,
            filterDateStart: dateStart,
            filterDateEnd: dateEnd,
            chartsData: filteredData.chartsData,
            pos: this.sortedPos(filteredData.pos, PosSorting.POS, SortingDirection.Asc),
            doAvg: filteredData.doAvg,
            doTotal: filteredData.doTotal,
            ffAvg: filteredData.ffAvg,
            ffTotal: filteredData.ffTotal,
            tabFilters: null,
            posSorting: { sorting: PosSorting.POS, direction: SortingDirection.Asc }
        };
        this.checkboxRangeTopRef = React.createRef();
        this.checkboxRangeMiddleRef = React.createRef();
        this.checkboxRangeBottomRef = React.createRef();
        this.checkboxWarehouseYesRef = React.createRef();
        this.checkboxWarehouseNoRef = React.createRef();
        this.refresh = this.refresh.bind(this);
        this.onDateFilterChange = this.onDateFilterChange.bind(this);
    }

    refresh(updatedMachines, aggregatedEvents) {
        this.allMachines = updatedMachines != null ? updatedMachines : [];
        this.allAggregatedEvents = aggregatedEvents != null ? aggregatedEvents : [];
        var filter = null;
        var value = null;
        if (this.state.tabFilters && Object.keys(this.state.tabFilters).length !== 0) {
            filter = Object.keys(this.state.tabFilters)[0];
            value = this.state.tabFilters[filter];
        }
        var dateStart = null;
        var dateEnd = null;
        if (this.allAggregatedEvents.length > 0) {
            dateStart = new Date(this.allAggregatedEvents[0].timestamp);
            dateEnd = new Date(this.allAggregatedEvents[this.allAggregatedEvents.length - 1].timestamp);
        }
        const filteredData = this.calculateFilteredData(null, null);
        this.setState({
            machines: filteredData.machines,
            aggregatedEvents: filteredData.aggregatedEvents,
            dateStart: dateStart,
            dateEnd: dateEnd,
            filterDateStart: dateStart,
            filterDateEnd: dateEnd,
            chartsData: filteredData.chartsData,
            pos: this.sortedPos(filteredData.pos, PosSorting.POS, SortingDirection.Asc),
            doAvg: filteredData.doAvg,
            doTotal: filteredData.doTotal,
            ffAvg: filteredData.ffAvg,
            ffTotal: filteredData.ffTotal
        });
    }

    reloadDataAndUpdateDate(dateStart, dateEnd) {
        const filteredData = this.calculateFilteredData(this.state.tabFilters != null ? Object.keys(this.state.tabFilters)[0] : null, this.state.tabFilters != null ? Object.values(this.state.tabFilters)[0] : null, dateStart, dateEnd)
        this.setState({
            machines: filteredData.machines,
            aggregatedEvents: filteredData.aggregatedEvents,
            chartsData: filteredData.chartsData,
            pos: this.sortedPos(filteredData.pos, PosSorting.POS, SortingDirection.Asc),
            doAvg: filteredData.doAvg,
            doTotal: filteredData.doTotal,
            ffAvg: filteredData.ffAvg,
            ffTotal: filteredData.ffTotal,
            filterDateStart: dateStart != null ? dateStart : this.state.filterDateStart,
            filterDateEnd: dateEnd != null ? dateEnd : this.state.filterDateEnd
        });
    }

    allAggregatedEventsFilteredByDate(dateStart, dateEnd) {
        var aggregatedEvents = this.allAggregatedEvents != null ? this.allAggregatedEvents : [];
        if (this.state != null && (dateStart != null || this.state.filterDateStart != null) && (dateEnd != null || this.state.filterDateEnd != null)) {
            const dateStartStr = DateUtils.formatYYYYMMDD(dateStart != null ? dateStart : this.state.filterDateStart);
            const dateEndStr = DateUtils.formatYYYYMMDD(dateEnd != null ? dateEnd : this.state.filterDateEnd);
            aggregatedEvents = aggregatedEvents.filter((aggregatedEvent) => aggregatedEvent.timestamp >= dateStartStr && aggregatedEvent.timestamp <= dateEndStr)
        }
        return aggregatedEvents;
    }

    calculateFilteredData(filter, value, dateStart = null, dateEnd = null) {
        var machines = null;
        var aggregatedEvents = null;
        var chartsData = null;
        var pos = null;
        var doAvg = null;
        var doTotal = null;
        var ffAvg = null;
        var ffTotal = null;

        switch (filter) {
            case TabFilter.DoorOpenings:
            case TabFilter.Footfall:
                machines = this.allMachines != null ? this.allMachines : [];
                aggregatedEvents = this.allAggregatedEventsFilteredByDate(dateStart, dateEnd);
                var chartsDataAndMetrics = this.calculateChartsDataAndMetrics(machines, aggregatedEvents);
                chartsData = chartsDataAndMetrics.chartsData;
                const filterDate = new Date(value);
                aggregatedEvents = this.allAggregatedEventsFilteredByDate(filterDate, filterDate);
                var chartsDataAndMetricsFiltered = this.calculateChartsDataAndMetrics(machines, aggregatedEvents);
                pos = this.calculatePosData(machines, aggregatedEvents);
                doAvg = chartsDataAndMetricsFiltered.doAvg;
                doTotal = chartsDataAndMetricsFiltered.doTotal;
                ffAvg = chartsDataAndMetricsFiltered.ffAvg;
                ffTotal = chartsDataAndMetricsFiltered.ffTotal;
                break;
            case TabFilter.POS:
                machines = this.allMachines != null ? this.allMachines : [];
                aggregatedEvents = this.allAggregatedEventsFilteredByDate(dateStart, dateEnd);
                const tempPosPos = this.calculatePosData(machines, aggregatedEvents).find(tempPos => tempPos.id === value);
                machines = machines.filter((machine) => machine.getPos() === value);
                const machineImeisPos = machines.map((machine) => machine.getImei());
                aggregatedEvents = aggregatedEvents.filter((aggregatedEvent) => machineImeisPos.includes(aggregatedEvent.imei));
                var chartsDataAndMetrics = this.calculateChartsDataAndMetrics(machines, aggregatedEvents);
                chartsData = chartsDataAndMetrics.chartsData;
                pos = this.calculatePosData(machines, aggregatedEvents);
                if (tempPosPos != null) {
                    pos[0].range = tempPosPos.range;
                }
                doAvg = chartsDataAndMetrics.doAvg;
                doTotal = chartsDataAndMetrics.doTotal;
                ffAvg = chartsDataAndMetrics.ffAvg;
                ffTotal = chartsDataAndMetrics.ffTotal;
                break;
            case TabFilter.Range:
                var bits = value.toString(2);
                while (bits.length < RangeFilterValue.ShowAll.toString(2).length) {
                    bits = "0" + bits;
                }
                var ranges = [...bits].map(bit => bit === "1");
                machines = this.allMachines != null ? this.allMachines : [];
                aggregatedEvents = this.allAggregatedEventsFilteredByDate(dateStart, dateEnd);
                const tempPos = this.calculatePosData(machines, aggregatedEvents).filter((pos) => {
                    switch (pos.range) {
                        case 3:
                            return ranges[0];
                        case 2:
                            return ranges[1];
                        case 1:
                            return ranges[2];
                        default:
                            return ranges[0] === ranges[1] && ranges[1] === ranges[2];
                    }
                });
                const tempPosNames = tempPos.map(pos => pos.id)
                machines = machines.filter((machine) => tempPosNames.includes(machine.getPos()));
                const machineImeisRange = machines.map((machine) => machine.getImei());
                aggregatedEvents = aggregatedEvents.filter((aggregatedEvent) => machineImeisRange.includes(aggregatedEvent.imei));
                var chartsDataAndMetrics = this.calculateChartsDataAndMetrics(machines, aggregatedEvents);
                chartsData = chartsDataAndMetrics.chartsData;
                pos = this.calculatePosData(machines, aggregatedEvents);
                pos.forEach(posItem => {
                    const match = tempPos.find((tempPos) => tempPos.id === posItem.id);
                    if (match) {
                        posItem.range = match.range;
                    }
                });
                doAvg = chartsDataAndMetrics.doAvg;
                doTotal = chartsDataAndMetrics.doTotal;
                ffAvg = chartsDataAndMetrics.ffAvg;
                ffTotal = chartsDataAndMetrics.ffTotal;
                break;
            case TabFilter.Warehouse:
                switch (value) {
                    case WarehouseFilterValue.ShowAll:
                        machines = this.allMachines != null ? this.allMachines : [];
                        break;
                    case WarehouseFilterValue.ShowNone:
                        machines = [];
                        break;
                    case WarehouseFilterValue.ShowYes:
                        machines = this.allMachines != null ? this.allMachines.filter((machine) => machine.isWarehouse() === true) : [];
                        break;
                    case WarehouseFilterValue.ShowNo:
                        machines = this.allMachines != null ? this.allMachines.filter((machine) => machine.isWarehouse() === false) : [];
                        break;
                }
                aggregatedEvents = this.allAggregatedEventsFilteredByDate(dateStart, dateEnd);
                var chartsDataAndMetrics = this.calculateChartsDataAndMetrics(machines, aggregatedEvents);
                chartsData = chartsDataAndMetrics.chartsData;
                pos = this.calculatePosData(machines, aggregatedEvents);
                doAvg = chartsDataAndMetrics.doAvg;
                doTotal = chartsDataAndMetrics.doTotal;
                ffAvg = chartsDataAndMetrics.ffAvg;
                ffTotal = chartsDataAndMetrics.ffTotal;
                break;
            default:
                machines = this.allMachines != null ? this.allMachines : [];
                aggregatedEvents = this.allAggregatedEventsFilteredByDate(dateStart, dateEnd);
                var chartsDataAndMetrics = this.calculateChartsDataAndMetrics(machines, aggregatedEvents);
                chartsData = chartsDataAndMetrics.chartsData;
                pos = this.calculatePosData(machines, aggregatedEvents);
                doAvg = chartsDataAndMetrics.doAvg;
                doTotal = chartsDataAndMetrics.doTotal;
                ffAvg = chartsDataAndMetrics.ffAvg;
                ffTotal = chartsDataAndMetrics.ffTotal;
                break;
        }

        return {
            machines: machines,
            aggregatedEvents: aggregatedEvents,
            chartsData: chartsData,
            pos: pos,
            doAvg: doAvg,
            doTotal: doTotal,
            ffAvg: ffAvg,
            ffTotal: ffTotal
        }
    }

    calculateChartsDataAndMetrics(machines, aggregatedEvents) {
        var data = {};
        var machinesWithDoorOpenings = {};
        var machinesWithFootfall = {};
        var totalDO = 0;
        var totalFF = 0;
        aggregatedEvents.forEach(event => {
            const machine = machines.find(machine => event.imei === machine.getImei() || event.last_asset_id === machine.getAssetId());
            if (machine != null) {
                if (event.door_openings != null) {
                    totalDO += event.door_openings;
                }
                if (event.footfall != null) {
                    totalFF += event.footfall;
                }
                if (event.timestamp in data) {
                    data[event.timestamp] = {
                        doorOpenings: data[event.timestamp].doorOpenings + (event.door_openings != null ? event.door_openings : 0),
                        footfall: data[event.timestamp].footfall + (event.footfall != null ? event.footfall : 0)
                    }
                } else {
                    data[event.timestamp] = {
                        doorOpenings: event.door_openings != null ? event.door_openings : 0,
                        footfall: event.footfall != null ? event.footfall : 0
                    }
                }
                if (event.door_openings != null) {
                    if (!(event.timestamp in machinesWithDoorOpenings)) {
                        machinesWithDoorOpenings[event.timestamp] = new Set();
                    }
                    machinesWithDoorOpenings[event.timestamp].add(machine.getAssetId());
                }
                if (event.footfall != null) {
                    if (!(event.timestamp in machinesWithFootfall)) {
                        machinesWithFootfall[event.timestamp] = new Set();
                    }
                    machinesWithFootfall[event.timestamp].add(machine.getAssetId());
                }
            }
        });

        for (const [key, value] of Object.entries(data)) {
            var doorOpeningsPosCount = 1;
            var footfallPosCount = 1;
            if (key in machinesWithDoorOpenings) {
                doorOpeningsPosCount = machinesWithDoorOpenings[key].size;
            }
            if (key in machinesWithFootfall) {
                footfallPosCount = machinesWithFootfall[key].size;
            }
            data[key] = {
                doorOpenings: value.doorOpenings / doorOpeningsPosCount,
                footfall: value.footfall / footfallPosCount
            }
        }

        var totalMachinesWithDoorOpenings = 0;
        var totalMachinesWithFootfall = 0;
        Object.values(machinesWithDoorOpenings).forEach(value => {
            totalMachinesWithDoorOpenings += value.size;
        });
        Object.values(machinesWithFootfall).forEach(value => {
            totalMachinesWithFootfall += value.size;
        });
        return {
            chartsData: data,
            doTotal: totalDO,
            doAvg: totalDO / Math.max(totalMachinesWithDoorOpenings, 1),
            ffTotal: totalFF,
            ffAvg: totalFF / Math.max(totalMachinesWithFootfall, 1)
        };
    }

    calculatePosData(machines, aggregatedEvents) {
        var posDict = {};
        var reducedPos = [];

        if (machines) {
            machines.forEach((machine) => {
                if (machine.getPos() in posDict) {
                    posDict[machine.getPos()].machinesCount = posDict[machine.getPos()].machinesCount + 1;
                } else {
                    posDict[machine.getPos()] = {
                        id: machine.getPos(),
                        machinesCount: 1,
                        description: machine.getDescription(),
                        days: null,
                        doorOpenings: null,
                        footfall: null
                    };
                }
            });
        }
        aggregatedEvents.forEach(event => {
            if (event.door_openings != null || event.footfall != null) {
                const machine = machines.find(machine => event.imei === machine.getImei() || event.last_asset_id === machine.getAssetId());
                const pos = machine != null ? machine.getPos() : null;
                if (pos != null && pos in posDict) {
                    var posObj = posDict[pos];
                    if (posObj.days == null) {
                        posObj.days = new Set();
                    }
                    posObj.days.add(event.timestamp);
                    if (event.door_openings != null) {
                        if (posObj.doorOpenings == null) {
                            posObj.doorOpenings = event.door_openings;
                        } else {
                            posObj.doorOpenings += event.door_openings;
                        }
                    }
                    if (event.footfall != null) {
                        if (posObj.footfall == null) {
                            posObj.footfall = event.footfall;
                        } else {
                            posObj.footfall += event.footfall;
                        }
                    }
                }
            }
        });
        
        for (const [posId, pos] of Object.entries(posDict)) {
            if (pos.footfall != null) {
                pos.footfallAvg = pos.footfall / (Math.max(pos.days != null ? pos.days.size : 1) * Math.max(pos.machinesCount != null ? pos.machinesCount : 1));
            }
            if (pos.doorOpenings != null) {
                pos.doorOpeningsAvg = pos.doorOpenings / (Math.max(pos.days != null ? pos.days.size : 1) * Math.max(pos.machinesCount != null ? pos.machinesCount : 1))
            }
            pos.range = 0;
            if (pos.doorOpeningsAvg != null) {
                reducedPos.push({
                    pos: posId,
                    doorOpeningsAvg: pos.doorOpeningsAvg
                });
            }
        }

        reducedPos.sort((a, b) => b.doorOpeningsAvg - a.doorOpeningsAvg);
        for (var index = 0; index < reducedPos.length; index++) {
            if (index < reducedPos.length / 5) {
                reducedPos[index].range = 3;
            } else if (index < reducedPos.length * 4 / 5) {
                reducedPos[index].range = 2;
            } else {
                reducedPos[index].range = 1;
            }
        }
        const ranges = Object.assign({}, ...reducedPos.map((x) => ({[x.pos]: x.range})));
        for (const [posId, pos] of Object.entries(posDict)) {
            if (posId in ranges) {
                pos.range = ranges[posId];
            }
        }

        return Object.values(posDict);
    }

    sortedPos(posToSort, sorting, direction) {
        const pos = posToSort ? [...posToSort] : [];
        switch (sorting) {
            case PosSorting.Range:
                if (direction === SortingDirection.Asc) {
                    pos.sort((a, b) => a.range - b.range);
                } else {
                    pos.sort((a, b) => b.range - a.range);
                }
                break;
            case PosSorting.POS:
                if (direction === SortingDirection.Asc) {
                    pos.sort((a, b) => Comparators.compareStringsAsc(a.id, b.id));
                } else {
                    pos.sort((a, b) => Comparators.compareStringsDesc(a.id, b.id));
                }
                break;
            case PosSorting.MachinesCount:
                if (direction === SortingDirection.Asc) {
                    pos.sort((a, b) => a.machinesCount - b.machinesCount);
                } else {
                    pos.sort((a, b) => b.machinesCount - a.machinesCount);
                }
                break;
            case PosSorting.Description:
                if (direction === SortingDirection.Asc) {
                    pos.sort((a, b) => Comparators.compareStringsAsc(a.description, b.description));
                } else {
                    pos.sort((a, b) => Comparators.compareStringsDesc(a.description, b.description));
                }
                break;
            case PosSorting.Days:
                if (direction === SortingDirection.Asc) {
                    pos.sort((a, b) => (a.days != null ? a.days.size : -1) - (b.days != null ? b.days.size : -1));
                } else {
                    pos.sort((a, b) => (b.days != null ? b.days.size : -1) - (a.days != null ? a.days.size : -1));
                }
                break;
            case PosSorting.Affluence:
                if (direction === SortingDirection.Asc) {
                    pos.sort((a, b) => (a.footfallAvg != null ? a.footfallAvg : -1) - (b.footfallAvg != null ? b.footfallAvg : -1));
                } else {
                    pos.sort((a, b) => (b.footfallAvg != null ? b.footfallAvg : -1) - (a.footfallAvg != null ? a.footfallAvg : -1));
                }
                break;
            case PosSorting.Activity:
                if (direction === SortingDirection.Asc) {
                    pos.sort((a, b) => (a.doorOpeningsAvg != null ? a.doorOpeningsAvg : -1) - (b.doorOpeningsAvg != null ? b.doorOpeningsAvg : -1));
                } else {
                    pos.sort((a, b) => (b.doorOpeningsAvg != null ? b.doorOpeningsAvg : -1) - (a.doorOpeningsAvg != null ? a.doorOpeningsAvg : -1));
                }
                break;
        }
        return pos;
    }

    sortPosClick(e, sorting, direction) {
        e.preventDefault();
        this.sortPos(sorting, direction);
    }

    sortPos(sorting, direction) {
        const sortedPos = this.sortedPos(this.state.pos, sorting, direction);
        this.setState({
            pos: sortedPos,
            posSorting: { sorting: sorting, direction: direction }
        });
    }

    tabFilterSelected(e, filter, value) {
        try {
            e.preventDefault();
        } catch(error) {
            //
        }
        var tabFilters = null;
        var machines = null;
        var aggregatedEvents = null;
        var chartsData = null;
        var pos = null;
        var doAvg = null;
        var doTotal = null;
        var ffAvg = null;
        var ffTotal = null;

        if (this.state.tabFilters && this.state.tabFilters[filter] === value) {
            tabFilters = null;
            machines = this.allMachines != null ? this.allMachines : [];
            aggregatedEvents = this.allAggregatedEvents != null ? this.allAggregatedEvents : [];
            var chartsDataAndMetrics = this.calculateChartsDataAndMetrics(machines, aggregatedEvents);
            chartsData = chartsDataAndMetrics.chartsData;
            pos = this.calculatePosData(machines, aggregatedEvents);
            doAvg = chartsDataAndMetrics.doAvg;
            doTotal = chartsDataAndMetrics.doTotal;
            ffAvg = chartsDataAndMetrics.ffAvg;
            ffTotal = chartsDataAndMetrics.ffTotal;
        } else {
            const filteredData = this.calculateFilteredData(filter, value);
            machines = filteredData.machines;
            aggregatedEvents = filteredData.aggregatedEvents;
            chartsData = filteredData.chartsData;
            pos = filteredData.pos;
            doAvg = filteredData.doAvg;
            doTotal = filteredData.doTotal;
            ffAvg = filteredData.ffAvg;
            ffTotal = filteredData.ffTotal;
            tabFilters = {
                [filter]: value
            };
        }
        this.setState({
            tabFilters: tabFilters,
            machines: machines,
            aggregatedEvents: aggregatedEvents,
            chartsData: chartsData,
            pos: this.sortedPos(pos, this.state.posSorting.sorting, this.state.posSorting.direction),
            doAvg: doAvg,
            doTotal: doTotal,
            ffAvg: ffAvg,
            ffTotal: ffTotal
        });
    }

    onDateFilterChange(e) {
        e.preventDefault();
        if (e.target.id === "start") {
            this.reloadDataAndUpdateDate(new Date(e.target.value), null);
        } else {
            this.reloadDataAndUpdateDate(null, new Date(e.target.value));
        }
    }

    calculateRangeFilterValue(topChecked, middleChecked, bottomChecked) {
        var bits = (topChecked === true ? "1" : "0") + (middleChecked === true ? "1" : "0") + (bottomChecked === true ? "1" : "0")
        return parseInt(bits, 2);
    }

    calculateWarehouseFilterValue(yesChecked, noChecked) {
        if (yesChecked == noChecked) {
            return yesChecked ? WarehouseFilterValue.ShowAll : WarehouseFilterValue.ShowNone;
        } else if (yesChecked) {
            return WarehouseFilterValue.ShowYes;
        } else {
            return WarehouseFilterValue.ShowNo;
        }
    }

    render() {
        const { t } = this.props;

        if (!this.props.selectedCompany) {
            return <p>{t('error_generic')}</p>
        }

        return <div className='dashboard-commercial-management'>
            <div className='dashboard-commercial-management-top'>
                <p>{t('activity')}</p>
                <div className='chart-commercial-container'>
                    <Line options={{
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,
                            },
                            title: {
                                display: false
                            },
                            datalabels: {
                                anchor: 'end',
                                align: 'top'
                            }
                        },
                        elements: {
                            point: {
                                radius: 4,
                                hoverRadius: 4
                            }
                        },
                        ticks: {
                            precision: 0
                        },
                        onClick: (event, elements) => {
                            if (elements) {
                                if (elements.length > 0) {
                                    this.tabFilterSelected(event, TabFilter.DoorOpenings, Object.keys(this.state.chartsData)[elements[0].index]);
                                } else if (this.state.tabFilters && this.state.tabFilters[TabFilter.DoorOpenings]) {
                                    this.tabFilterSelected(event, TabFilter.DoorOpenings, this.state.tabFilters[TabFilter.DoorOpenings]);
                                }
                            }
                        },
                        onHover: (event, chartElement) => {
                            event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
                        }
                    }} data={{
                        labels: Object.keys(this.state.chartsData).map(dateStr => DateUtils.formattedDate(dateStr, true)),
                        datasets: [{
                            data: Object.values(this.state.chartsData).map(chartData => chartData.doorOpenings != null ? (Math.round(chartData.doorOpenings * 100) / 100) : null),
                            backgroundColor: Object.keys(this.state.chartsData).map(timestamp => this.props.selectedCompany.getBranding().getColorAccent() + ((this.state.tabFilters && (this.state.tabFilters[TabFilter.Footfall] !== timestamp && this.state.tabFilters[TabFilter.DoorOpenings] !== timestamp)) ? "88" : "ff")),
                            borderColor: "#888888",
                            borderWidth: 1
                        }]
                    }} />
                </div>
                <p>{t('affluence')}</p>
                <div className='chart-commercial-container'>
                    <Bar options={{
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,
                            },
                            title: {
                                display: false
                            },
                            datalabels: {
                                anchor: 'end',
                                align: 'top'
                            }
                        },
                        ticks: {
                            precision: 0
                        },
                        onClick: (event, elements) => {
                            if (elements) {
                                if (elements.length > 0) {
                                    this.tabFilterSelected(event, TabFilter.Footfall, Object.keys(this.state.chartsData)[elements[0].index]);
                                } else if (this.state.tabFilters && this.state.tabFilters[TabFilter.Footfall]) {
                                    this.tabFilterSelected(event, TabFilter.Footfall, this.state.tabFilters[TabFilter.Footfall]);
                                }
                            }
                        },
                        onHover: (event, chartElement) => {
                            event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
                        }
                    }} data={{
                        labels: Object.keys(this.state.chartsData).map(dateStr => DateUtils.formattedDate(dateStr, true)),
                        datasets: [{
                            data: Object.values(this.state.chartsData).map(chartData => chartData.footfall != null ? (Math.round(chartData.footfall * 100) / 100) : null),
                            backgroundColor: Object.keys(this.state.chartsData).map(timestamp => this.props.selectedCompany.getBranding().getColorAccent() + ((this.state.tabFilters && (this.state.tabFilters[TabFilter.Footfall] !== timestamp && this.state.tabFilters[TabFilter.DoorOpenings] !== timestamp)) ? "88" : "ff")),
                            borderColor: "#888888",
                            borderWidth: 1
                        }]
                    }} />
                </div>
            </div>
            <div className='dashboard-commercial-management-middle'>
                <div className='date-inputs'>
                    <input type="date" id="start" name="date-start" value={DateUtils.formatYYYYMMDD(this.state.filterDateStart)} min={DateUtils.formatYYYYMMDD(this.state.dateStart)} max={DateUtils.formatYYYYMMDD(DateUtils.getSmallest(this.state.dateEnd, this.state.filterDateEnd))} style={{borderColor: this.props.selectedCompany.getBranding().getColorAccent(), color: this.props.selectedCompany.getBranding().getColorText()}} onChange={this.onDateFilterChange}/>
                    <input type="date" id="end" name="date-end" value={DateUtils.formatYYYYMMDD(this.state.filterDateEnd)} min={DateUtils.formatYYYYMMDD(DateUtils.getBiggest(this.state.dateStart, this.state.filterDateStart))} max={DateUtils.formatYYYYMMDD(this.state.dateEnd)} style={{borderColor: this.props.selectedCompany.getBranding().getColorAccent(), color: this.props.selectedCompany.getBranding().getColorText()}} onChange={this.onDateFilterChange} />
                </div>
                <MultiRangeSlider
                    min={0}
                    max={DateUtils.daysBetweenDates(this.state.dateStart, this.state.dateEnd)}
                    minValue={DateUtils.daysBetweenDates(this.state.dateStart, this.state.filterDateStart)}
                    maxValue={DateUtils.daysBetweenDates(this.state.dateStart, this.state.filterDateEnd)}
                    canMinMaxValueSame={true}
                    onChange={(result) => {
                        this.reloadDataAndUpdateDate(DateUtils.addDaysCopy(this.state.dateStart, result.minValue), DateUtils.addDaysCopy(this.state.dateStart, result.maxValue));
                    }}
                    label={false}
                    ruler={false}
                    style={{ border: "none", boxShadow: "none", padding: "15px 10px" }}
                    barLeftColor={this.props.selectedCompany.getBranding().getColorAccent() + '88'}
                    barInnerColor={this.props.selectedCompany.getBranding().getColorAccent()}
                    barRightColor={this.props.selectedCompany.getBranding().getColorAccent() + '88'}
                    thumbLeftColor={this.props.selectedCompany.getBranding().getColorAccent()}
                    thumbRightColor={this.props.selectedCompany.getBranding().getColorAccent()}
                />
            </div>
            <div className='dashboard-commercial-management-bottom'>
                <div className='dashboard-commercial-management-bottom-left'>
                    <table className='clickable sortable' style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                        <thead style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                            <tr>
                                { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), this.state.posSorting.sorting === PosSorting.Range ? (this.state.posSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>, (e) => this.sortPosClick(e, PosSorting.Range, this.state.posSorting.sorting === PosSorting.Range  && this.state.posSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                                { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('point_of_sales_short'), this.state.posSorting.sorting === PosSorting.POS ? (this.state.posSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortPosClick(e, PosSorting.POS, this.state.posSorting.sorting === PosSorting.POS  && this.state.posSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                                { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('devices'), this.state.posSorting.sorting === PosSorting.MachinesCount ? (this.state.posSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortPosClick(e, PosSorting.MachinesCount, this.state.posSorting.sorting === PosSorting.MachinesCount  && this.state.posSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                                { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('description'), this.state.posSorting.sorting === PosSorting.Description ? (this.state.posSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortPosClick(e, PosSorting.Description, this.state.posSorting.sorting === PosSorting.Description  && this.state.posSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                                { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('days'), this.state.posSorting.sorting === PosSorting.Days ? (this.state.posSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortPosClick(e, PosSorting.Days, this.state.posSorting.sorting === PosSorting.Days  && this.state.posSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                                { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('affluence'), this.state.posSorting.sorting === PosSorting.Affluence ? (this.state.posSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortPosClick(e, PosSorting.Affluence, this.state.posSorting.sorting === PosSorting.Affluence  && this.state.posSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                                { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('activity'), this.state.posSorting.sorting === PosSorting.Activity ? (this.state.posSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortPosClick(e, PosSorting.Activity, this.state.posSorting.sorting === PosSorting.Activity  && this.state.posSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.pos.map((pos) => <tr onClick={(e) => this.tabFilterSelected(e, TabFilter.POS, pos.id)}  style={{opacity: (this.state.tabFilters && this.state.tabFilters[TabFilter.POS]) ? (this.state.tabFilters[TabFilter.POS] === pos.id ? 1 : 0.5) : 1}}>
                                { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), DashboardUIHelper.iconSvgForPosRange(pos.range, t)) }
                                { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), pos.id) }
                                { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), pos.machinesCount) }
                                { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), pos.description) }
                                { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), pos.days != null ? pos.days.size : null) }
                                { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), NumberUtils.formatNumberMaxDecimals(pos.footfallAvg, 0)) }
                                { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), NumberUtils.formatNumberMaxDecimals(pos.doorOpeningsAvg, 1)) }
                            </tr>) }
                        </tbody>
                        <tfoot style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                            <tr>
                                { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), null) }
                                { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), this.state.pos.length) }
                                { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), this.state.machines.length) }
                                { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), null) }
                                { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), Math.max(...this.state.pos.map(pos => pos.days != null ? pos.days.size : 0), 0)) }
                                { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), NumberUtils.formatNumberMaxDecimals(this.state.ffAvg, 0)) }
                                { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), NumberUtils.formatNumberMaxDecimals(this.state.doAvg, 1)) }
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div className='dashboard-commercial-management-bottom-right'>
                    <div>
                        <p>{t('range')}</p>
                        <Checkbox
                            icon={<Icon.FiCheck color={this.props.selectedCompany.getBranding().getColorAccent()} size={13} />}
                            name="range-option-top"
                            checked={true}
                            onChange={(value, event) => {
                                return this.tabFilterSelected(event, TabFilter.Range, this.calculateRangeFilterValue(value, this.checkboxRangeMiddleRef.current.checked, this.checkboxRangeBottomRef.current.checked));
                            }}
                            borderColor={this.props.selectedCompany.getBranding().getColorAccent()}
                            size={14}
                            borderRadius={4}
                            containerStyle={{ cursor: "pointer" }}
                            labelStyle={{ marginLeft: 5, userSelect: "none" }}
                            label={t('range_top')}
                            reference={this.checkboxRangeTopRef}
                        />
                        <Checkbox
                            icon={<Icon.FiCheck color={this.props.selectedCompany.getBranding().getColorAccent()} size={13} />}
                            name="range-option-middle"
                            checked={true}
                            onChange={(value, event) => {
                                return this.tabFilterSelected(event, TabFilter.Range, this.calculateRangeFilterValue(this.checkboxRangeTopRef.current.checked, value, this.checkboxRangeBottomRef.current.checked));
                            }}
                            borderColor={this.props.selectedCompany.getBranding().getColorAccent()}
                            size={14}
                            borderRadius={4}
                            containerStyle={{ cursor: "pointer" }}
                            labelStyle={{ marginLeft: 5, userSelect: "none" }}
                            label={t('range_middle')}
                            reference={this.checkboxRangeMiddleRef}
                        />
                        <Checkbox
                            icon={<Icon.FiCheck color={this.props.selectedCompany.getBranding().getColorAccent()} size={13} />}
                            name="range-option-bottom"
                            checked={true}
                            onChange={(value, event) => {
                                return this.tabFilterSelected(event, TabFilter.Range, this.calculateRangeFilterValue(this.checkboxRangeTopRef.current.checked, this.checkboxRangeMiddleRef.current.checked, value));
                            }}
                            borderColor={this.props.selectedCompany.getBranding().getColorAccent()}
                            size={14}
                            borderRadius={4}
                            containerStyle={{ cursor: "pointer" }}
                            labelStyle={{ marginLeft: 5, userSelect: "none" }}
                            label={t('range_bottom')}
                            reference={this.checkboxRangeBottomRef}
                        />
                        <p>{t('warehouse')}</p>
                        <Checkbox
                            icon={<Icon.FiCheck color={this.props.selectedCompany.getBranding().getColorAccent()} size={13} />}
                            name="warehouse-option-yes"
                            checked={true}
                            onChange={(value, event) => {
                                return this.tabFilterSelected(event, TabFilter.Warehouse, this.calculateWarehouseFilterValue(value, this.checkboxWarehouseNoRef.current.checked));
                            }}
                            borderColor={this.props.selectedCompany.getBranding().getColorAccent()}
                            size={14}
                            borderRadius={4}
                            containerStyle={{ cursor: "pointer" }}
                            labelStyle={{ marginLeft: 5, userSelect: "none" }}
                            label={t('yes')}
                            reference={this.checkboxWarehouseYesRef}
                        />
                        <Checkbox
                            icon={<Icon.FiCheck color={this.props.selectedCompany.getBranding().getColorAccent()} size={13} />}
                            name="warehouse-option-no"
                            checked={true}
                            onChange={(value, event) => {
                                return this.tabFilterSelected(event, TabFilter.Warehouse, this.calculateWarehouseFilterValue(this.checkboxWarehouseYesRef.current.checked, value));
                            }}
                            borderColor={this.props.selectedCompany.getBranding().getColorAccent()}
                            size={14}
                            borderRadius={4}
                            containerStyle={{ cursor: "pointer" }}
                            labelStyle={{ marginLeft: 5, userSelect: "none" }}
                            label={t('no')}
                            reference={this.checkboxWarehouseNoRef}
                        />
                    </div>
                    <div>
                        <div className='commercial-metric' style={{borderColor: this.props.selectedCompany.getBranding().getColorAccent()}}>
                            <p>{NumberUtils.formatNumberK(this.state.doAvg, 1)}</p>
                            <p>{t('activity') + ' / ' + t('device')}</p>
                        </div>
                        <div className='commercial-metric' style={{borderColor: this.props.selectedCompany.getBranding().getColorAccent()}}>
                            <p>{NumberUtils.formatNumberK(this.state.doTotal, 1)}</p>
                            <p>{t('total_activity')}</p>
                        </div>
                    </div>
                    <div>
                        <div className='commercial-metric' style={{borderColor: this.props.selectedCompany.getBranding().getColorAccent()}}>
                            <p>{NumberUtils.formatNumberK(this.state.ffAvg, 1)}</p>
                            <p>{t('affluence') + ' / ' + t('device')}</p>
                        </div>
                        <div className='commercial-metric' style={{borderColor: this.props.selectedCompany.getBranding().getColorAccent()}}>
                            <p>{NumberUtils.formatNumberK(this.state.ffTotal, 1)}</p>
                            <p>{t('total_affluence')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default withHelpers(withTranslation()(DashboardCommercialManagement))