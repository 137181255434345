export default class IotCompanyBranding {

    #logo = null;
    #logoSecondary = null;
    #colorMain = null;
    #colorBackground = null;
    #colorAccent = null;
    #colorText = null;
    #colorTextMain = null;
    #colorTextMainAccent = null;
    #colorTemperatureOk = null;
    #colorTemperatureKo = null;
    #colorTemperatureProduct = null;
    #colorTemperatureCondenser = null;
    #colorTemperatureEvaporator = null;

    constructor(logo, logoSecondary, colorMain, colorBackground, colorAccent, colorText, colorTextMain, colorTextMainAccent, colorTemperatureOk, colorTemperatureKo, colorTemperatureProduct, colorTemperatureCondenser, colorTemperatureEvaporator) {
        this.#logo = logo;
        this.#logoSecondary = logoSecondary;
        this.#colorMain = colorMain;
        this.#colorBackground = colorBackground;
        this.#colorAccent = colorAccent;
        this.#colorText = colorText;
        this.#colorTextMain = colorTextMain;
        this.#colorTextMainAccent = colorTextMainAccent;
        this.#colorTemperatureOk = colorTemperatureOk;
        this.#colorTemperatureKo = colorTemperatureKo;
        this.#colorTemperatureProduct = colorTemperatureProduct;
        this.#colorTemperatureCondenser = colorTemperatureCondenser;
        this.#colorTemperatureEvaporator = colorTemperatureEvaporator;
    }

    static fromServerResponse(serverResponse) {
        return new IotCompanyBranding(
            serverResponse.logo,
            serverResponse.logo_secondary,
            serverResponse.color_main,
            serverResponse.color_background,
            serverResponse.color_accent,
            serverResponse.color_text,
            serverResponse.color_text_main,
            serverResponse.color_text_main_accent,
            serverResponse.color_temperature_ok,
            serverResponse.color_temperature_ko,
            serverResponse.color_temperature_product,
            serverResponse.color_temperature_condenser,
            serverResponse.color_temperature_evaporator
        );
    }

    getLogo() {
        return this.#logo;
    }

    setLogo(logo) {
        this.#logo = logo;
    }

    getLogoSecondary() {
        return this.#logoSecondary;
    }

    setLogoSecondary(logoSecondary) {
        this.#logoSecondary = logoSecondary;
    }

    getColorMain() {
        return this.#colorMain;
    }

    setColorMain(colorMain) {
        this.#colorMain = colorMain;
    }

    getColorBackground() {
        return this.#colorBackground;
    }

    setColorBackground(colorBackground) {
        this.#colorBackground = colorBackground;
    }

    getColorAccent() {
        return this.#colorAccent;
    }

    setColorAccent(colorAccent) {
        this.#colorAccent = colorAccent;
    }

    getColorText() {
        return this.#colorText;
    }

    setColorText(colorText) {
        this.#colorText = colorText;
    }

    getColorTextMain() {
        return this.#colorTextMain;
    }

    setColorTextMain(colorTextMain) {
        this.#colorTextMain = colorTextMain;
    }

    getColorTextMainAccent() {
        return this.#colorTextMainAccent;
    }

    setColorTextMainAccent(colorTextMainAccent) {
        this.#colorTextMainAccent = colorTextMainAccent;
    }

    getColorTemperatureOk() {
        return this.#colorTemperatureOk;
    }

    setColorTemperatureOk(colorTemperatureOk) {
        this.#colorTemperatureOk = colorTemperatureOk;
    }

    getColorTemperatureKo() {
        return this.#colorTemperatureKo;
    }

    setColorTemperatureKo(colorTemperatureKo) {
        this.#colorTemperatureKo = colorTemperatureKo;
    }

    getColorTemperatureProduct() {
        return this.#colorTemperatureProduct;
    }

    setColorTemperatureProduct(colorTemperatureProduct) {
        this.#colorTemperatureProduct = colorTemperatureProduct;
    }

    getColorTemperatureCondenser() {
        return this.#colorTemperatureCondenser;
    }

    setColorTemperatureCondenser(colorTemperatureCondenser) {
        this.#colorTemperatureCondenser = colorTemperatureCondenser;
    }

    getColorTemperatureEvaporator() {
        return this.#colorTemperatureEvaporator;
    }

    setColorTemperatureEvaporator(colorTemperatureEvaporator) {
        this.#colorTemperatureEvaporator = colorTemperatureEvaporator;
    }
}