
import React from 'react'
import { withHelpers } from '../../utils/Injection';
import { withTranslation } from 'react-i18next';
import DashboardUIHelper from '../../utils/DashboardUIHelper';
import BaseComponent from '../BaseComponent';

class DashboardDocumentation extends BaseComponent {

    constructor(props) {
        super(props);
        if (props.reference) {
            props.reference.current = this;
        }
        this.allMachines = props.machines != null ? props.machines : [];
        this.allAggregatedEvents = props.aggregatedEvents != null ? props.aggregatedEvents : [];

        this.state = {
            documents: {
                "GreenSystems_IoT.pptx": "https://elasticbeanstalk-eu-north-1-140292165096.s3.eu-north-1.amazonaws.com/public-assets/documents/GreenSystems_IoT.pptx",
                "MIDDLE 1M ORANGINA.jpg": "https://elasticbeanstalk-eu-north-1-140292165096.s3.eu-north-1.amazonaws.com/public-assets/documents/MIDDLE+1M+ORANGINA.JPG",
                "PICOLO ORANGINA.jpg": "https://elasticbeanstalk-eu-north-1-140292165096.s3.eu-north-1.amazonaws.com/public-assets/documents/PICOLO+ORANGINA.JPG",
                "POCM MIDDLE 1M FR.pdf": "https://elasticbeanstalk-eu-north-1-140292165096.s3.eu-north-1.amazonaws.com/public-assets/documents/POCM_+MIDDLE+1M+FR_.pdf",
                "POCM Picolo FR.pdf": "https://elasticbeanstalk-eu-north-1-140292165096.s3.eu-north-1.amazonaws.com/public-assets/documents/POCM_Picolo+FR_.pdf",
                "POCM Slim cooler FR.pdf": "https://elasticbeanstalk-eu-north-1-140292165096.s3.eu-north-1.amazonaws.com/public-assets/documents/POCM_Slim+cooler+FR_.pdf",
                "POCM ST1 FR.pdf": "https://elasticbeanstalk-eu-north-1-140292165096.s3.eu-north-1.amazonaws.com/public-assets/documents/POCM_ST1+FR_.pdf",
                "POCM ST2.1 FR.pdf": "https://elasticbeanstalk-eu-north-1-140292165096.s3.eu-north-1.amazonaws.com/public-assets/documents/POCM_ST2.1+FR_.pdf",
                "SLIM ORANGINA 2.jpg": "https://elasticbeanstalk-eu-north-1-140292165096.s3.eu-north-1.amazonaws.com/public-assets/documents/SLIM+ORANGINA+2.jpg",
                "ST1 ORANGINA.jpg": "https://elasticbeanstalk-eu-north-1-140292165096.s3.eu-north-1.amazonaws.com/public-assets/documents/ST1+ORANGINA.JPG"
            }
        };
    }

    refresh(updatedMachines, aggregatedEvents) {
        //
    }

    render() {
        const { t } = this.props;

        if (!this.props.selectedCompany) {
            return <p>{t('error_generic')}</p>
        }
        return <div className='dashboard-documentation'>
            <h1>{t('header_documentation')}</h1>
            <ul className='documentation-list'>
                { Object.entries(this.state.documents).map((entry) => <li>{DashboardUIHelper.getImgFileTypeIcon(entry[0].split('.').pop())}<a href={entry[1]} target='_blank' rel='noreferrer noopener' style={{color: this.props.selectedCompany.getBranding().getColorAccent()}}>{entry[0]}</a></li>) }
            </ul>
        </div>
    }
}

export default withHelpers(withTranslation()(DashboardDocumentation))