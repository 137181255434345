import IotCompanyBranding from "./IotCompanyBranding";

export default class IotCompany {

    #id = null;
    #taxId = null;
    #tradeName = null;
    #name = null;
    #addressLine1 = null;
    #addressLine2 = null;
    #postcode = null;
    #city = null;
    #county = null;
    #country = null;
    #email = null;
    #phone = null;
    #commerceNumber = null;
    #clientCode = null;
    #active = null;
    #branding = null;

    constructor(id, taxId, tradeName, name, addressLine1, addressLine2, postcode, city, county, country, email, phone, commerceNumber, clientCode, active, branding) {
        this.#id = id;
        this.#taxId = taxId;
        this.#tradeName = tradeName;
        this.#name = name;
        this.#addressLine1 = addressLine1;
        this.#addressLine2 = addressLine2;
        this.#postcode = postcode;
        this.#city = city;
        this.#county = county;
        this.#country = country;
        this.#email = email;
        this.#phone = phone;
        this.#commerceNumber = commerceNumber;
        this.#clientCode = clientCode;
        this.#active = active;
        this.#branding = branding;
    }

    static fromServerResponse(serverResponse) {
        return new IotCompany(
            serverResponse.id,
            serverResponse.tax_id,
            serverResponse.trade_name,
            serverResponse.name,
            serverResponse.address_line1,
            serverResponse.address_line2,
            serverResponse.postcode,
            serverResponse.city,
            serverResponse.county,
            serverResponse.country,
            serverResponse.email,
            serverResponse.phone,
            serverResponse.commerce_number,
            serverResponse.client_code,
            serverResponse.active,
            IotCompanyBranding.fromServerResponse(serverResponse.branding)
        );
    }

    getId() {
        return this.#id;
    }

    setId(id) {
        this.#id = id;
    }

    getTaxId() {
        return this.#taxId;
    }

    setTaxId(taxId) {
        this.#taxId = taxId;
    }

    getTradeName() {
        return this.#tradeName;
    }

    setTradeName(tradeName) {
        this.#tradeName = tradeName;
    }

    getName() {
        return this.#name;
    }

    setName(name) {
        this.#name = name;
    }

    getAddressLine1() {
        return this.#addressLine1;
    }

    setAddressLine1(addressLine1) {
        this.#addressLine1 = addressLine1;
    }

    getAddressLine2() {
        return this.#addressLine2;
    }

    setAddressLine2(addressLine2) {
        this.#addressLine2 = addressLine2;
    }

    getPostcode() {
        return this.#postcode;
    }

    setPostcode(postcode) {
        this.#postcode = postcode;
    }

    getCity() {
        return this.#city;
    }

    setCity(city) {
        this.#city = city;
    }

    getCounty() {
        return this.#county;
    }

    setCounty(county) {
        this.#county = county;
    }

    getCountry() {
        return this.#country;
    }

    setCountry(country) {
        this.#country = country;
    }

    getEmail() {
        return this.#email;
    }

    setEmail(email) {
        this.#email = email;
    }

    getPhone() {
        return this.#phone;
    }

    setPhone(phone) {
        this.#phone = phone;
    }

    getCommerceNumber() {
        return this.#commerceNumber;
    }

    setCommerceNumber(commerceNumber) {
        this.#commerceNumber = commerceNumber;
    }

    getClientCode() {
        return this.#clientCode;
    }

    setClientCode(clientCode) {
        this.#clientCode = clientCode;
    }

    getActive() {
        return this.#active;
    }

    isActive() {
        return this.#active === true;
    }

    setActive(active) {
        this.#active = active;
    }

    getBranding() {
        return this.#branding;
    }

    setBranding(branding) {
        this.#branding = branding;
    }
}