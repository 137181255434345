import React from 'react'
import { withTranslation } from 'react-i18next'
import { withHelpers } from '../../utils/Injection';
import BaseComponent from '../BaseComponent';

class CompaniesList extends BaseComponent {

    constructor(props) {
        super(props);
    }

    selectCompany(e, company) {
        e. preventDefault();
        this.props.selectCompany(company);
    }

    render() {
        const { t } = this.props;

        return <div className='companies-list'>{this.props.companies.map((company) => <div key={company.getId()} className='list-item-company' onClick={ (e) => this.selectCompany(e, company) } style={{ backgroundColor: company.getBranding().getColorMain() }}>
                <div className='company-logo'>
                    <img src={company.getBranding().getLogo()} alt={t('alt_logo')}/>
                </div>
                <div>
                    <p style={{ color: company.getBranding().getColorTextMain() }}>{ company.getTradeName() }</p>
                </div>
            </div>)}
        </div>
    }
}

export default withHelpers(withTranslation()(CompaniesList))