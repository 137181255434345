
import React from 'react'
import { withHelpers } from '../../utils/Injection';
import { withTranslation } from 'react-i18next';
import DateUtils from '../../utils/DateUtils';
import { GoTriangleUp } from "react-icons/go";
import { GoTriangleDown } from "react-icons/go";
import Comparators from '../../utils/Comparators';
import DashboardUIHelper from '../../utils/DashboardUIHelper';
import ApiRepository from '../../data/ApiRepository';
import BaseComponent from '../BaseComponent';

const MachinesSorting = {
    ID: 0,
    Controller: 1,
    IMEI: 2
}

const EventsSorting = {
    Timestamp: 0,
    ID: 1,
    Temp: 2,
    DO: 3,
    FF: 4,
    Lat: 5,
    Long: 6,
    ModemEvent: 7,
    ElstatEvent: 8,
    Voltage: 9,
    GmsSignal: 10
}

const SortingDirection = {
    Asc: 0,
    Desc: 1
}

const TabFilter = {
    AssetId: 0
}

class DashboardAdmin extends BaseComponent {

    constructor(props) {
        super(props);
        if (props.reference) {
            props.reference.current = this;
        }
        this.allMachines = props.machines != null ? props.machines : [];
        this.allAggregatedEvents = props.aggregatedEvents != null ? props.aggregatedEvents : [];
        const filteredData = this.calculateFilteredData(null, null);
        this.state = {
            machines: this.sortedMachines(filteredData.machines, MachinesSorting.ID, SortingDirection.Asc),
            events: null,
            tabFilters: null,
            machinesSorting: { sorting: MachinesSorting.ID, direction: SortingDirection.Asc },
            eventsSorting: { sorting: EventsSorting.Timestamp, direction: SortingDirection.Desc },
            loadingEvents: false
        };
        this.refresh = this.refresh.bind(this);
    }

    refresh(updatedMachines, aggregatedEvents) {
        this.allMachines = updatedMachines != null ? updatedMachines : [];
        this.allAggregatedEvents = aggregatedEvents != null ? aggregatedEvents : [];
        var filter = null;
        var value = null;
        if (this.state.tabFilters && Object.keys(this.state.tabFilters).length !== 0) {
            filter = Object.keys(this.state.tabFilters)[0];
            value = this.state.tabFilters[filter];
        }
        const filteredData = this.calculateFilteredData(filter, value);
        this.setState({
            machines: this.sortedMachines(filteredData.machines, this.state.machinesSorting.sorting, this.state.machinesSorting.direction),
            events: this.sortedEvents(filteredData.events, this.state.eventsSorting.sorting, this.state.eventsSorting.direction)
        });
    }

    calculateFilteredData(filter, value) {
        var machines = null;
        var events = null;
        switch (filter) { 
            case TabFilter.AssetId:
                machines = this.allMachines != null ? this.allMachines : [];
                var machine = machines.find((machine) => machine.getAssetId() === value);
                events = machine.getMachineEvents();
                if (events == null) {
                    this.setState({
                        loadingEvents: true
                    });
                    ApiRepository.getMachineEvents(
                        this.props.selectedCompany.getId(),
                        machine.getAssetId(),
                        null,
                        null,
                        (events) => {
                            machine.setMachineEvents(events);
                            this.setState({
                                machines: machines,
                                events: this.sortedEvents(events, this.state.eventsSorting.sorting, this.state.eventsSorting.direction),
                                loadingEvents: false
                            });
                        },
                        (sessionExpired, errorMessage) => {
                            this.setState({
                                loadingEvents: false
                            });
                            if (sessionExpired) {
                                this.resetTokenAndGoToLogin();
                                alert(errorMessage);
                            }
                        }
                    )
                }
                break;
            default:
                machines = this.allMachines != null ? this.allMachines : [];
                break;
        }
        return {
            machines: machines,
            events: events
        };
    }

    sortedMachines(machinesToSort, sorting, direction) {
        const machines = machinesToSort ? [...machinesToSort] : [];
        switch (sorting) {
            case MachinesSorting.ID:
                if (direction === SortingDirection.Asc) {
                    machines.sort((a, b) => Comparators.compareStringsAsc(a.getAssetId(), b.getAssetId()));
                } else {
                    machines.sort((a, b) => Comparators.compareStringsDesc(a.getAssetId(), b.getAssetId()));
                }
                break;
            case MachinesSorting.Controller:
                if (direction === SortingDirection.Asc) {
                    machines.sort((a, b) => Comparators.compareStringsAsc(a.getControllerSn() != null ? a.getControllerSn() : "ZZZZZ", b.getControllerSn() != null ? b.getControllerSn() : "ZZZZZ"));
                } else {
                    machines.sort((a, b) => Comparators.compareStringsDesc(a.getControllerSn() != null ? a.getControllerSn() : "ZZZZZ", b.getControllerSn() != null ? b.getControllerSn() : "ZZZZZ"));
                }
                break;
            case MachinesSorting.IMEI:
                if (direction === SortingDirection.Asc) {
                    machines.sort((a, b) => Comparators.compareStringsAsc(a.getImei(), b.getImei()));
                } else {
                    machines.sort((a, b) => Comparators.compareStringsDesc(a.getImei(), b.getImei()));
                }
                break;
        }
        return machines;
    }

    sortMachinesClick(e, sorting, direction) {
        e.preventDefault();
        this.sortMachines(sorting, direction);
    }

    sortMachines(sorting, direction) {
        const sortedMachines = this.sortedMachines(this.state.machines, sorting, direction);
        this.setState({
            machines: sortedMachines,
            machinesSorting: { sorting: sorting, direction: direction }
        });
    }

    sortedEvents(eventsToSort, sorting, direction) {
        const events = eventsToSort ? [...eventsToSort] : [];
        switch (sorting) {
            case EventsSorting.Timestamp:
                if (direction === SortingDirection.Asc) {
                    events.sort((a, b) => a.getTimestamp() - b.getTimestamp());
                } else {
                    events.sort((a, b) => b.getTimestamp() - a.getTimestamp());
                }
                break;
            case MachinesSorting.ID:
                // All are the same
                break;
            case EventsSorting.Temp:
                if (direction === SortingDirection.Asc) {
                    events.sort((a, b) => (a.getTemp() != null ? a.getTemp() : -100000) - (b.getTemp() != null ? b.getTemp() : -100000));
                } else {
                    events.sort((a, b) => (b.getTemp() != null ? b.getTemp() : -100000) - (a.getTemp() != null ? a.getTemp() : -100000));
                }
                break;
            case EventsSorting.DO:
                if (direction === SortingDirection.Asc) {
                    events.sort((a, b) => (a.getDoorOpenings() != null ? a.getDoorOpenings() : -10000) - (b.getDoorOpenings() != null ? b.getDoorOpenings() : -10000));
                } else {
                    events.sort((a, b) => (b.getDoorOpenings() != null ? b.getDoorOpenings() : -10000) - (a.getDoorOpenings() != null ? a.getDoorOpenings() : -10000));
                }
                break;
            case EventsSorting.FF:
                if (direction === SortingDirection.Asc) {
                    events.sort((a, b) => (a.getFootfall() != null ? a.getFootfall() : -10000) - (b.getFootfall() != null ? b.getFootfall() : -10000));
                } else {
                    events.sort((a, b) => (b.getFootfall() != null ? b.getFootfall() : -10000) - (a.getFootfall() != null ? a.getFootfall() : -10000));
                }
                break;
            case EventsSorting.Lat:
                if (direction === SortingDirection.Asc) {
                    events.sort((a, b) => (a.getLat() != null ? a.getLat() : -10000) - (b.getLat() != null ? b.getLat() : -10000));
                } else {
                    events.sort((a, b) => (b.getLat() != null ? b.getLat() : -10000) - (a.getLat() != null ? a.getLat() : -10000));
                }
                break;
            case EventsSorting.Long:
                if (direction === SortingDirection.Asc) {
                    events.sort((a, b) => (a.getLong() != null ? a.getLong() : -10000) - (b.getLong() != null ? b.getLong() : -10000));
                } else {
                    events.sort((a, b) => (b.getLong() != null ? b.getLong() : -10000) - (a.getLong() != null ? a.getLong() : -10000));
                }
                break;
            case EventsSorting.ModemEvent:
                if (direction === SortingDirection.Asc) {
                    events.sort((a, b) => Comparators.compareStringsAsc(a.getModemEvent(), b.getModemEvent()));
                } else {
                    events.sort((a, b) => Comparators.compareStringsDesc(a.getModemEvent(), b.getModemEvent()));
                }
                break;
            case EventsSorting.ElstatEvent:
                if (direction === SortingDirection.Asc) {
                    events.sort((a, b) => Comparators.compareStringsAsc(a.getEvent(), b.getEvent()));
                } else {
                    events.sort((a, b) => Comparators.compareStringsDesc(a.getEvent(), b.getEvent()));
                }
                break;
            case EventsSorting.Voltage:
                if (direction === SortingDirection.Asc) {
                    events.sort((a, b) => (a.getVoltage() != null ? a.getVoltage() : -10000) - (b.getVoltage() != null ? b.getVoltage() : -10000));
                } else {
                    events.sort((a, b) => (b.getVoltage() != null ? b.getVoltage() : -10000) - (a.getVoltage() != null ? a.getVoltage() : -10000));
                }
                break;
            case EventsSorting.GmsSignal:
                if (direction === SortingDirection.Asc) {
                    events.sort((a, b) => (a.getGsmSignal() != null ? a.getGsmSignal() : -10000) - (b.getGsmSignal() != null ? b.getGsmSignal() : -10000));
                } else {
                    events.sort((a, b) => (b.getGsmSignal() != null ? b.getGsmSignal() : -10000) - (a.getGsmSignal() != null ? a.getGsmSignal() : -10000));
                }
                break;
        }
        return events;
    }

    sortEventsClick(e, sorting, direction) {
        e.preventDefault();
        this.sortEvents(sorting, direction);
    }

    sortEvents(sorting, direction) {
        const sortedEvents = this.sortedEvents(this.state.events, sorting, direction);
        this.setState({
            events: sortedEvents,
            eventsSorting: { sorting: sorting, direction: direction }
        });
    }

    tabFilterSelected(e, filter, value) {
        try {
            e.preventDefault();
        } catch(error) {
            //
        }
        var tabFilters = null;
        var machines = null;
        var events = null;
        if (this.state.tabFilters && this.state.tabFilters[filter] === value) {
            tabFilters = null;
            machines = this.allMachines != null ? this.allMachines : [];
            events = null;
        } else {
            const filteredData = this.calculateFilteredData(filter, value);
            machines = filteredData.machines;
            events = filteredData.events;
            tabFilters = {
                [filter]: value
            };
        }
        this.setState({
            machines: machines,
            events: events,
            tabFilters: tabFilters
        });
    }

    render() {
        const { t } = this.props;

        if (!this.props.selectedCompany) {
            return <p>{t('error_generic')}</p>
        }

        var header = t('select_an_asset_from_the_list');
        if (this.state.tabFilters !== null && this.state.tabFilters[TabFilter.AssetId] !== null) {
            const machine = this.state.machines.find(machine => machine.getAssetId() == this.state.tabFilters[TabFilter.AssetId]);
            if (machine !== null) {
                header = machine.getCounty() + " / " + machine.getChannel() + " / " + machine.getDescription();
            }
        }

        return <div className='dashboard-admin'>
            <div className='dashboard-admin-left'>
                <table className='clickable sortable' style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                    <thead style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                        <tr>
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('id'), this.state.machinesSorting.sorting === MachinesSorting.ID ? (this.state.machinesSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortMachinesClick(e, MachinesSorting.ID, this.state.machinesSorting.sorting === MachinesSorting.ID  && this.state.machinesSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('controller'), this.state.machinesSorting.sorting === MachinesSorting.Controller ? (this.state.machinesSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortMachinesClick(e, MachinesSorting.Controller, this.state.machinesSorting.sorting === MachinesSorting.Controller  && this.state.machinesSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('imei'), this.state.machinesSorting.sorting === MachinesSorting.IMEI ? (this.state.machinesSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortMachinesClick(e, MachinesSorting.IMEI, this.state.machinesSorting.sorting === MachinesSorting.IMEI  && this.state.machinesSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.machines.map((machine) => <tr onClick={(e) => this.tabFilterSelected(e, TabFilter.AssetId, machine.getAssetId())} style={{opacity: (this.state.tabFilters && this.state.tabFilters[TabFilter.AssetId]) ? (this.state.tabFilters[TabFilter.AssetId] === machine.getAssetId() ? 1 : 0.5) : 1}}>
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), machine.getAssetId()) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), machine.getControllerSn()) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), machine.getImei()) }
                        </tr>) }
                    </tbody>
                    <tfoot style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                        <tr>
                            { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), this.state.machines.length, 3) }
                        </tr>
                    </tfoot>
                </table>
            </div>
            <div className='dashboard-admin-right'>
                <p className='dashboard-admin-events-header' style={{color: this.props.selectedCompany.getBranding().getColorText()}}>{header}</p>
                <table className={this.state.loadingEvents ? '' : 'sortable'} style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                    <thead style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                        <tr>
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('timestamp_utc'), this.state.eventsSorting.sorting === EventsSorting.Timestamp ? (this.state.eventsSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortEventsClick(e, EventsSorting.Timestamp, this.state.eventsSorting.sorting === EventsSorting.Timestamp && this.state.eventsSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('id'), this.state.eventsSorting.sorting === EventsSorting.ID ? (this.state.eventsSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortEventsClick(e, EventsSorting.ID, this.state.eventsSorting.sorting === EventsSorting.ID && this.state.eventsSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('temperature_short'), this.state.eventsSorting.sorting === EventsSorting.Temp ? (this.state.eventsSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortEventsClick(e, EventsSorting.Temp, this.state.eventsSorting.sorting === EventsSorting.Temp && this.state.eventsSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('door_openings_short'), this.state.eventsSorting.sorting === EventsSorting.DO ? (this.state.eventsSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortEventsClick(e, EventsSorting.DO, this.state.eventsSorting.sorting === EventsSorting.DO && this.state.eventsSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('footfall_short'), this.state.eventsSorting.sorting === EventsSorting.FF ? (this.state.eventsSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortEventsClick(e, EventsSorting.FF, this.state.eventsSorting.sorting === EventsSorting.FF && this.state.eventsSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('latitude_short'), this.state.eventsSorting.sorting === EventsSorting.Lat ? (this.state.eventsSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortEventsClick(e, EventsSorting.Lat, this.state.eventsSorting.sorting === EventsSorting.Lat && this.state.eventsSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('longitude_short'), this.state.eventsSorting.sorting === EventsSorting.Long ? (this.state.eventsSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortEventsClick(e, EventsSorting.Long, this.state.eventsSorting.sorting === EventsSorting.Long && this.state.eventsSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('modem_event'), this.state.eventsSorting.sorting === EventsSorting.ModemEvent ? (this.state.eventsSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortEventsClick(e, EventsSorting.ModemEvent, this.state.eventsSorting.sorting === EventsSorting.ModemEvent && this.state.eventsSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('event'), this.state.eventsSorting.sorting === EventsSorting.ElstatEvent ? (this.state.eventsSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortEventsClick(e, EventsSorting.ElstatEvent, this.state.eventsSorting.sorting === EventsSorting.ElstatEvent && this.state.eventsSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('voltage'), this.state.eventsSorting.sorting === EventsSorting.Voltage ? (this.state.eventsSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortEventsClick(e, EventsSorting.Voltage, this.state.eventsSorting.sorting === EventsSorting.Voltage && this.state.eventsSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('gsm_signal'), this.state.eventsSorting.sorting === EventsSorting.GmsSignal ? (this.state.eventsSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortEventsClick(e, EventsSorting.GmsSignal, this.state.eventsSorting.sorting === EventsSorting.GmsSignal && this.state.eventsSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.loadingEvents && <tr> { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), t('loading_ellipsis'), null, 11) } </tr> }
                        { !this.state.loadingEvents && this.state.events != null && this.state.events.length > 0 && this.state.events.map((event) => <tr>
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), DateUtils.formatYYYYMMDDHHMMSS(DateUtils.epochToDate(event.getTimestamp()))) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), this.state.tabFilters[TabFilter.AssetId]) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), event.getTemp()) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), event.getDoorOpenings()) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), event.getFootfall()) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), event.getLat()) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), event.getLong()) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), event.getModemEvent()) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), event.getEvent()) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), event.getVoltage()) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), event.getGsmSignal()) }
                        </tr>) }
                        { !this.state.loadingEvents && this.state.events != null && this.state.events.length === 0 && <tr> { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), t('no_events_message'), null, 11) } </tr> }
                        { !this.state.loadingEvents && this.state.events == null && <tr> { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), (this.state.tabFilters == null || this.state.tabFilters[TabFilter.AssetId] == null) ? t('no_asset_selected') : t('no_events_message'), null, 11) } </tr> }
                    </tbody>
                    <tfoot style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                        <tr>
                            { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), this.state.events != null ? this.state.events.length : "0", 11) }
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    }
}

export default withHelpers(withTranslation()(DashboardAdmin))