import React, { useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import Footer from './../footer/Footer';
import { withHelpers } from '../../utils/Injection';
import BaseComponent from '../BaseComponent';
import TextUtils from '../../utils/TextUtils';
import logo from './../../res/logos/logo.svg';
import backgroundImage from './../../res/images/bg_landing.png';

class Landing extends BaseComponent {

    constructor(props) {
        super(props);
        this.onStartClick = this.onStartClick.bind(this);
    }

    onStartClick(e) {
        e.preventDefault();
        this.props.navigate("/login", { replace: true});
    }

    render() {
        const { t } = this.props;
        const motto = t('landing_motto');
        const mottoParts = motto.split(t('landing_motto_colored'));
        
        return  <div className='landing-container'>
            <img src={backgroundImage} className='landing-bg' alt='' />
            <img src={logo} className="landing-logo" alt={t('alt_green_systems_logo')} />
            <h1>{TextUtils.composeTextWithLineBreaks(mottoParts[0])}<span>{t('landing_motto_colored')}</span>{TextUtils.composeTextWithLineBreaks(mottoParts[1])}</h1>
            <div className='vertical-separator'/>
            <button onClick={this.onStartClick}>{t('action_start')}</button>
            <Footer/>
        </div>
    }
}

export default withHelpers(withTranslation()(Landing))
