import axios from 'axios';
import IotCompany from '../model/IotCompany';
import IotMachine from '../model/IotMachine';
import IotEvent from '../model/IotEvent';
import User from '../model/User';
import { jwtDecode } from "jwt-decode";

export default class ApiRepository {

    static #instance = axios.create({
        // baseURL: 'http://127.0.0.1:5000',
        baseURL: 'https://api.greensystems.com.es',
        timeout: 30000,
        headers: {
            'Accept': "*/*",
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
        }
    });

    static t;
    static userId;

    /*

        this.#instance.interceptors.request.use(request => {
            console.log('Starting Request', JSON.stringify(request, null, 2))
            return request
        })
        
        this.#instance.interceptors.response.use(response => {
            console.log('Response:', JSON.stringify(response, null, 2))
            return response
        })

        */

    static setToken(token) {
        if (token) {
            this.#instance.defaults.headers.common["x-access-token"] = token;
            ApiRepository.userId = jwtDecode(token)["public_id"];
            // localStorage.setItem('token',token);
        } else {
            ApiRepository.userId = null;
            delete this.#instance.defaults.headers.common["x-access-token"];
            // localStorage.removeItem('token')
        }
    }

    static login(email, password, successListener, errorListener) {
        this.#instance.post('/login', {
            email: email,
            password: password
        })
        .then(function (response) {
            successListener(response.data);
        })
        .catch(function (error) {
            try {
                if (error.response.status === 401 && error.response.data.message === 'User not active.') {
                    errorListener(false, ApiRepository.t('error_account_not_active'));
                } else if (error.response.status < 500) {
                    errorListener(false, ApiRepository.t('error_login_user_password'));
                } else {
                    errorListener(false, ApiRepository.t('error_generic'));
                }
            } catch {
                errorListener(false, ApiRepository.t('error_generic'));
            }
        });
    }

    static resetPassword(email, successListener, errorListener) {
        this.#instance.post('/reset-password', {
            email: email
        })
        .then(function (response) {
            successListener(response.data);
        })
        .catch(function (error) {
            try {
                if (error.response.status < 500) {
                    errorListener(false, ApiRepository.t('error_reset_password'));
                } else {
                    errorListener(false, ApiRepository.t('error_generic'));
                }
            } catch {
                errorListener(false, ApiRepository.t('error_generic'));
            }
        });
    }

    static changePassword(oldPassword, newPassword, successListener, errorListener) {
        this.#instance.post('/modify-password', {
            old_password: oldPassword,
            new_password: newPassword
        })
        .then(function (response) {
            successListener(response.data);
        })
        .catch(function (error) {
            try {
                if (error.response.status === 400 && error.response.data.message === 'Invalid password.') {
                    errorListener(false, ApiRepository.t('error_invalid_password') + '\n' + ApiRepository.t('password_requirements_description'));
                } else if (error.response.status === 403 && error.response.data.message === 'Invalid password.') {
                    errorListener(false, ApiRepository.t('error_invalid_password'));
                } else {
                    errorListener(false, ApiRepository.t('error_generic'));
                }
            } catch {
                errorListener(false, ApiRepository.t('error_generic'));
            }
        });
    }

    static signUp(name, email, password, successListener, errorListener) {
        this.#instance.post('/signup', {
            name: name,
            email: email,
            password: password
        })
        .then(function (response) {
            successListener(response.data);
        })
        .catch(function (error) {
            try {
                if (error.response.status === 400 && error.response.data.message === 'Invalid name.') {
                    errorListener(false, ApiRepository.t('error_invalid_full_name'));
                } else if (error.response.status === 400 && error.response.data.message === 'Invalid email.') {
                    errorListener(false, ApiRepository.t('error_invalid_email'));
                } else if (error.response.status === 400 && error.response.data.message.startsWith('Invalid password:')) {
                    errorListener(false, ApiRepository.t('error_invalid_password') + '\n' + ApiRepository.t('password_requirements_description'));
                } else if (error.response.status === 400 && error.response.data.message.startsWith('User already exists.')) {
                    errorListener(false, ApiRepository.t('error_user_exists'));
                } else if (error.response.status < 500) {
                    errorListener(false, ApiRepository.t('error_sign_up'));
                } else {
                    errorListener(false, ApiRepository.t('error_generic'));
                }
            } catch {
                errorListener(false, ApiRepository.t('error_generic'));
            }
        });
    }

    static getCompanies(successListener, errorListener) {
        this.#instance.get('/iot/companies')
        .then(function (response) {
            var companies = [];
            if (response.data) {
                response.data.forEach(item => {
                    companies.push(IotCompany.fromServerResponse(item));
                });
            }
            successListener(companies);
        })
        .catch(function (error) {
            try {
                if (error.response.status === 401) {
                    errorListener(true, ApiRepository.t('error_session_expired'));
                } else {
                    errorListener(false, ApiRepository.t('error_generic'));
                }
            } catch {
                errorListener(false, ApiRepository.t('error_generic'));
            }
        })
    }

    static getCompanyMachines(companyId, successListener, errorListener) {
        this.#instance.get('/iot/' + companyId + '/machines', { params: { 'temp_last_week': true } })
        .then(function (response) {
            var machines = [];
            if (response.data) {
                response.data.forEach(item => {
                    machines.push(IotMachine.fromServerResponse(item));
                });
            }
            successListener(machines);
        })
        .catch(function (error) {
            try {
                if (error.response.status === 401) {
                    errorListener(true, ApiRepository.t('error_session_expired'));
                } else {
                    errorListener(false, ApiRepository.t('error_generic'));
                }
            } catch {
                errorListener(false, ApiRepository.t('error_generic'));
            }
        })
    }

    static getCompanyAggregatedEventsAll(companyId, successListener, errorListener) {
        ApiRepository.getCompanyAggregatedEvents(companyId, null, null, successListener, errorListener);
    }

    static getCompanyAggregatedEvents(companyId, fromDate, toDate, successListener, errorListener) {
        var params = {};
        if (fromDate !== null && toDate !== null) {
            params = {
                'from_date': fromDate,
                'to_date': toDate
            }
        }
        this.#instance.get('/iot/' + companyId + '/aggregated_events', { params: params })
        .then(function (response) {
            successListener(response.data);
        })
        .catch(function (error) {
            try {
                if (error.response.status === 401) {
                    errorListener(true, ApiRepository.t('error_session_expired'));
                } else {
                    errorListener(false, ApiRepository.t('error_generic'));
                }
            } catch {
                errorListener(false, ApiRepository.t('error_generic'));
            }
        });
    }

    static getMachineEvents(companyId, assetId, fromDate, toDate, successListener, errorListener) {
        var params = {};
        if (fromDate !== null && toDate !== null) {
            params = {
                'from_date': fromDate,
                'to_date': toDate
            }
        }
        this.#instance.get('/iot/' + companyId + '/events/' + assetId, { params: params })
        .then(function (response) {
            var events = [];
            if (response.data) {
                response.data.forEach(item => {
                    events.push(IotEvent.fromServerResponse(item));
                });
            }
            successListener(events);
        })
        .catch(function (error) {
            try {
                if (error.response.status === 401) {
                    errorListener(true, ApiRepository.t('error_session_expired'));
                } else {
                    errorListener(false, ApiRepository.t('error_generic'));
                }
            } catch {
                errorListener(false, ApiRepository.t('error_generic'));
            }
        });
    }

    static getUser(userId, successListener, errorListener) {
        this.#instance.get('/users/' + userId)
        .then(function (response) {
            successListener(User.fromServerResponse(response.data));
        })
        .catch(function (error) {
            try {
                if (error.response.status === 401) {
                    errorListener(true, ApiRepository.t('error_session_expired'));
                } else {
                    errorListener(false, ApiRepository.t('error_generic'));
                }
            } catch {
                errorListener(false, ApiRepository.t('error_generic'));
            }
        });
    }
}