import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { ProtectedRoute } from "../components/ProtectedRoute";
import { useAuth } from "../context/AuthContext";
import Login from "../components/login/Login";
import ResetPassword from "../components/login/ResetPassword";
import SignUp from "../components/login/SignUp";
import Dashboard from "../components/dashboard/Dashboard";
import Account from "../components/account/Account";
import ChangePassword from "../components/changePassword/ChangePassword";
import Landing from "../components/landing/Landing";

const Routes = () => {
    const { token } = useAuth();

    // Define public routes accessible to all users
    const routesForPublic = [
        {
            path: "/service",
            element: <div>Service Page</div>,
        },
        {
            path: "/about-us",
            element: <div>About Us</div>,
        },
    ];

    // Define routes accessible only to authenticated users
    const routesForAuthenticatedOnly = [
        {
            path: "/",
            element: <ProtectedRoute />, // Wrap the component in ProtectedRoute
            children: [
                {
                    path: "/",
                    element: <Dashboard></Dashboard>,
                },
                {
                    path: "/account",
                    element: <Account></Account>,
                },
                {
                    path: "/change-password",
                    element: <ChangePassword></ChangePassword>,
                },
                {
                    path: "*",
                    element: <Navigate to="/" replace />
                }
            ],
        },
    ];

    // Define routes accessible only to non-authenticated users
    const routesForNotAuthenticatedOnly = [
        {
            path: "/",
            element: <Landing />,
        },
        {
            path: "/login",
            element: <Login />,
        },
        {
            path: "/reset-password",
            element: <ResetPassword />,
        },
        {
            path: "/sign-up",
            element: <SignUp />,
        },
        {
            path: "*",
            element: <Navigate to="/" replace />
        }
    ];

    // Combine and conditionally include routes based on authentication status
    const router = createBrowserRouter([
        ...routesForPublic,
        ...(!token ? routesForNotAuthenticatedOnly : []),
        ...routesForAuthenticatedOnly,
    ]);

    // Provide the router configuration using RouterProvider
    return <RouterProvider router={router} />;
};

export default Routes;