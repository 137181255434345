export default class Comparators {

    static compareStringsAsc = (a, b) => {
        if (a == null && b != null) {
            return -1;
        } else if (a != null && b == null) {
            return 1;
        } else if (a == null && b == null) {
            return 0;
        }
        return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
    };

    static compareStringsDesc = (a, b) => {
        if (a == null && b != null) {
            return 1;
        } else if (a != null && b == null) {
            return -1;
        } else if (a == null && b == null) {
            return 0;
        }
        return a.toLowerCase() < b.toLowerCase() ? 1 : -1;
    }
}