import React from 'react'
import { withTranslation } from 'react-i18next'
import Footer from './../footer/Footer';
import logo from './../../res/logos/logo.svg';
import { withHelpers } from '../../utils/Injection';
import Validation from '../../utils/Validation';
import ApiRepository from '../../data/ApiRepository';
import BaseComponent from '../BaseComponent';

class ResetPassword extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            email: null,
            success: null,
            error: null
        };
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.navigateToToLogin = this.navigateToToLogin.bind(this);
    }

    handleEmailChange(e) {
        this.setState({ email: e.target.value });
    }

    resetPassword(e) {
        e. preventDefault();

        const { t } = this.props;

        var errorText;
        if (Validation.isEmptyString(this.state.email)) {
            errorText = t('error_empty_email');
        } else if (!Validation.validateEmail(this.state.email)) {
            errorText = t('error_invalid_email');
        }
        if (errorText) {
            this.setState({ error: errorText });
            return false;
        }
        this.setState({ error: null });
        ApiRepository.resetPassword(
            this.state.email,
            (response) => {
                this.setState({ success: t('reset_password_success_message'), error: null })
            },
            (sessionExpired, errorMessage) => {
                this.setState({ success: null, error: errorMessage });
            }
        );
        return false;
    }

    navigateToToLogin(e) {
        e. preventDefault();
        this.props.navigate("/login");
    }

    render() {
        const { t } = this.props;

        return <div className='login-container'>
            <div>
                <img src={logo} className="login-logo" alt={t('alt_green_systems_logo')} />
                <h1>{t('iot_platform')}</h1>
                <form className='login-form' onSubmit={this.resetPassword} noValidate>
                    <div className="form-container">
                        <label htmlFor="username">{t('email')}</label>
                        <input type="email" placeholder={t('email_input_placeholder')} name="username" onChange={this.handleEmailChange}/>

                        { !Validation.isEmptyString(this.state.error) && <p className='error'>{this.state.error}</p> }
                        { !Validation.isEmptyString(this.state.success) && <p className='success'>{this.state.success}</p> }

                        { Validation.isEmptyString(this.state.success) && <button type="submit">{t('action_reset_password')}</button> }
                        
                        <a onClick={this.navigateToToLogin}>{t('link_login')}</a>
                    </div>
                </form>
            </div>
            <Footer/>
        </div>
    }
}

export default withHelpers(withTranslation()(ResetPassword))