import {
    useLocation,
    useNavigate,
    useParams
} from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import React from "react";

export function withHelpers(Component) {
    return (props) => (
        <Component {...props} location={useLocation()} navigate={useNavigate()} params={useParams()} auth={useAuth()} />
    );
}