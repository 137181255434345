export default class TextUtils {
 
    static composeTextWithLineBreaks = (text) => {
        if (text == null) {
            return [];
        }
        const parts = text.split("\n");
        const result = [];
        for (let i = 0; i < parts.length; i++) {
            result.push(parts[i]);
            if (i + 1 != parts.length) {
                result.push(<br/>);
            }
        }
        return result;
    }
}