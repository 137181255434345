import React from 'react';
import { Helmet } from "react-helmet";
import './i18n';
import { withTranslation } from 'react-i18next';
import Main from './Main'
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import ApiRepository from './data/ApiRepository';
import AuthProvider from './context/AuthContext';
import Routes from './utils/Routes';

class App extends React.Component {

  app;
  analytics;

  constructor(props) {
    super(props);

    this.state = {
      //
    }

    if (!App.app) {
      // Initialize Firebase+Analytics
      App.app = initializeApp({
        apiKey: "AIzaSyAHykzyetBFW6x_CeW30hzX_7yMduDulKg",
        authDomain: "greensystems-iot-dashboard.firebaseapp.com",
        projectId: "greensystems-iot-dashboard",
        storageBucket: "greensystems-iot-dashboard.appspot.com",
        messagingSenderId: "977275724672",
        appId: "1:977275724672:web:981bf2525c25d45d739765",
        measurementId: "G-GHH6YLCTZH"
      });
      App.analytics = getAnalytics(App.app);
    }
  }

  render() {
    const { t } = this.props;

    ApiRepository.t = t;

    return <AuthProvider>
      <Helmet>
        <title>{t('helmet_title')}</title>
      </Helmet>
      <Routes />
    </AuthProvider>
  }
}

export default withTranslation()(App)