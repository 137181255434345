import React from 'react'
import { withTranslation } from 'react-i18next'
import { withHelpers } from '../../utils/Injection';
import DashboardUIHelper from '../../utils/DashboardUIHelper';
import i18n from '../../i18n';
import Chart from 'chart.js/auto';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement
  } from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import { MapContainer, Marker, TileLayer, Popup, useMap } from 'react-leaflet';
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import DateUtils from '../../utils/DateUtils';
import { GoTriangleUp } from "react-icons/go";
import { GoTriangleDown } from "react-icons/go";
import Comparators from '../../utils/Comparators';
import BaseComponent from '../BaseComponent';

const TabFilter = {
    AssetId: "asset_id",
    SyncStatus: "sync_status",
    Warehouse: "warehouse",
    MapAssetId: "map_asset_id",
    POS: "pos"
}

const WarehouseFilterValue = {
    ShowAll: 0,
    ShowNone: 1,
    ShowYes: 2,
    ShowNo: 3
}

const MachinesSorting = {
    SyncStatus: 0,
    ID: 1,
    Model: 2,
    Brand: 3,
    RegistrationDate: 4,
    DaysWorking: 5,
    LastContact: 6
}

const PosSorting = {
    POS: 0,
    MachinesCount: 1,
    Client: 2,
    Channel: 3,
    Description: 4,
    Address: 5,
    Postcode: 6,
    City: 7,
    County: 8
}

const SortingDirection = {
    Asc: 0,
    Desc: 1
}

class DashboardAssetManagement extends BaseComponent {

    constructor(props) {
        super(props);
        if (props.reference) {
            props.reference.current = this;
        }
        this.allMachines = props.machines != null ? props.machines : [];
        this.allAggregatedEvents = props.aggregatedEvents != null ? props.aggregatedEvents : [];
        const filteredData = this.calculateFilteredData(null, null);
        this.state = {
            machines: this.sortedMachines(filteredData.machines, MachinesSorting.ID, SortingDirection.Asc),
            machinesSyncStatus: filteredData.machinesSyncStatus,
            machinesWarehouse: filteredData.machinesWarehouse,
            machinesMapData: filteredData.mapData.machinesMapData,
            machinesMapDataLimits: filteredData.mapData.machinesMapDataLimits,
            pos: this.sortedPos(filteredData.pos, PosSorting.POS, SortingDirection.Asc),
            aggregatedEvents: filteredData.aggregatedEvents,
            tabFilters: null,
            machinesSorting: { sorting: MachinesSorting.ID, direction: SortingDirection.Asc },
            posSorting: { sorting: PosSorting.POS, direction: SortingDirection.Asc }
        };
        this.mapRef = React.createRef();
        this.checkboxWarehouseYesRef = React.createRef();
        this.checkboxWarehouseNoRef = React.createRef();
        ChartJS.register(
            CategoryScale,
            LinearScale,
            BarElement,
            Title,
            Tooltip,
            Legend
        );
        this.refresh = this.refresh.bind(this);
    }
    
    componentWillUnmount() {
        this.mapRef = null;
        this.checkboxWarehouseYesRef = null;
        this.checkboxWarehouseNoRef = null;
    }

    refresh(updatedMachines, aggregatedEvents) {
        this.allMachines = updatedMachines != null ? updatedMachines : [];
        this.allAggregatedEvents = aggregatedEvents != null ? aggregatedEvents : [];
        var filter = null;
        var value = null;
        if (this.state.tabFilters && Object.keys(this.state.tabFilters).length !== 0) {
            filter = Object.keys(this.state.tabFilters)[0];
            value = this.state.tabFilters[filter];
        }
        const filteredData = this.calculateFilteredData(filter, value);
        this.setState({
            machines: this.sortedMachines(filteredData.machines, this.state.machinesSorting.sorting, this.state.machinesSorting.direction),
            aggregatedEvents: filteredData.aggregatedEvents,
            machinesSyncStatus: filteredData.machinesSyncStatus,
            machinesWarehouse: filteredData.machinesWarehouse,
            machinesMapData: filteredData.mapData.machinesMapData,
            machinesMapDataLimits: filteredData.mapData.machinesMapDataLimits,
            pos: this.sortedPos(filteredData.pos, this.state.posSorting.sorting, this.state.posSorting.direction)
        });
        try {
            if (filteredData.mapData.machinesMapDataLimits) {
                this.mapRef.current.fitBounds([[filteredData.mapData.machinesMapDataLimits.north, filteredData.mapData.machinesMapDataLimits.east], [filteredData.mapData.machinesMapDataLimits.south, filteredData.mapData.machinesMapDataLimits.west]]);
            }
        } catch (error) {
            //console.log(error)
        }
    }

    calculateFilteredMachines(machines, filters) {
        var filteredMachines = [];
        if (machines) {
            filteredMachines = machines;
            if (filters) {
                if (filters.assetId != null) {
                    filteredMachines = filteredMachines.filter((machine) => machine.getAssetId() === filters.assetId);
                }
                if (filters.syncStatus != null) {
                    filteredMachines = filteredMachines.filter((machine) => machine.getSyncStatus() === filters.syncStatus);
                }
                if (filters.warehouse != null) {
                    filteredMachines = filteredMachines.filter((machine) => machine.isWarehouse() === filters.warehouse);
                }
                if (filters.pos != null) {
                    filteredMachines = filteredMachines.filter((machine) => machine.getPos() === filters.pos);
                }
            }
        }
        return filteredMachines;
    }

    calculateMachinesSyncStatus(machines) {
        var machinesSyncStatus = [];
        if (machines) {
            machinesSyncStatus.push(machines.filter((machine) => machine.getSyncStatus() === 3).length);
            machinesSyncStatus.push(machines.filter((machine) => machine.getSyncStatus() === 2).length);
            machinesSyncStatus.push(machines.filter((machine) => machine.getSyncStatus() === 1).length);
            machinesSyncStatus.push(machines.length - machinesSyncStatus[0] - machinesSyncStatus[1] - machinesSyncStatus[2]);
        }
        return machinesSyncStatus;
    }

    calculateMachinesWarehouse(machines) {
        var machinesWarehouse = [];
        if (machines) {
            machinesWarehouse.push(machines.filter((machine) => machine.isWarehouse() !== false).length);
            machinesWarehouse.push(machines.length - machinesWarehouse[0]);
        }
        return machinesWarehouse;
    }

    calculateListOfPos(machines) {
        var posDict = {};
        if (machines) {
            machines.forEach((machine) => {
                if (machine.getPos() in posDict) {
                    posDict[machine.getPos()].machinesCount = posDict[machine.getPos()].machinesCount + 1;
                } else {
                    posDict[machine.getPos()] = {
                        id: machine.getPos(),
                        machinesCount: 1,
                        client: machine.getClient(),
                        channel: machine.getChannel(),
                        description: machine.getDescription(),
                        address: machine.getAddress(),
                        postcode: machine.getPostcode(),
                        city: machine.getCity(),
                        county: machine.getCounty()
                    };
                }
            });
        }
        return Object.values(posDict);
    }

    calculateMapDataAndLimits(machines) {
        const machinesMapData = [];
        var machinesMapDataLimits = {
            north: null,
            east: null,
            south: null,
            west: null
        };
        machines.forEach(machine => {
            if (machine.getSyncStatus() > 0) {
                if (machine.getLastLat() != null && machine.getLastLong() != null) {
                    machinesMapData.push({
                        assetId: machine.getAssetId(),
                        syncStatus: machine.getSyncStatus(),
                        lat: machine.getLastLat(),
                        long: machine.getLastLong(),
                        timestamp: Date.parse(machine.getLastPositionDate())
                    });
                    if (machinesMapDataLimits.north == null || machinesMapDataLimits.north < machine.getLastLat()) {
                        machinesMapDataLimits.north = machine.getLastLat()
                    }
                    if (machinesMapDataLimits.south == null || machinesMapDataLimits.south > machine.getLastLat()) {
                        machinesMapDataLimits.south = machine.getLastLat()
                    }
                    if (machinesMapDataLimits.east == null || machinesMapDataLimits.east < machine.getLastLong()) {
                        machinesMapDataLimits.east = machine.getLastLong()
                    }
                    if (machinesMapDataLimits.west == null || machinesMapDataLimits.west > machine.getLastLong()) {
                        machinesMapDataLimits.west = machine.getLastLong()
                    }
                }
            }
        });
        if (machinesMapDataLimits.north == null || machinesMapDataLimits.south == null || machinesMapDataLimits.east == null || machinesMapDataLimits.west == null) {
            machinesMapDataLimits = null;
        }
        return {
            machinesMapData: machinesMapData,
            machinesMapDataLimits: machinesMapDataLimits
        }
    }

    tabFilterSelected(e, filter, value) {
        try {
            e.preventDefault();
        } catch(error) {
            //
        }
        var tabFilters = null;
        var machines = null;
        var aggregatedEvents = null;
        var mapData = null;
        var machinesSyncStatus = null;
        var machinesWarehouse = null;
        var pos = null;
        if (this.state.tabFilters && this.state.tabFilters[filter] === value) {
            tabFilters = null;
            machines = this.allMachines != null ? this.allMachines : [];
            aggregatedEvents = this.allAggregatedEvents != null ? this.allAggregatedEvents : [];
            mapData = this.calculateMapDataAndLimits(this.allMachines);
            machinesSyncStatus = this.calculateMachinesSyncStatus(machines);
            machinesWarehouse = this.calculateMachinesWarehouse(machines);
            pos = this.calculateListOfPos(machines);
        } else {
            const filteredData = this.calculateFilteredData(filter, value);
            machines = filteredData.machines;
            aggregatedEvents = filteredData.aggregatedEvents;
            mapData = filteredData.mapData;
            machinesSyncStatus = filteredData.machinesSyncStatus;
            machinesWarehouse = filteredData.machinesWarehouse;
            pos = filteredData.pos;
            tabFilters = {
                [filter]: value
            };
        }
        var machinesMapData = mapData.machinesMapData;
        var machinesMapDataLimits = mapData.machinesMapDataLimits;
        this.setState({
            tabFilters: tabFilters,
            machines: this.sortedMachines(machines, this.state.machinesSorting.sorting, this.state.machinesSorting.direction),
            aggregatedEvents: aggregatedEvents,
            machinesSyncStatus: machinesSyncStatus,
            machinesWarehouse: machinesWarehouse,
            machinesMapData: machinesMapData,
            machinesMapDataLimits: machinesMapDataLimits,
            pos: this.sortedPos(pos, this.state.posSorting.sorting, this.state.posSorting.direction)
        });
        try {
            if (machinesMapDataLimits) {
                this.mapRef.current.fitBounds([[machinesMapDataLimits.north, machinesMapDataLimits.east], [machinesMapDataLimits.south, machinesMapDataLimits.west]]);
            }
        } catch (error) {
            console.log(error)
        }
    }

    calculateFilteredData(filter, value) {
        var machines = null;
        var aggregatedEvents = null;
        var mapData = null;
        var machinesSyncStatus = null;
        var machinesWarehouse = null;
        var pos = null;
        switch (filter) { 
            case TabFilter.AssetId:
                machines = this.allMachines != null ? this.allMachines : [];
                var filteredMachines = [machines.find((machine) => machine.getAssetId() === value)];
                aggregatedEvents = this.allAggregatedEvents != null ? this.allAggregatedEvents : [];
                mapData = this.calculateMapDataAndLimits(filteredMachines);
                machinesSyncStatus = this.calculateMachinesSyncStatus(filteredMachines);
                machinesWarehouse = this.calculateMachinesWarehouse(filteredMachines);
                pos = this.calculateListOfPos(filteredMachines);
                break;
            case TabFilter.MapAssetId:
                machines = this.allMachines != null ? [this.allMachines.find((machine) => machine.getAssetId() === value)] : [];
                aggregatedEvents = this.allAggregatedEvents != null ? this.allAggregatedEvents : [];
                mapData = this.calculateMapDataAndLimits(this.allMachines);
                machinesSyncStatus = this.calculateMachinesSyncStatus(machines);
                machinesWarehouse = this.calculateMachinesWarehouse(machines);
                pos = this.calculateListOfPos(machines);
                break;
            case TabFilter.Warehouse:
                switch (value) {
                    case WarehouseFilterValue.ShowAll:
                        machines = this.allMachines != null ? this.allMachines : [];
                        break;
                    case WarehouseFilterValue.ShowNone:
                        machines = [];
                        break;
                    case WarehouseFilterValue.ShowYes:
                        machines = this.allMachines != null ? this.allMachines.filter((machine) => machine.isWarehouse() === true) : [];
                        break;
                    case WarehouseFilterValue.ShowNo:
                        machines = this.allMachines != null ? this.allMachines.filter((machine) => machine.isWarehouse() === false) : [];
                        break;
                }
                aggregatedEvents = this.allAggregatedEvents != null ? this.allAggregatedEvents : [];
                mapData = this.calculateMapDataAndLimits(machines);
                machinesSyncStatus = this.calculateMachinesSyncStatus(machines);
                machinesWarehouse = this.calculateMachinesWarehouse(machines);
                pos = this.calculateListOfPos(machines);
                break;
            case TabFilter.POS:
                machines = this.allMachines != null ? this.allMachines.filter((machine) => machine.getPos() === value) : [];
                aggregatedEvents = this.allAggregatedEvents != null ? this.allAggregatedEvents : [];
                mapData = this.calculateMapDataAndLimits(machines);
                machinesSyncStatus = this.calculateMachinesSyncStatus(machines);
                machinesWarehouse = this.calculateMachinesWarehouse(machines);
                pos = this.calculateListOfPos(this.allMachines);
                break;
            case TabFilter.SyncStatus:
                machines = this.allMachines != null ? this.allMachines.filter((machine) => machine.getSyncStatus() === value) : [];
                aggregatedEvents = this.allAggregatedEvents != null ? this.allAggregatedEvents : [];
                mapData = this.calculateMapDataAndLimits(machines);
                machinesSyncStatus = this.calculateMachinesSyncStatus(this.allMachines);
                machinesWarehouse = this.calculateMachinesWarehouse(machines);
                pos = this.calculateListOfPos(this.allMachines);
                break;
            default:
                machines = this.allMachines != null ? this.allMachines : [];
                aggregatedEvents = this.allAggregatedEvents != null ? this.allAggregatedEvents : [];
                mapData = this.calculateMapDataAndLimits(machines);
                machinesSyncStatus = this.calculateMachinesSyncStatus(machines);
                machinesWarehouse = this.calculateMachinesWarehouse(machines);
                pos = this.calculateListOfPos(machines);
                break;
        }
        return {
            machines: machines,
            aggregatedEvents: aggregatedEvents,
            mapData: mapData,
            machinesSyncStatus: machinesSyncStatus,
            machinesWarehouse: machinesWarehouse,
            pos: pos
        };
    }

    calculateWarehouseFilterValue(yesChecked, noChecked) {
        if (yesChecked == noChecked) {
            return yesChecked ? WarehouseFilterValue.ShowAll : WarehouseFilterValue.ShowNone;
        } else if (yesChecked) {
            return WarehouseFilterValue.ShowYes;
        } else {
            return WarehouseFilterValue.ShowNo;
        }
    }

    sortedMachines(machinesToSort, sorting, direction) {
        const machines = machinesToSort ? [...machinesToSort] : [];
        switch (sorting) {
            case MachinesSorting.ID:
                if (direction === SortingDirection.Asc) {
                    machines.sort((a, b) => Comparators.compareStringsAsc(a.getAssetId(), b.getAssetId()));
                } else {
                    machines.sort((a, b) => Comparators.compareStringsDesc(a.getAssetId(), b.getAssetId()));
                }
                break;
            case MachinesSorting.Model:
                if (direction === SortingDirection.Asc) {
                    machines.sort((a, b) => Comparators.compareStringsAsc(a.getCoolerModel(), b.getCoolerModel()));
                } else {
                    machines.sort((a, b) => Comparators.compareStringsDesc(a.getCoolerModel(), b.getCoolerModel()));
                }
                break;
            case MachinesSorting.Brand:
                if (direction === SortingDirection.Asc) {
                    machines.sort((a, b) => Comparators.compareStringsAsc(a.getCoolerBrand(), b.getCoolerBrand()));
                } else {
                    machines.sort((a, b) => Comparators.compareStringsDesc(a.getCoolerBrand(), b.getCoolerBrand()));
                }
                break;
            case MachinesSorting.RegistrationDate:
                if (direction === SortingDirection.Asc) {
                    machines.sort((a, b) => Comparators.compareStringsAsc(a.getManufacturingDate(), b.getManufacturingDate()));
                } else {
                    machines.sort((a, b) => Comparators.compareStringsDesc(a.getManufacturingDate(), b.getManufacturingDate()));
                }
                break;
            case MachinesSorting.DaysWorking:
                if (direction === SortingDirection.Asc) {
                    machines.sort((a, b) => a.getDaysActive() - b.getDaysActive());
                } else {
                    machines.sort((a, b) => b.getDaysActive() - a.getDaysActive());
                }
                break;
            case MachinesSorting.LastContact:
                if (direction === SortingDirection.Asc) {
                    machines.sort((a, b) => Comparators.compareStringsAsc(a.getLastConnection(), b.getLastConnection()));
                } else {
                    machines.sort((a, b) => Comparators.compareStringsDesc(a.getLastConnection(), b.getLastConnection()));
                }
                break;
        }
        return machines;
    }

    sortMachinesClick(e, sorting, direction) {
        e.preventDefault();
        this.sortMachines(sorting, direction);
    }

    sortMachines(sorting, direction) {
        const sortedMachines = this.sortedMachines(this.state.machines, sorting, direction);
        this.setState({
            machines: sortedMachines,
            machinesSorting: { sorting: sorting, direction: direction }
        });
    }

    sortedPos(posToSort, sorting, direction) {
        const pos = posToSort ? [...posToSort] : [];
        switch (sorting) {
            case PosSorting.POS:
                if (direction === SortingDirection.Asc) {
                    pos.sort((a, b) => Comparators.compareStringsAsc(a.id, b.id));
                } else {
                    pos.sort((a, b) => Comparators.compareStringsDesc(a.id, b.id));
                }
                break;
            case PosSorting.MachinesCount:
                if (direction === SortingDirection.Asc) {
                    pos.sort((a, b) => a.machinesCount - b.machinesCount);
                } else {
                    pos.sort((a, b) => b.machinesCount - a.machinesCount);
                }
                break;
            case PosSorting.Client:
                if (direction === SortingDirection.Asc) {
                    pos.sort((a, b) => Comparators.compareStringsAsc(a.client, b.client));
                } else {
                    pos.sort((a, b) => Comparators.compareStringsDesc(a.client, b.client));
                }
                break;
            case PosSorting.Channel:
                if (direction === SortingDirection.Asc) {
                    pos.sort((a, b) => Comparators.compareStringsAsc(a.channel, b.channel));
                } else {
                    pos.sort((a, b) => Comparators.compareStringsDesc(a.channel, b.channel));
                }
                break;
            case PosSorting.Description:
                if (direction === SortingDirection.Asc) {
                    pos.sort((a, b) => Comparators.compareStringsAsc(a.description, b.description));
                } else {
                    pos.sort((a, b) => Comparators.compareStringsDesc(a.description, b.description));
                }
                break;
            case PosSorting.Address:
                if (direction === SortingDirection.Asc) {
                    pos.sort((a, b) => Comparators.compareStringsAsc(a.address, b.address));
                } else {
                    pos.sort((a, b) => Comparators.compareStringsDesc(a.address, b.address));
                }
                break;
            case PosSorting.Postcode:
                if (direction === SortingDirection.Asc) {
                    pos.sort((a, b) => Comparators.compareStringsAsc(a.postcode, b.postcode));
                } else {
                    pos.sort((a, b) => Comparators.compareStringsDesc(a.postcode, b.postcode));
                }
                break;
            case PosSorting.City:
                if (direction === SortingDirection.Asc) {
                    pos.sort((a, b) => Comparators.compareStringsAsc(a.city, b.city));
                } else {
                    pos.sort((a, b) => Comparators.compareStringsDesc(a.city, b.city));
                }
                break;
            case PosSorting.County:
                if (direction === SortingDirection.Asc) {
                    pos.sort((a, b) => Comparators.compareStringsAsc(a.county, b.county));
                } else {
                    pos.sort((a, b) => Comparators.compareStringsDesc(a.county, b.county));
                }
                break;
        }
        return pos;
    }

    sortPosClick(e, sorting, direction) {
        e.preventDefault();
        this.sortPos(sorting, direction);
    }

    sortPos(sorting, direction) {
        const sortedPos = this.sortedPos(this.state.pos, sorting, direction);
        this.setState({
            pos: sortedPos,
            posSorting: { sorting: sorting, direction: direction }
        });
    }

    render() {
        const { t } = this.props;

        var syncMapBounds = [[0, 0],[0, 0]];
        if (this.state.machinesMapDataLimits) {
            syncMapBounds = [[this.state.machinesMapDataLimits.north, this.state.machinesMapDataLimits.east], [this.state.machinesMapDataLimits.south, this.state.machinesMapDataLimits.west]];
        }

        if (!this.props.selectedCompany) {
            return <p>{t('error_generic')}</p>
        }

        return <div className='dashboard-asset-management'>
            <div className='dashboard-asset-management-top'>
                <div>
                    {DashboardUIHelper.dashboardModuleHeader(this.props.selectedCompany.getBranding(), t('devices'))}
                    <table className='clickable sortable' style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                        <thead style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                            <tr>
                                { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), null, null) }
                                { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('id'), this.state.machinesSorting.sorting === MachinesSorting.ID ? (this.state.machinesSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortMachinesClick(e, MachinesSorting.ID, this.state.machinesSorting.sorting === MachinesSorting.ID  && this.state.machinesSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                                { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('model'), this.state.machinesSorting.sorting === MachinesSorting.Model ? (this.state.machinesSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortMachinesClick(e, MachinesSorting.Model, this.state.machinesSorting.sorting === MachinesSorting.Model  && this.state.machinesSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                                { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('brand'), this.state.machinesSorting.sorting === MachinesSorting.Brand ? (this.state.machinesSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortMachinesClick(e, MachinesSorting.Brand, this.state.machinesSorting.sorting === MachinesSorting.Brand  && this.state.machinesSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                                { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('registration_date'), this.state.machinesSorting.sorting === MachinesSorting.RegistrationDate ? (this.state.machinesSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortMachinesClick(e, MachinesSorting.RegistrationDate, this.state.machinesSorting.sorting === MachinesSorting.RegistrationDate  && this.state.machinesSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                                { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('days_working'), this.state.machinesSorting.sorting === MachinesSorting.DaysWorking ? (this.state.machinesSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortMachinesClick(e, MachinesSorting.DaysWorking, this.state.machinesSorting.sorting === MachinesSorting.DaysWorking  && this.state.machinesSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                                { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('last_contact'), this.state.machinesSorting.sorting === MachinesSorting.LastContact ? (this.state.machinesSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortMachinesClick(e, MachinesSorting.LastContact, this.state.machinesSorting.sorting === MachinesSorting.LastContact  && this.state.machinesSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.machines.map((machine) => <tr onClick={(e) => this.tabFilterSelected(e, TabFilter.AssetId, machine.getAssetId())} style={{opacity: (this.state.tabFilters && this.state.tabFilters[TabFilter.AssetId]) ? (this.state.tabFilters[TabFilter.AssetId] === machine.getAssetId() ? 1 : 0.5) : 1}}>
                                { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), DashboardUIHelper.circleSvgForSyncStatus(machine.getSyncStatus(), t)) }
                                { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), machine.getAssetId()) }
                                { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), machine.getCoolerModel()) }
                                { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), machine.getCoolerBrand()) }
                                { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), DateUtils.formattedDate(machine.getManufacturingDate())) }
                                { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), machine.getDaysActive()) }
                                { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), DateUtils.formattedDate(machine.getLastConnection())) }
                            </tr>) }
                        </tbody>
                        <tfoot style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                            <tr>
                                { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), this.state.machines.length, 7) }
                            </tr>
                        </tfoot>
                    </table>
                </div>
                <div>
                    {DashboardUIHelper.dashboardModuleHeader(this.props.selectedCompany.getBranding(), t('synchronisation'))}
                    <div className='chart-sync-container'>
                    <Bar options={{
                            maintainAspectRatio: false,
                            plugins: {
                                legend: {
                                    display: false,
                                },
                                title: {
                                    display: false
                                },
                                datalabels: {
                                    anchor: 'end',
                                    align: 'top'
                                }
                            },
                            ticks: {
                                precision: 0
                            },
                            onClick: (event, elements) => {
                                if (elements) {
                                    if (elements.length > 0) {
                                        this.tabFilterSelected(event, TabFilter.SyncStatus, Math.abs(elements[0].index + 1 - this.state.machinesSyncStatus.length));
                                    } else if (this.state.tabFilters && this.state.tabFilters[TabFilter.SyncStatus]) {
                                        this.tabFilterSelected(event, TabFilter.SyncStatus, this.state.tabFilters[TabFilter.SyncStatus]);
                                    }
                                }
                            },
                            onHover: (event, chartElement) => {
                                event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
                            }
                        }} data={{
                            labels: [
                                t('alt_sync_status_less_than_1_month'),
                                t('alt_sync_status_more_than_1_month'),
                                t('alt_sync_status_more_than_3_months'),
                                t('alt_sync_status_not_available')
                            ],
                            datasets: [{
                                data: [
                                    this.state.machinesSyncStatus[0],
                                    this.state.machinesSyncStatus[1],
                                    this.state.machinesSyncStatus[2],
                                    this.state.machinesSyncStatus[3]
                                ],
                                backgroundColor: [
                                    "#0cad14" + ((this.state.tabFilters && this.state.tabFilters[TabFilter.SyncStatus] !== 3) ? "88" : "ff"),
                                    "#e6b110" + ((this.state.tabFilters && this.state.tabFilters[TabFilter.SyncStatus] !== 2) ? "88" : "ff"),
                                    "#e61010" + ((this.state.tabFilters && this.state.tabFilters[TabFilter.SyncStatus] !== 1) ? "88" : "ff"),
                                    "#ffffff" + ((this.state.tabFilters && this.state.tabFilters[TabFilter.SyncStatus] !== 0) ? "88" : "ff")
                                ],
                                borderColor: "#888888",
                                borderWidth: 1
                            }]
                        }} />
                    </div>
                    <div>
                        <div className='warehouse-options-container'>
                            <p>{t('warehouse')}</p>
                            <Checkbox
                                icon={<Icon.FiCheck color={this.props.selectedCompany.getBranding().getColorAccent()} size={13} />}
                                name="warehouse-option-yes"
                                checked={true}
                                onChange={(value, event) => {
                                    return this.tabFilterSelected(event, TabFilter.Warehouse, this.calculateWarehouseFilterValue(value, this.checkboxWarehouseNoRef.current.checked));
                                }}
                                borderColor={this.props.selectedCompany.getBranding().getColorAccent()}
                                size={14}
                                borderRadius={4}
                                containerStyle={{ cursor: "pointer" }}
                                labelStyle={{ marginLeft: 5, userSelect: "none" }}
                                label={t('yes')}
                                reference={this.checkboxWarehouseYesRef}
                            />
                            <Checkbox
                                icon={<Icon.FiCheck color={this.props.selectedCompany.getBranding().getColorAccent()} size={13} />}
                                name="warehouse-option-no"
                                checked={true}
                                onChange={(value, event) => {
                                    return this.tabFilterSelected(event, TabFilter.Warehouse, this.calculateWarehouseFilterValue(this.checkboxWarehouseYesRef.current.checked, value));
                                }}
                                borderColor={this.props.selectedCompany.getBranding().getColorAccent()}
                                size={14}
                                borderRadius={4}
                                containerStyle={{ cursor: "pointer" }}
                                labelStyle={{ marginLeft: 5, userSelect: "none" }}
                                label={t('no')}
                                reference={this.checkboxWarehouseNoRef}
                            />
                        </div>
                        <div className='warehouse-chart-container'>
                            <Doughnut options={{
                                maintainAspectRatio: false,
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    title: {
                                        display: false
                                    },
                                    datalabels: {
                                        anchor: 'end',
                                        align: 'top'
                                    }
                                },
                            }} data={{
                                labels: [t('yes') + ' ' + (this.state.machinesWarehouse[0] / (this.state.machinesWarehouse[0] + this.state.machinesWarehouse[1]) * 100).toFixed(2) + '%', t('no') + ' ' + (this.state.machinesWarehouse[1] / (this.state.machinesWarehouse[0] + this.state.machinesWarehouse[1]) * 100).toFixed(2) + '%'],
                                datasets: [{
                                    data: [this.state.machinesWarehouse[0], this.state.machinesWarehouse[1]],
                                    backgroundColor: [this.props.selectedCompany.getBranding().getColorAccent(), this.props.selectedCompany.getBranding().getColorAccent() + '88'],
                                    borderWidth: 1
                                }]
                            }} />
                        </div>
                    </div>
                </div>
                <div className='sync-map-container'>
                    <MapContainer bounds={syncMapBounds} scrollWheelZoom={false} ref={this.mapRef}>
                        <TileLayer
                            attribution={ (this.state.tabFilters && (this.state.tabFilters[TabFilter.AssetId] != null || this.state.tabFilters[TabFilter.MapAssetId] != null)) ?
                               (this.state.machines.find((machine) => machine.getAssetId() === (this.state.tabFilters[TabFilter.AssetId] || this.state.tabFilters[TabFilter.MapAssetId])).getFormattedAddressForMapTile() + ' ') :
                               '' }
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        
                        { this.state.machinesMapData.map((mapItem) => 
                            <Marker position={[mapItem.lat, mapItem.long]} icon={DashboardUIHelper.markerIconForSyncStatus(mapItem.syncStatus)} opacity={(this.state.tabFilters && this.state.tabFilters[TabFilter.MapAssetId]) ? (this.state.tabFilters[TabFilter.MapAssetId] === mapItem.assetId ? 1 : 0.5) : 1} eventHandlers={{
                                mouseover: (event) => event.target.openPopup(),
                                mouseout: (event) => event.target.closePopup(),
                                click: (event) => this.tabFilterSelected(event, TabFilter.MapAssetId, mapItem.assetId)
                            }}>
                                <Popup autoPan={false}>
                                    {t('machine') + ': ' + mapItem.assetId}<br/>
                                    {t('latitude_short') + ': ' + mapItem.lat}<br/>
                                    {t('longitude_short') + ': ' + mapItem.long}<br/>
                                    {t('date') + ': ' + DateUtils.formattedDate(mapItem.timestamp)}
                                </Popup>
                            </Marker>
                        ) }
                    </MapContainer>
                </div>
            </div>
            <div className='dashboard-asset-management-bottom'>
                {DashboardUIHelper.dashboardModuleHeader(this.props.selectedCompany.getBranding(), t('points_of_sales'))}
                <table className='clickable sortable' style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                    <thead style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                        <tr>
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('point_of_sales_short'), this.state.posSorting.sorting === PosSorting.POS ? (this.state.posSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortPosClick(e, PosSorting.POS, this.state.posSorting.sorting === PosSorting.POS  && this.state.posSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('devices'), this.state.posSorting.sorting === PosSorting.MachinesCount ? (this.state.posSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortPosClick(e, PosSorting.MachinesCount, this.state.posSorting.sorting === PosSorting.MachinesCount  && this.state.posSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('client'), this.state.posSorting.sorting === PosSorting.Client ? (this.state.posSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortPosClick(e, PosSorting.Client, this.state.posSorting.sorting === PosSorting.Client  && this.state.posSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('channel'), this.state.posSorting.sorting === PosSorting.Channel ? (this.state.posSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortPosClick(e, PosSorting.Channel, this.state.posSorting.sorting === PosSorting.Channel  && this.state.posSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('description'), this.state.posSorting.sorting === PosSorting.Description ? (this.state.posSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortPosClick(e, PosSorting.Description, this.state.posSorting.sorting === PosSorting.Description  && this.state.posSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('address'), this.state.posSorting.sorting === PosSorting.Address ? (this.state.posSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortPosClick(e, PosSorting.Address, this.state.posSorting.sorting === PosSorting.Address  && this.state.posSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('postcode'), this.state.posSorting.sorting === PosSorting.Postcode ? (this.state.posSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortPosClick(e, PosSorting.Postcode, this.state.posSorting.sorting === PosSorting.Postcode  && this.state.posSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('city'), this.state.posSorting.sorting === PosSorting.City ? (this.state.posSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortPosClick(e, PosSorting.City, this.state.posSorting.sorting === PosSorting.City  && this.state.posSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                            { DashboardUIHelper.getHeaderCell(this.props.selectedCompany.getBranding(), [t('county'), this.state.posSorting.sorting === PosSorting.County ? (this.state.posSorting.direction === SortingDirection.Asc ? <GoTriangleUp/> : <GoTriangleDown/>) : <></>], (e) => this.sortPosClick(e, PosSorting.County, this.state.posSorting.sorting === PosSorting.County  && this.state.posSorting.direction === SortingDirection.Asc ? SortingDirection.Desc : SortingDirection.Asc)) }
                        </tr>
                    </thead>
                    <tbody>
                        { this.state.pos.map((pos) => <tr onClick={(e) => this.tabFilterSelected(e, TabFilter.POS, pos.id)} style={{opacity: (this.state.tabFilters && this.state.tabFilters[TabFilter.POS]) ? (this.state.tabFilters[TabFilter.POS] === pos.id ? 1 : 0.5) : 1}}>
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), pos.id) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), pos.machinesCount) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), pos.client) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), pos.channel) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), pos.description) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), pos.address) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), pos.postcode) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), pos.city) }
                            { DashboardUIHelper.getBodyCell(this.props.selectedCompany.getBranding(), pos.county) }
                        </tr>) }
                    </tbody>
                    <tfoot style={{background: this.props.selectedCompany.getBranding().getColorBackground()}}>
                        <tr>
                            { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), this.state.pos.length) }
                            { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), this.state.machines.length) }
                            { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), null) }
                            { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), null) }
                            { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), null) }
                            { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), null) }
                            { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), null) }
                            { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), null) }
                            { DashboardUIHelper.getFooterCell(this.props.selectedCompany.getBranding(), null) }
                        </tr>
                    </tfoot>
                </table>
            </div>     
        </div>;
    }
}

export default withHelpers(withTranslation()(DashboardAssetManagement))