import React from 'react'
import { withTranslation } from 'react-i18next'
import { withHelpers } from '../../utils/Injection';
import BaseComponent from '../BaseComponent';
import IconClose from '../icons/IconClose';
import IconMenu from '../icons/IconMenu';
import IconFilter from '../icons/IconFilter';

class Header extends BaseComponent {

    constructor(props) {
        super(props);
        if (props.reference) {
            props.reference.current = this;
        }
        this.state = {
            dropdownVisible: false,
            filtersVisible: false
        }
        this.dropdownContentRef = React.createRef();
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.toggleFilters = this.toggleFilters.bind(this);
    }

    closeMenu() {
        if (this.state.dropdownVisible) {
            this.toggleDropdown(null);
        }
    }

    hideFilters() {
        if (this.state.filtersVisible) {
            this.toggleFilters(null);
        }
    }

    itemSelected(e, item) {
        e.preventDefault();
        this.setState({
            dropdownVisible: false
        });
        if (this.dropdownContentRef.current) {
            this.dropdownContentRef.current.scroll({
                top: 0
            });
        }
        if (this.props.onItemSelected) {
            this.props.onItemSelected(item);
        }
    }

    toggleDropdown(e) {
        if (e) {
            e.preventDefault();
        }
        const filtersVisible = (this.state.filtersVisible && !this.state.dropdownVisible) ? false : this.state.filtersVisible;
        this.setState({
            dropdownVisible: !this.state.dropdownVisible,
            filtersVisible: filtersVisible
        });
        if (this.state.dropdownVisible && this.dropdownContentRef.current) {
            this.dropdownContentRef.current.scroll({
                top: 0
            });
        }
        if (this.state.filtersVisible !== filtersVisible && this.props.changeFiltersVisibility) {
            this.props.changeFiltersVisibility(filtersVisible);
        }
    }

    toggleFilters(e) {
        if (e) {
            e.preventDefault();
        }
        const dropdownVisible = (this.state.dropdownVisible && !this.state.filtersVisible) ? false : this.state.dropdownVisible;
        this.setState({
            filtersVisible: !this.state.filtersVisible,
            dropdownVisible: dropdownVisible
        });
        if (this.props.changeFiltersVisibility) {
            this.props.changeFiltersVisibility(!this.state.filtersVisible);
        }
        if (this.state.dropdownVisible !== dropdownVisible && this.dropdownContentRef.current) {
            this.dropdownContentRef.current.scroll({
                top: 0
            });
        }
    }

    render() {
        const { t } = this.props;
        const style = this.props.branding ? { backgroundColor: this.props.branding.getColorMain(), color: this.props.branding.getColorTextMain() } : {}
        const itemStyleSelected = this.props.branding ? { color: this.props.branding.getColorAccent() } : {}
        const dropdownContentStyle = this.props.branding ? {
            display: this.state.dropdownVisible ? "block" : "none",
            backgroundColor: this.props.branding.getColorMain()
        } : {
            display: this.state.dropdownVisible ? "block" : "none"
        };

        return  <header style={style}>
            { this.props.logo && <img className="logo" src={this.props.logo}/> }
            { this.props.menuItems.map(menuItem =>
                <div className={'header-menu-item' + (this.props.selectedItem === menuItem ? ' selected' : '')} style={this.props.selectedItem === menuItem ? itemStyleSelected : {}} onClick={(e) => this.itemSelected(e, menuItem)}>
                    <p>{t(menuItem)}</p>
                </div>
            ) }
            { this.props.showFilters && <div className='filters'>
                <div className='filters-button' onClick={this.toggleFilters}>
                    <IconFilter hexColor={this.props.branding ? this.props.branding.getColorAccent() : "var(--main-color)"} />
                </div>
            </div> }
            { this.props.menuItems.length > 0 && <div class="dropdown" style={style}>
                <div className='dropdown-button' onClick={this.toggleDropdown}>
                    { this.state.dropdownVisible && <IconClose hexColor={this.props.branding ? this.props.branding.getColorAccent() : "var(--main-color)"} /> }
                    { !this.state.dropdownVisible && <IconMenu hexColor={this.props.branding ? this.props.branding.getColorAccent() : "var(--main-color)"} /> }
                </div>
                <div class="dropdown-content" style={dropdownContentStyle} ref={this.dropdownContentRef}>
                { this.props.menuItems.map(menuItem =>
                    <div className={'header-menu-item' + (this.props.selectedItem === menuItem ? ' selected' : '')} style={this.props.selectedItem === menuItem ? itemStyleSelected : {}} onClick={(e) => this.itemSelected(e, menuItem)}>
                        <p>{t(menuItem)}</p>
                    </div>
                ) }
                </div>
            </div> }
        </header>
    }
}

export default withHelpers(withTranslation()(Header))