import React from 'react'
import { withTranslation } from 'react-i18next'
import { withHelpers } from '../../utils/Injection';
import ApiRepository from '../../data/ApiRepository';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import logo from './../../res/logos/logo.svg';
import { HeaderMenuItem } from '../header/HeaderMenuItem';
import * as Icon from "react-icons/fi";
import Validation from '../../utils/Validation';
import IconInfo from '../icons/IconInfo';
import BaseComponent from '../BaseComponent';

class ChangePassword extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            currentPassword: null,
            newPassword: null,
            newPasswordRepeat: null,
            tooltipPasswordVisible: false,
            error: null
        };
        this.onHeaderItemSelected = this.onHeaderItemSelected.bind(this);
        this.handleCurrentPasswordChange = this.handleCurrentPasswordChange.bind(this);
        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        this.handleNewPasswordRepeatChange = this.handleNewPasswordRepeatChange.bind(this);
        this.toggleTooltipPasswordVisibility = this.toggleTooltipPasswordVisibility.bind(this);
        this.doPasswordChange = this.doPasswordChange.bind(this);
    }

    onHeaderItemSelected(item) {
        if (item === HeaderMenuItem.Back) {
            this.props.navigate("/account");
        }
    }

    doPasswordChange(e) {
        e. preventDefault();

        const { t } = this.props;
        var errorText = "";

        if (Validation.isEmptyString(this.state.currentPassword)) {
            errorText = t('error_empty_current_password');
        }
        if (Validation.isEmptyString(this.state.newPassword)) {
            if (!Validation.isEmptyString(errorText)) {
                errorText += '\n';
            }
            errorText += t('error_empty_new_password');
        }
        if (Validation.isEmptyString(this.state.newPasswordRepeat)) {
            if (!Validation.isEmptyString(errorText)) {
                errorText += '\n';
            }
            errorText += t('error_empty_new_password_again');
        }
        if (!Validation.isEmptyString(errorText)) {
            this.setState({
                error: errorText
            });
            return;
        }
        if (this.state.newPassword != this.state.newPasswordRepeat) {
            this.setState({
                error: t('error_passwords_not_matching')
            });
            return;
        }
        this.setState({
            error: null
        });
        ApiRepository.changePassword(
            this.state.currentPassword,
            this.state.newPassword,
            (response) => {
                this.props.auth.setToken(null);
                this.props.navigate("/login", { replace: true });
                alert(t('password_successfully_updated'));
            },
            (sessionExpired, errorMessage) => {
                if (sessionExpired) {
                    this.resetTokenAndGoToLogin();
                    alert(errorMessage);
                } else {
                    this.setState({ error: errorMessage });
                }
            }
        )
    }

    handleCurrentPasswordChange(e) {
        this.setState({ currentPassword: e.target.value });
    }

    handleNewPasswordChange(e) {
        this.setState({ newPassword: e.target.value });
    }

    handleNewPasswordRepeatChange(e) {
        this.setState({ newPasswordRepeat: e.target.value });
    }

    toggleTooltipPasswordVisibility(e) {
        e. preventDefault();
        this.setState({ tooltipPasswordVisible: !this.state.tooltipPasswordVisible });
    }

    render() {
        const { t } = this.props;
        const menuItems = [HeaderMenuItem.Back];

        return <>
            <Header
                logo={ logo }
                menuItems={menuItems}
                onItemSelected={(item) => this.onHeaderItemSelected(item)}
                selectedItem={menuItems[0]}
            />
            <div className='account-container'>
                <div className='account-content'>
                    <p className='header-over-card'>{t('change_password')}</p>
                    <form className='change-password-form' onSubmit={this.doPasswordChange} noValidate>
                        <div className="form-container">
                            <label htmlFor="password">{t('old_password_input_placeholder')}</label>
                            <input type="password" placeholder={t('old_password_input_placeholder')} name="password" onChange={this.handleCurrentPasswordChange}/>
                            <label htmlFor="password">{t('new_password_input_placeholder')}<span className='tooltip-trigger' onClick={this.toggleTooltipPasswordVisibility}><IconInfo className='input-hint-icon'/></span></label>
                            <label htmlFor='password' className={'tooltip-' + (this.state.tooltipPasswordVisible ? 'expanded' : 'collapsed')}>{t('password_requirements_description')}</label>
                            <input type="password" placeholder={t('new_password_input_placeholder')} name="password" onChange={this.handleNewPasswordChange}/>
                            <input type="password" placeholder={t('new_password_again_input_placeholder')} name="password" onChange={this.handleNewPasswordRepeatChange}/>

                            { !Validation.isEmptyString(this.state.error) && <p className='error'>{this.state.error}</p> }

                            <button type="submit">{t('action_change_password')}</button>
                        </div>
                    </form>
                </div>
            </div>
            <Footer/>
        </>
    }
}

export default withHelpers(withTranslation()(ChangePassword))