import React, { useContext } from 'react'
import { withTranslation } from 'react-i18next'
import { withHelpers } from '../../utils/Injection';
import Header from './../header/Header';
import Footer from './../footer/Footer';
import logo from './../../res/logos/logo.svg';
import ApiRepository from '../../data/ApiRepository';
import CompaniesList from './CompaniesList';
import { DashboardFilterOptions } from '../../model/DashboardFilterOptions';
import IconDelete from '../icons/IconDelete';
import IconSearch from '../icons/IconSearch';
import { HeaderMenuItem } from '../header/HeaderMenuItem';
import DashboardAssetManagement from './DashboardAssetManagement';
import DashboardCommercialManagement from './DashboardCommercialManagement';
import DashboardTemperature from './DashboardTemperature';
import DashboardDocumentation from './DashboardDocumentation';
import DashboardIotPremium from './DashboardIotPremium';
import DashboardAdmin from './DashboardAdmin';
import BaseComponent from '../BaseComponent';

class Dashboard extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            companies: null,
            selectedCompany: null,
            machines: null,
            filteredMachines: null,
            aggregatedEvents: null,
            filtersToolbarVisible: false,
            filteredAggregatedEvents: null,
            filterOptions: null,
            filterDropdown: null,
            filters: null,
            machineSearchFilter: null,
            selectedHeaderMenuItem: null
        };
        this.headerRef = React.createRef();
        this.dashboardAssetManagementRef = React.createRef();
        this.dashboardCommercialManagementRef = React.createRef();
        this.dashboardTemperatureRef = React.createRef();
        this.dashboardDocumentationRef = React.createRef();
        this.dashboardIotPremiumRef = React.createRef();
        this.dashboardAdminRef = React.createRef();
        this.selectCompany = this.selectCompany.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.filterDropdownSelected = this.filterDropdownSelected.bind(this);
        this.onHeaderItemSelected = this.onHeaderItemSelected.bind(this);
        this.onFiltersVisibilityChange = this.onFiltersVisibilityChange.bind(this);
        this.machineSearchFilterChanged = this.machineSearchFilterChanged.bind(this);
    }

    componentDidMount() {
        ApiRepository.getCompanies(
            (companies) => {
                this.setState({
                    loading: false,
                    companies: companies,
                    selectedCompany: companies && companies.length === 1 ? companies[0] : null
                });
            },
            (sessionExpired, errorMessage) => {
                this.setState({
                    loading: false
                });
                if (sessionExpired) {
                    this.resetTokenAndGoToLogin();
                    alert(errorMessage);
                }
            }
        )
    }

    selectCompany(company) {
        if (this.headerRef.current) {
            this.headerRef.current.hideFilters();
            this.headerRef.current.closeMenu();
        }
        this.setState({
            loading: true,
            selectedCompany: company,
            selectedHeaderMenuItem: HeaderMenuItem.AssetManagement
        });
        ApiRepository.getCompanyMachines(
            company.getId(),
            (machines) => {
                if (machines == null) {
                    return;
                }
                var filterOptions = {}
                if (machines) {
                    filterOptions[DashboardFilterOptions.Zone] = [...new Set(machines.map(machine => machine.getZone()))];
                    filterOptions[DashboardFilterOptions.County] = [...new Set(machines.map(machine => machine.getCounty()))];
                    filterOptions[DashboardFilterOptions.Channel] = [...new Set(machines.map(machine => machine.getChannel()))];
                    filterOptions[DashboardFilterOptions.Model] = [...new Set(machines.map(machine => machine.getCoolerModel()))];
                    filterOptions[DashboardFilterOptions.Client] = [...new Set(machines.map(machine => machine.getClient()))];
                    filterOptions[DashboardFilterOptions.Brand] = [...new Set(machines.map(machine => machine.getCoolerBrand()))];
                    filterOptions[DashboardFilterOptions.Machine] = [...new Set(machines.map(machine => machine.getAssetId()))];
                }
                this.setState({
                    machines: machines,
                    filteredMachines: null,
                    filterOptions: filterOptions
                });
                ApiRepository.getCompanyAggregatedEventsAll(
                    company.getId(),
                    (aggregatedEvents) => {
                        this.setState({
                            loading: false,
                            aggregatedEvents: aggregatedEvents,
                            filteredAggregatedEvents: null
                        });
                    },
                    (sessionExpired, errorMessage) => {
                        this.setState({
                            loading: false
                        });
                        if (sessionExpired) {
                            this.resetTokenAndGoToLogin();
                            alert(errorMessage);
                        }
                    }
                );
            },
            (sessionExpired, errorMessage) => {
                if (sessionExpired) {
                    this.resetTokenAndGoToLogin();
                    alert(errorMessage);
                }
            }
        )
    }

    clearCompanySelection() {
        this.setState({
            loading: false,
            selectedCompany: null,
            machines: null,
            filteredMachines: null,
            aggregatedEvents: null,
            filteredAggregatedEvents: null,
            filterOptions: null,
            filterDropdown: null,
            filters: null,
            machineSearchFilter: null,
            selectedHeaderMenuItem: null
        });
    }

    notifyRefresh(machines, aggregatedEvents) {
        if (this.state.selectedHeaderMenuItem === HeaderMenuItem.AssetManagement) {
            if (this.dashboardAssetManagementRef.current) {
                this.dashboardAssetManagementRef.current.refresh(machines, aggregatedEvents);
            }
        } else if (this.state.selectedHeaderMenuItem === HeaderMenuItem.CommercialManagement) {
            if (this.dashboardCommercialManagementRef.current) {
                this.dashboardCommercialManagementRef.current.refresh(machines, aggregatedEvents);
            }
        }
    }

    clearFilters(e) {
        e.preventDefault();
        this.setState({
            filteredMachines: null,
            filteredAggregatedEvents: null,
            filters: null,
            tabFilters: null,
            filterDropdown: null,
            machineSearchFilter: null
        });
        this.notifyRefresh(this.state.machines, this.state.aggregatedEvents);
    }

    clearFilter(e, dashboardFilterOption) {
        e.preventDefault();
        if (this.state.filters && dashboardFilterOption in this.state.filters) {
            var filters = this.state.filters;
            delete filters[dashboardFilterOption];
            const filteredMachines = this.filteredMachines(filters);
            const filteredAggregatedEvents = this.filteredAggregatedEvents(filters);
            this.setState({
                filteredMachines: filteredMachines,
                filteredAggregatedEvents: filteredAggregatedEvents,
                filters: filters,
                filterDropdown: null,
                machineSearchFilter: null
            });
            this.notifyRefresh(filteredMachines, filteredAggregatedEvents);
        } else if (this.state.filterDropdown === dashboardFilterOption) {
            this.setState({
                filterDropdown: null,
                machineSearchFilter: null
            });
        }
    }

    filterDropdownSelected(e, filterDropdown) {
        e.preventDefault();
        if (!this.state.aggregatedEvents) {
            return;
        }
        this.setState({
            filterDropdown: this.state.filterDropdown === filterDropdown ? null : filterDropdown,
            machineSearchFilter: null
        });
    }

    filterSelected(e, dashboardFilterOption, item) {
        e.preventDefault();
        var filters = this.state.filters
        if (!filters) {
            filters = {}
        }
        filters[dashboardFilterOption] = item;
        const filteredMachines = this.filteredMachines(filters);
        const filteredAggregatedEvents = this.filteredAggregatedEvents(filters);
        this.setState({
            filteredMachines: filteredMachines,
            filteredAggregatedEvents: filteredAggregatedEvents,
            filters: filters,
            filterDropdown: null,
            machineSearchFilter: null
        });
        this.notifyRefresh(filteredMachines, filteredAggregatedEvents);
    }

    filteredMachines(filters) {
        if (!filters) {
            return this.state.machines;
        }
        var machines = this.state.machines;
        for (const [key, value] of Object.entries(filters)) {
            switch (key) {
                case DashboardFilterOptions.Zone:
                    machines = machines.filter((machine) => machine.getZone() === value)
                    break;
                case DashboardFilterOptions.County:
                    machines = machines.filter((machine) => machine.getCounty() === value)
                    break;
                case DashboardFilterOptions.Channel:
                    machines = machines.filter((machine) => machine.getChannel() === value)
                    break;
                case DashboardFilterOptions.Model:
                    machines = machines.filter((machine) => machine.getCoolerModel() === value)
                    break;
                case DashboardFilterOptions.Client:
                    machines = machines.filter((machine) => machine.getClient() === value)
                    break;
                case DashboardFilterOptions.Brand:
                    machines = machines.filter((machine) => machine.getCoolerBrand() === value)
                    break;
                case DashboardFilterOptions.Machine:
                    machines = machines.filter((machine) => machine.getAssetId() === value)
                    break;
            }
        }
        return machines;
    }

    filteredAggregatedEvents(filters) {
        return this.state.aggregatedEvents;
    }

    onHeaderItemSelected(item) {
        if (item === HeaderMenuItem.CompaniesList) {
            this.clearCompanySelection();
        } else if (item === HeaderMenuItem.Account) {
            this.props.navigate("/account");
        } else if (item === HeaderMenuItem.Logout) {
            this.props.auth.setToken(null);
            this.props.navigate("/login", { replace: true });
        } else {
            this.setState({
                selectedHeaderMenuItem: item,
                tabFilters: null
            });
        }
    }

    onFiltersVisibilityChange(visible) {
        this.setState({
            filtersToolbarVisible: visible,
            filterDropdown: null,
            machineSearchFilter: null
        });
    }

    machineSearchFilterChanged(e) {
        this.setState({ 
            machineSearchFilter: e.target.value
        });
    }

    render() {
        const { t } = this.props;
        const headerMenuItems = this.state.selectedCompany ?
            [HeaderMenuItem.AssetManagement, HeaderMenuItem.CommercialManagement, HeaderMenuItem.Temperature, HeaderMenuItem.Documentation, HeaderMenuItem.IotPremium, HeaderMenuItem.Admin, HeaderMenuItem.CompaniesList] :
            [HeaderMenuItem.CompaniesList, HeaderMenuItem.Account, HeaderMenuItem.Logout]
        const containerStyle = this.state.selectedCompany ? { backgroundColor: this.state.selectedCompany.getBranding().getColorBackground(), color: this.state.selectedCompany.getBranding().getColorText() } : {}
        const selectedHeaderMenuItem = this.state.selectedHeaderMenuItem ? this.state.selectedHeaderMenuItem : headerMenuItems[0];

        return <div className='dashboard-container' style={containerStyle}>
            <Header
                logo={ this.state.selectedCompany ? this.state.selectedCompany.getBranding().getLogo() : logo }
                branding={ this.state.selectedCompany ? this.state.selectedCompany.getBranding() : null }
                showFilters={ this.state.selectedCompany !== null }
                changeFiltersVisibility={(visible) => this.onFiltersVisibilityChange(visible)}
                menuItems={headerMenuItems}
                onItemSelected={(item) => this.onHeaderItemSelected(item)}
                selectedItem={ selectedHeaderMenuItem }
                reference={this.headerRef}
            />
            <div>
                { this.state.loading && <div className='dashboard-content'>
                    <p>{t('loading_ellipsis')}</p>
                </div> }
                
                {/* Companies list */}
                { this.state.companies && this.state.companies.length === 0 && <p>{t('no_companies_placeholder')}</p> }
                { this.state.companies && this.state.companies.length > 0 && !this.state.selectedCompany && <CompaniesList t={t} companies={this.state.companies} selectCompany={this.selectCompany}/> }
                
                {/* Filters */}
                { this.state.selectedCompany && <>
                    <div className='filters-container' style={{ backgroundColor: this.state.selectedCompany.getBranding().getColorMain() }}>
                        <p style={{color: this.state.selectedCompany.getBranding().getColorTextMain()}}>{t('applied_filters')}</p>
                        { Object.values(DashboardFilterOptions).map((filterOption) => <>
                            <div>
                                <p className='filter-name' style={{ color: this.state.selectedCompany.getBranding().getColorTextMain() }}>{t(filterOption)}</p>
                                <IconDelete className="filter-delete-icon" hexColor={this.state.selectedCompany.getBranding().getColorTextMain()} onClick={(e) => this.clearFilter(e, filterOption)}/>
                            </div>
                            <p className='filter-value' onClick={(e) => this.filterDropdownSelected(e, filterOption)} style={{ color: this.state.selectedCompany.getBranding().getColorTextMain() }}>{ (this.state.filters && (filterOption in this.state.filters)) ? this.state.filters[filterOption] : t('all') }</p>
                            { this.state.filterDropdown === filterOption && <div
                                className="filter-dropdown-content"
                                style={{ backgroundColor: this.state.selectedCompany.getBranding().getColorBackground(), color: this.state.selectedCompany.getBranding().getColorText() }}
                                onMouseOver={ (e) => {
                                    if (e.target.localName === "p") {
                                        e.target.style.backgroundColor=this.state.selectedCompany.getBranding().getColorAccent();
                                        e.target.style.color=this.state.selectedCompany.getBranding().getColorTextMainAccent();
                                    }
                                }}
                                onMouseOut={ (e) => {
                                    if (e.target.localName === "p") {
                                        e.target.style.backgroundColor=this.state.selectedCompany.getBranding().getColorBackground();
                                        e.target.style.color=this.state.selectedCompany.getBranding().getColorText();
                                    }
                                }}
                                >
                                { DashboardFilterOptions.Machine === filterOption && <div className='filter-search'>
                                    <IconSearch className="filter-search-icon" hexColor={this.state.selectedCompany.getBranding().getColorAccent()}/>
                                    <input type="text" placeholder={t('action_search')} onChange={this.machineSearchFilterChanged} style={{
                                        border: "1px solid " + this.state.selectedCompany.getBranding().getColorAccent(),
                                        backgroundColor: this.state.selectedCompany.getBranding().getColorBackground(),
                                        color: this.state.selectedCompany.getBranding().getColorText()
                                    }}/>
                                </div>}
                                { this.state.filterOptions[filterOption].filter((item) => {
                                    if (item !== null && item.length > 0) {
                                        if (DashboardFilterOptions.Machine === filterOption && this.state.machineSearchFilter != null && this.state.machineSearchFilter !== "") {
                                            return item.startsWith(this.state.machineSearchFilter);
                                        }
                                        return true;
                                    }
                                    return false;
                                }).map((item) => 
                                    <p onClick={(e) => this.filterSelected(e, filterOption, item)}>{item}</p>
                                ) }
                            </div> }
                        </>) }
                        <button style={{ backgroundColor: this.state.selectedCompany.getBranding().getColorAccent(), color: this.state.selectedCompany.getBranding().getColorTextMainAccent() }} onClick={this.clearFilters}>{t('clear_filters')}</button>
                    </div>
                </>}
                { this.state.selectedCompany && this.state.filtersToolbarVisible && <>
                    <div className='filters-container filters-toolbar' style={{ backgroundColor: this.state.selectedCompany.getBranding().getColorMain(), display: "block" }}>
                        <p style={{color: this.state.selectedCompany.getBranding().getColorTextMain()}}>{t('applied_filters')}</p>
                        { Object.values(DashboardFilterOptions).map((filterOption) => <>
                            <div>
                                <p className='filter-name' style={{ color: this.state.selectedCompany.getBranding().getColorTextMain() }}>{t(filterOption)}</p>
                                <IconDelete className="filter-delete-icon" hexColor={this.state.selectedCompany.getBranding().getColorTextMain()} onClick={(e) => this.clearFilter(e, filterOption)}/>
                            </div>
                            <p className='filter-value' onClick={(e) => this.filterDropdownSelected(e, filterOption)} style={{ color: this.state.selectedCompany.getBranding().getColorTextMain() }}>{ (this.state.filters && (filterOption in this.state.filters)) ? this.state.filters[filterOption] : t('all') }</p>
                            { this.state.filterDropdown === filterOption && <div
                                className="filter-dropdown-content"
                                style={{ backgroundColor: this.state.selectedCompany.getBranding().getColorBackground(), color: this.state.selectedCompany.getBranding().getColorText() }}
                                onMouseOver={ (e) => {
                                    if (e.target.localName === "p") {
                                        e.target.style.backgroundColor=this.state.selectedCompany.getBranding().getColorAccent();
                                        e.target.style.color=this.state.selectedCompany.getBranding().getColorTextMainAccent();
                                    }
                                }}
                                onMouseOut={ (e) => {
                                    if (e.target.localName === "p") {
                                        e.target.style.backgroundColor=this.state.selectedCompany.getBranding().getColorBackground();
                                        e.target.style.color=this.state.selectedCompany.getBranding().getColorText();
                                    }
                                }}
                                >
                                { DashboardFilterOptions.Machine === filterOption && <div className='filter-search'>
                                    <IconSearch className="filter-search-icon" hexColor={this.state.selectedCompany.getBranding().getColorAccent()}/>
                                    <input type="text" placeholder={t('action_search')} onChange={this.machineSearchFilterChanged} style={{
                                        border: "1px solid " + this.state.selectedCompany.getBranding().getColorAccent(),
                                        backgroundColor: this.state.selectedCompany.getBranding().getColorBackground(),
                                        color: this.state.selectedCompany.getBranding().getColorText()
                                    }}/>
                                </div>}
                                { this.state.filterOptions[filterOption].filter((item) => {
                                    if (item !== null && item.length > 0) {
                                        if (DashboardFilterOptions.Machine === filterOption && this.state.machineSearchFilter != null && this.state.machineSearchFilter !== "") {
                                            return item.startsWith(this.state.machineSearchFilter);
                                        }
                                        return true;
                                    }
                                    return false;
                                }).map((item) => 
                                    <p onClick={(e) => this.filterSelected(e, filterOption, item)}>{item}</p>
                                ) }
                            </div> }
                        </>) }
                        <button style={{ backgroundColor: this.state.selectedCompany.getBranding().getColorAccent(), color: this.state.selectedCompany.getBranding().getColorTextMainAccent() }} onClick={this.clearFilters}>{t('clear_filters')}</button>
                    </div>
                </>}

                {/* Content */}
                { this.state.selectedCompany && this.state.aggregatedEvents && <div className='dashboard-content' style={{background: this.state.selectedCompany.getBranding().getColorBackground()}}>
                    { this.state.selectedHeaderMenuItem === HeaderMenuItem.AssetManagement && <DashboardAssetManagement
                        selectedCompany={this.state.selectedCompany}
                        machines={this.state.filteredMachines != null ? this.state.filteredMachines : this.state.machines}
                        aggregatedEvents={this.state.filteredAggregatedEvents != null ? this.state.filteredAggregatedEvents : this.state.aggregatedEvents}
                        reference={this.dashboardAssetManagementRef}
                    /> }
                    { this.state.selectedHeaderMenuItem === HeaderMenuItem.CommercialManagement && <DashboardCommercialManagement
                        selectedCompany={this.state.selectedCompany}
                        machines={this.state.filteredMachines != null ? this.state.filteredMachines : this.state.machines}
                        aggregatedEvents={this.state.filteredAggregatedEvents != null ? this.state.filteredAggregatedEvents : this.state.aggregatedEvents}
                        reference={this.dashboardCommercialManagementRef}
                    /> }
                    { this.state.selectedHeaderMenuItem === HeaderMenuItem.Temperature && <DashboardTemperature
                        selectedCompany={this.state.selectedCompany}
                        machines={this.state.filteredMachines != null ? this.state.filteredMachines : this.state.machines}
                        aggregatedEvents={this.state.filteredAggregatedEvents != null ? this.state.filteredAggregatedEvents : this.state.aggregatedEvents}
                        reference={this.dashboardTemperatureRef}
                    /> }
                    { this.state.selectedHeaderMenuItem === HeaderMenuItem.Documentation && <DashboardDocumentation
                        selectedCompany={this.state.selectedCompany}
                        machines={this.state.filteredMachines != null ? this.state.filteredMachines : this.state.machines}
                        aggregatedEvents={this.state.filteredAggregatedEvents != null ? this.state.filteredAggregatedEvents : this.state.aggregatedEvents}
                        reference={this.dashboardDocumentationRef}
                    /> }
                    { this.state.selectedHeaderMenuItem === HeaderMenuItem.IotPremium && <DashboardIotPremium
                        selectedCompany={this.state.selectedCompany}
                        machines={this.state.filteredMachines != null ? this.state.filteredMachines : this.state.machines}
                        aggregatedEvents={this.state.filteredAggregatedEvents != null ? this.state.filteredAggregatedEvents : this.state.aggregatedEvents}
                        reference={this.dashboardIotPremiumRef}
                    /> }
                    { this.state.selectedHeaderMenuItem === HeaderMenuItem.Admin && <DashboardAdmin
                        selectedCompany={this.state.selectedCompany}
                        machines={this.state.filteredMachines != null ? this.state.filteredMachines : this.state.machines}
                        aggregatedEvents={this.state.filteredAggregatedEvents != null ? this.state.filteredAggregatedEvents : this.state.aggregatedEvents}
                        reference={this.dashboardAdminRef}
                    /> }
                </div>}
            </div>
            <Footer/>
        </div>
    }
}

export default withHelpers(withTranslation()(Dashboard))