import { CompanyRole } from "./CompanyRole";

export default class Company {

    #id = null;
    #name = null;
    #tradeName = null;
    #taxId = null;
    #email = null;
    #phone = null;
    #addressLine1 = null;
    #addressLine2 = null;
    #city = null;
    #postcode = null;
    #county = null;
    #country = null;
    #publicKeyLast4 = null;
    #secretKeyLast4 = null;
    #clientCode = null;
    #commerceNumber = null;
    #role = null;
    #active = null;

    constructor(id, name, tradeName, taxId, email, phone, addressLine1, addressLine2, city, postcode, county, country, publicKeyLast4, secretKeyLast4, clientCode, commerceNumber, role, active) {
        this.#id = id;
        this.#name = name;
        this.#tradeName = tradeName;
        this.#taxId = taxId;
        this.#email = email;
        this.#phone = phone;
        this.#addressLine1 = addressLine1;
        this.#addressLine2 = addressLine2;
        this.#city = city;
        this.#postcode = postcode;
        this.#county = county;
        this.#country = country;
        this.#publicKeyLast4 = publicKeyLast4;
        this.#secretKeyLast4 = secretKeyLast4;
        this.#clientCode = clientCode;
        this.#commerceNumber = commerceNumber;
        this.#role = role;
        this.#active = active;
    }

    static fromServerResponse(serverResponse) {
        return new Company(
            serverResponse.id,
            serverResponse.name,
            serverResponse.trade_name,
            serverResponse.tax_id,
            serverResponse.email,
            serverResponse.phone,
            serverResponse.address_line1,
            serverResponse.address_line2,
            serverResponse.city,
            serverResponse.postcode,
            serverResponse.county,
            serverResponse.country,
            serverResponse.public_key_last4,
            serverResponse.secret_key_last4,
            serverResponse.client_code,
            serverResponse.commerce_number,
            serverResponse.role,
            serverResponse.active
        )
    }

    getId() {
        return this.#id;
    }

    setId(id) {
        this.#id = id;
    }

    getName() {
        return this.#name;
    }

    setName(name) {
        this.#name = name;
    }

    getTradeName() {
        return this.#tradeName;
    }

    setTradeName(tradeName) {
        this.#tradeName = tradeName;
    }

    getTaxId() {
        return this.#taxId;
    }

    setTaxId(taxId) {
        this.#taxId = taxId;
    }

    getEmail() {
        return this.#email;
    }

    setEmail(email) {
        this.#email = email;
    }

    getPhone() {
        return this.#phone;
    }

    setPhone(phone) {
        this.#phone = phone;
    }

    getAddressLine1() {
        return this.#addressLine1;
    }

    setAddressLine1(addressLine1) {
        this.#addressLine1 = addressLine1;
    }

    getAddressLine2() {
        return this.#addressLine2;
    }

    setAddressLine2(addressLine2) {
        this.#addressLine2 = addressLine2;
    }

    getCity() {
        return this.#city;
    }

    setCity(city) {
        this.#city = city;
    }

    getPostcode() {
        return this.#postcode;
    }

    setPostcode(postcode) {
        this.#postcode = postcode;
    }

    getCounty() {
        return this.#county;
    }

    setCounty(county) {
        this.#county = county;
    }

    getCountry() {
        return this.#country;
    }

    setCountry(country) {
        this.#country = country;
    }

    getPublicKeyLast4() {
        return this.#publicKeyLast4;
    }

    setPublicKeyLast4(publicKeyLast4) {
        this.#publicKeyLast4 = publicKeyLast4;
    }

    getSecretKeyLast4() {
        return this.#secretKeyLast4;
    }

    setSecretKeyLast4(secretKeyLast4) {
        this.#secretKeyLast4 = secretKeyLast4;
    }

    getClientCode() {
        return this.#clientCode;
    }

    setClientCode(clientCode) {
        this.#clientCode = clientCode;
    }

    getCommerceNumber() {
        return this.#commerceNumber;
    }

    setCommerceNumber(commerceNumber) {
        this.#commerceNumber = commerceNumber;
    }

    getRoleObj() {
        if (this.#role === CompanyRole.Viewer) {
            return CompanyRole.Viewer;
        } else if (this.#role === CompanyRole.Editor) {
            return CompanyRole.Editor;
        }
        return CompanyRole.Unknown;
    }

    getRole() {
        return this.#role;
    }

    setRole(role) {
        this.#role = role;
    }

    isActive() {
        return this.#active === true;
    }

    getActive() {
        return this.#active;
    }

    setActive(active) {
        this.#active = active;
    }
}
            