export default class IotMachine {

    #serial = null;
    #assetId = null;
    #pos = null;
    #typeId = null;
    #manufactureYear = null;
    #description = null;
    #deliveryDate = null;
    #address = null;
    #postcode = null;
    #city = null;
    #county = null
    #zone = null;
    #warehouse = null;
    #client = null;
    #channel = null;
    #coolerModel = null;
    #coolerBrand = null;
    #controllerSn = null;
    #imei = null;
    #manufacturingDate = null;
    #aux = null;
    #syncStatus = null;
    #tempStatus = null;
    #daysActive = null;
    #lastConnection = null;
    #lastPositionDate = null;
    #lastLat = null;
    #lastLong = null;
    #lastTemperatureDate = null;
    #lastTemperature = null;
    #lastTemperatures = null;
    #machineEvents = null;

    constructor(serial, assetId, pos, typeId, manufactureYear, description, deliveryDate, address, postcode, city, county, zone, warehouse, client, channel, coolerModel, coolerBrand, controllerSn, imei, manufacturingDate, aux, syncStatus, tempStatus, daysActive, lastConnection, lastPositionDate, lastLat, lastLong, lastTemperatureDate, lastTemperature, lastTemperatures) {
        this.#serial = serial;
        this.#assetId = assetId;
        this.#pos = pos;
        this.#typeId = typeId;
        this.#manufactureYear = manufactureYear;
        this.#description = description;
        this.#deliveryDate = deliveryDate;
        this.#address = address;
        this.#postcode = postcode;
        this.#city = city;
        this.#county = county
        this.#zone = zone;
        this.#warehouse = warehouse;
        this.#client = client;
        this.#channel = channel;
        this.#coolerModel = coolerModel;
        this.#coolerBrand = coolerBrand;
        this.#controllerSn = controllerSn;
        this.#imei = imei;
        this.#manufacturingDate = manufacturingDate;
        this.#aux = aux;
        this.#syncStatus = syncStatus;
        this.#tempStatus = tempStatus;
        this.#daysActive = daysActive;
        this.#lastConnection = lastConnection;
        this.#lastPositionDate = lastPositionDate;
        this.#lastLat = lastLat;
        this.#lastLong = lastLong;
        this.#lastTemperatureDate = lastTemperatureDate;
        this.#lastTemperature = lastTemperature;
        this.#lastTemperatures = lastTemperatures;
    }

    static fromServerResponse(serverResponse) {
        // {
        //     "pos": "ST-0000003270",
        //     "asset_id": "00001",
        //     "type_id": null,
        //     "manufacture_year": "2023",
        //     "description": "Super U 5 rue de l Erdre Nort sur Erdre",
        //     "delivery_date": "2023-03-30",
        //     "address": "5 Rue de l'Erdre",
        //     "postcode": "44390",
        //     "city": "Nort-sur-Erdre",
        //     "county": "44",
        //     "zone": null,
        //     "warehouse": "No",
        //     "client": "super u",
        //     "channel": "GMS",
        //     "serial": "5600338746000001.23",
        //     "cooler_model": "ST1",
        //     "cooler_brand": "GREEN SYSTEMS",
        //     "controller_sn": "BAEEAB79FB",
        //     "imei": "354018118524408",
        //     "manufacturing_date": "2023-01-15",
        //     "aux": null,
        //     "sync_status": 3,
        //     "temp_status": 0,
        //     "days_active": 356,
        //     "last_connection": "2024-03-28"
        // }
        return new IotMachine(
            serverResponse.serial,
            serverResponse.asset_id,
            serverResponse.pos,
            serverResponse.type_id,
            serverResponse.manufacture_year,
            serverResponse.description,
            serverResponse.delivery_date,
            serverResponse.address,
            serverResponse.postcode,
            serverResponse.city,
            serverResponse.county,
            serverResponse.zone,
            serverResponse.warehouse && serverResponse.warehouse.toLowerCase() !== "no",
            serverResponse.client,
            serverResponse.channel,
            serverResponse.cooler_model,
            serverResponse.cooler_brand,
            serverResponse.controller_sn,
            serverResponse.imei,
            serverResponse.manufacturing_date,
            serverResponse.aux,
            serverResponse.sync_status,
            serverResponse.temp_status,
            serverResponse.days_active,
            serverResponse.last_connection,
            serverResponse.last_position_date,
            serverResponse.last_lat,
            serverResponse.last_long,
            serverResponse.last_temperature_date,
            serverResponse.last_temperature,
            serverResponse.last_temperatures
        );
    }

    getSerial() {
        return this.#serial;
    }

    setSerial(serial) {
        this.#serial = serial;
    }

    getAssetId() {
        return this.#assetId;
    }

    setAssetId(assetId) {
        this.#assetId = assetId;
    }

    getPos() {
        return this.#pos;
    }

    setPos(pos) {
        this.#pos = pos;
    }

    getTypeId() {
        return this.#typeId;
    }

    setTypeId(typeId) {
        this.#typeId = typeId;
    }

    getManufactureYear() {
        return this.#manufactureYear;
    }

    setManufactureYear(manufactureYear) {
        this.#manufactureYear = manufactureYear;
    }

    getDescription() {
        return this.#description;
    }

    setDescription(description) {
        this.#description = description;
    }

    getDeliveryDate() {
        return this.#deliveryDate;
    }

    setDeliveryDate(deliveryDate) {
        this.#deliveryDate = deliveryDate;
    }

    getFormattedAddressForMapTile() {
        var formattedAddress = "";
        if (this.getAddress()) {
            formattedAddress += this.getAddress();
        }
        if (this.getCity()) {
            if (formattedAddress.length > 0) {
                formattedAddress += ", ";
            }
            formattedAddress += this.getCity();
        }
        if (this.getCounty()) {
            if (formattedAddress.length > 0) {
                formattedAddress += ", ";
            }
            formattedAddress += this.getCounty();
        }
        return formattedAddress;
    }

    getAddress() {
        return this.#address;
    }

    setAddress(address) {
        this.#address = address;
    }

    getPostcode() {
        return this.#postcode;
    }

    setPostcode(postcode) {
        this.#postcode = postcode;
    }

    getCity() {
        return this.#city;
    }

    setCity(city) {
        this.#city = city;
    }

    getCounty() {
        return this.#county;
    }

    setCounty(county) {
        this.#county = county;
    }

    getZone() {
        return this.#zone;
    }

    setZone(zone) {
        this.#zone = zone;
    }

    isWarehouse() {
        return this.#warehouse;
    }

    setWarehouse(warehouse) {
        this.#warehouse = warehouse;
    }

    getClient() {
        return this.#client;
    }

    setClient(client) {
        this.#client = client;
    }

    getChannel() {
        return this.#channel;
    }

    setChannel(channel) {
        this.#channel = channel;
    }

    getCoolerModel() {
        return this.#coolerModel;
    }

    setCoolerModel(coolerModel) {
        this.#coolerModel = coolerModel;
    }

    getCoolerBrand() {
        return this.#coolerBrand;
    }

    setCoolerBrand(coolerBrand) {
        this.#coolerBrand = coolerBrand;
    }

    getControllerSn() {
        return this.#controllerSn;
    }

    setControllerSn(controllerSn) {
        this.#controllerSn = controllerSn;
    }

    getImei() {
        return this.#imei;
    }

    setImei(imei) {
        this.#imei = imei;
    }

    getManufacturingDate() {
        return this.#manufacturingDate;
    }

    setManufacturingDate(manufacturingDate) {
        this.#manufacturingDate = manufacturingDate;
    }

    getAux() {
        return this.#aux;
    }

    setAux(aux) {
        this.#aux = aux;
    }

    getSyncStatus() {
        return this.#syncStatus;
    }

    setSyncStatus(syncStatus) {
        this.#syncStatus = syncStatus;
    }

    getTempStatus() {
        return this.#tempStatus;
    }

    setTempStatus(tempStatus) {
        this.#tempStatus = tempStatus;
    }

    getDaysActive() {
        return this.#daysActive;
    }

    setDaysActive(daysActive) {
        this.#daysActive = daysActive;
    }

    getLastConnection() {
        return this.#lastConnection;
    }

    setLastConnection(lastConnection) {
        this.#lastConnection = lastConnection;
    }

    getLastPositionDate() {
        return this.#lastPositionDate;
    }

    setLastPositionDate(lastPositionDate) {
        this.#lastPositionDate = lastPositionDate;
    }

    getLastLat() {
        return this.#lastLat;
    }

    setLastLat(lastLat) {
        this.#lastLat = lastLat;
    }

    getLastLong() {
        return this.#lastLong;
    }

    setLastLat(lastLong) {
        this.#lastLong = lastLong;
    }

    getLastTemperatureDate() {
        return this.#lastTemperatureDate;
    }

    setLastTemperatureDate(lastTemperatureDate) {
        this.#lastTemperatureDate = lastTemperatureDate;
    }

    getLastTemperature() {
        return this.#lastTemperature;
    }

    setLastTemperature(lastTemperature) {
        this.#lastTemperature = lastTemperature;
    }

    getLastTemperatures() {
        return this.#lastTemperatures;
    }

    setLastTemperatures(lastTemperatures) {
        this.#lastTemperatures = lastTemperatures;
    }

    getMachineEvents() {
        return this.#machineEvents;
    }

    setMachineEvents(machineEvents) {
        this.#machineEvents = machineEvents;
    }

    getCountyCoolerModelClient() {
        return this.getCounty() + " / " + this.getCoolerModel() + " / " + this.getClient();
    }
}